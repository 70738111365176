import React from 'react'
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import HospitalProfileWrapper from "./HospitalProfileWrapper.web";
import { Box, Grid, Typography } from '@material-ui/core';
import Sidebar from '../../../components/src/Sidebar.web';
import CustomInputField from '../../../components/src/CustomInputField.web';
import CustomStyledButton from '../../../components/src/CustomStyledButton.web';
import CommonModal from '../../../components/src/CommonModal.web';
import { receiveMail } from './assets';

class HelpCenter extends CustomisableUserProfilesController {
    render() {
        return (
            <Box sx={webStyle.container}>
                <Box sx={webStyle.sidebarWrapper}>
                    <Sidebar />
                </Box>
                <Box sx={webStyle.rightWrapper}>
                    <HospitalProfileWrapper
                        data-test-id='profile-wrapper'
                        {...this.props}
                        profileBackBtn
                        profilePageTitle={configJSON.PageTitle.HelpCenter}
                        handleOpenHospitalEditProfileView={this.openHospitalEditProfiles}
                    >
                        <Box sx={webStyle.contentWrapper}>
                            <Typography style={webStyle.headerText}>HomeCure</Typography>
                            <Typography style={webStyle.subHeaderText}>
                                You can contact us on toll free number or on Email!
                            </Typography>
                            <Grid container spacing={5} style={webStyle.contactInfoWrapper}>
                                <Grid item sm={12} md={6} lg={6} xl={6}>
                                    <CustomInputField fullWidth placeholder="Enter your phone number" />
                                </Grid>
                                <Grid item sm={12} md={6} lg={6} xl={6}>
                                    <CustomInputField fullWidth placeholder="Enter your mail" />
                                </Grid>
                            </Grid>
                            <Typography style={webStyle.contactUsText}>Contact Us</Typography>
                            <Grid container spacing={5}>
                                <Grid item sm={12} md={6} lg={6} xl={6}>
                                    <CustomInputField fullWidth placeholder="Enter your name" />
                                </Grid>
                                <Grid item sm={12} md={6} lg={6} xl={6}></Grid>
                            </Grid>
                            <CustomInputField
                                style={webStyle.messageField}
                                fullWidth
                                multiline
                                minRows={4}
                                placeholder="Write your message…"
                            />
                            <CustomStyledButton data-test-id='submit' style={{ width: '250px' }} onClick={this.openMailSentModal} label="Submit" />
                        </Box>
                    </HospitalProfileWrapper>
                </Box>
                <CommonModal
                    data-test-id='modal'
                    open={this.state.openHelpCenterModal}
                    dialogStyle={webStyle.modalDialog}
                    onClose={this.closeMailSentModal}
                >
                    <Box sx={webStyle.modalContent}>
                        <img src={receiveMail} style={webStyle.modalImage} alt="mail" />
                        <Typography style={webStyle.modalText}>
                            We have received your request
                        </Typography>
                        <CustomStyledButton
                            data-test-id='close-modal'
                            onClick={this.closeMailSentModal}
                            style={webStyle.modalButton}
                            label="Continue to Website"
                        />
                    </Box>
                </CommonModal>
            </Box>
        );
    }
}

export default HelpCenter;

const webStyle = {
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 60px 72px 60px',
        maxWidth: '55%',
    },
    headerText: {
        color: '#014866',
        fontFamily: 'SF Pro Text Heavy',
        fontSize: '30px',
        lineHeight: '35px',
        marginBottom: '32px',
    },
    subHeaderText: {
        color: '#292929',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '20px',
    },
    contactInfoWrapper: {
        marginBottom: '40px',
    },
    contactUsText: {
        fontFamily: 'SF Pro Text Bold',
        fontSize: '20px',
        color: '#292929',
        marginBottom: '20px',
    },
    messageField: {
        marginBottom: '90px',
        marginTop: '20px',
    },
    modalDialog: {
        borderRadius: '25px',
        backgroundColor: '#F2F1FA',
        maxWidth: '450px',
        width: '100%',
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    modalImage: {
        marginBottom: '30px',
    },
    modalText: {
        color: '#292929',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold',
        marginBottom: '50px',
    },
    modalButton: {
        width: '100%',
    },
};
