import React from 'react';
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import { Box, Typography } from '@material-ui/core';
import Sidebar from '../../../components/src/Sidebar.web';
import HospitalProfileWrapper from './HospitalProfileWrapper.web';
import CustomStyledButton from '../../../components/src/CustomStyledButton.web';
import CommonModal from '../../../components/src/CommonModal.web';

class DeactivateProfile extends CustomisableUserProfilesController {
    render() {
        return (
            <Box style={webStyle.container}>
                <Box sx={webStyle.sidebarWrapper}>
                    <Sidebar />
                </Box>
                <Box style={webStyle.rightWrapper}>
                    <HospitalProfileWrapper
                        data-test-id="profile-wrapper"
                        {...this.props}
                        profileBackBtn
                        profilePageTitle={configJSON.PageTitle.DeactivateAccount}
                        hideProfileActionBtns
                    >
                        <Box style={webStyle.contentWrapper}>
                            <Typography style={webStyle.heading}>HomeCure</Typography>
                            <Typography style={webStyle.subHeading}>
                                You can contact us on toll free number or on Email!
                            </Typography>
                            <Typography style={webStyle.description}>
                                In compliance with the "electronic health records" standards of 2013 (revised in 2016)
                                stating that "electronic records must compulsorily be preserved and never destroyed
                                during the lifetime of the person.” We will store your medical data safely and ensure you
                                get access to it whenever you feel like reactivating your account. Even if you do not
                                reactivate your account, your account will get deleted after 1 month, but your medical data
                                will be stored safely with us.
                            </Typography>
                            <CustomStyledButton
                                data-test-id='deactivate-button'
                                style={webStyle.deactivateButton}
                                onClick={this.openDeactivateConfirmModal}
                                label="Deactivate Account"
                            />
                        </Box>

                        <CommonModal
                            data-test-id='modal'
                            open={this.state.openDeactivateModal}
                            dialogStyle={webStyle.modalDialog}
                            onClose={this.closeDeactivateConfirmModal}
                        >
                            <Box style={webStyle.modalContent}>
                                <Typography style={webStyle.modalHeading}>Deactivate your account?</Typography>
                                <Typography style={webStyle.modalSubHeading}>Are you sure?</Typography>
                                <Box style={webStyle.modalButtonsWrapper}>
                                    <CustomStyledButton
                                        isSecondary
                                        data-test-id='No'
                                        onClick={this.closeDeactivateConfirmModal}
                                        style={webStyle.modalButton}
                                        label={configJSON.ButtonLabel.No}
                                    />
                                    <CustomStyledButton
                                        data-test-id='Yes'
                                        onClick={this.closeDeactivateConfirmModal}
                                        style={webStyle.modalButton}
                                        label={configJSON.ButtonLabel.Yes}
                                    />
                                </Box>
                            </Box>
                        </CommonModal>
                    </HospitalProfileWrapper>
                </Box>
            </Box>
        );
    }
}

export default DeactivateProfile;

const webStyle = {
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 60px 72px 60px',
        maxWidth: '80%',
    },
    heading: {
        color: '#014866',
        fontFamily: 'SF Pro Text Heavy',
        fontSize: '30px',
        lineHeight: '35px',
        marginBottom: '32px',
    },
    subHeading: {
        color: '#292929',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '20px',
    },
    description: {
        fontFamily: 'SF Pro Text Regular',
        textAlign: 'justify' as const,
        color: '#555555',
        fontSize: '18px',
        lineHeight: '30px',
        marginBottom: '50px',
    },
    deactivateButton: {
        width: '308px',
    },
    modalDialog: {
        borderRadius: '12px',
        backgroundColor: '#F2F1FA',
        maxWidth: '420px',
        width: '100%',
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        maxWidth: '261px',
        margin: '0 auto',
    },
    modalHeading: {
        color: '#292929',
        fontSize: '20px',
        fontFamily: 'SF Pro Text Bold',
        marginBottom: '20px',
    },
    modalSubHeading: {
        color: '#292929',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Medium',
        marginBottom: '30px',
    },
    modalButtonsWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    modalButton: {
        width: '100px',
    },
};
