import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject } from "react";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { ProfileValue, HospitalProfileValue, DataItem, Document, UserDataType, HospitalDataType } from "./types";
import * as Yup from "yup";
import {ProfileIcon} from "./assets";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any
  google: undefined;
  diseasesList?: any;
  step?: any;
  profileBackBtn?:boolean;
  profilePageTitle?:string;
  handleOpenHospitalEditProfileView?:() => void;
  openProfileSelectModal?:() => void;
  hideProfileActionBtns?:boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture:string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  languages: any;
  errors: Partial<ProfileValue>;
  familyMemberName: string;
  familyMemberPhone: string;
  familyMemberRelation: string;
  showDropdown: boolean;
  district: string;
  openDialog: boolean;
  UploadImageError: string;
  showHealthDetails: boolean;
  diseasesList: [],
  isLogin:boolean;
  url: any;
  enableWeb: boolean;
  registrationNo: string;
  hospitalErrors: Partial<HospitalProfileValue>;
  isUploadDoc: boolean;
  image: any;
  registrationCertifcateDoc: any,
  isDepartment: boolean;
  departmentsList:any,
  departments: any;
  insurances: any;
  insurancesList:any;
  search:string;
  patientSuccess: boolean;
  hospitalSuccess: boolean;
  identification_no: string;
  departmentId: string;
  kind: string;
  hospitalprofileErrorMessage: string;
  successMessage: string;
  connect_with_hospital:boolean;
  errorMessage:string;
  phoneOTP:any;
  resendCounter:number;
  isResendActive:boolean;
  isError:boolean;
  otpSend:boolean;
  otpVerified:string;
  setToken:string;
  emailSend:boolean;
  confimEmail:boolean;
  phNumber:string;
  mail:string;
  verifyType:string;
  profile_image: Document | null;
  settingsView:boolean;
  isInsurance:boolean;
  manageView:"bed"|"healthcare-person";
  isBedAvailable:boolean;
  checkedBedRows:string[];
  openHelpCenterModal:boolean;
  openDeactivateModal:boolean;
  settingPopoverEl : HTMLButtonElement | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getProfileDetailsCallId: string = "";
  updateProfileApiCallId: string = "";
  uploadProfileImageAPICallId: string = "";
  fileInputRef: RefObject<HTMLInputElement>;
  diseasesApiCallId: string = "";
  webCameraRef: any;
  getHospitalProfileDetailsCallId: string = "";
  updateHospitalApiCallId: string = "";
  fetchDepartmentsApiCallId: string = "";
  fetchInsurancesApiCallId: string = "";
  getHealthcarePersonnelCallId: string = "";
  verifyCallId: string = "";
  verifyOTPCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: ProfileIcon,
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
      settingsView:false,
      isInsurance:false,
      manageView:"bed",
      isBedAvailable:false,
      checkedBedRows:[],
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      languages: [],
      errors: {},
      familyMemberName: "",
      familyMemberPhone: "",
      familyMemberRelation: "",
      showDropdown: false,
      district: "",
      openDialog: false,
      UploadImageError: "",
      showHealthDetails:false,
      diseasesList: [],
      isLogin:false,
      url: null,
      enableWeb: false,
      registrationNo:"",
      hospitalErrors:{},
      isUploadDoc:false,
      image: null,
      registrationCertifcateDoc: null,
      isDepartment: true,
      departmentsList:[],
      departments: [],
      insurances: [],
      insurancesList:[],
      search:"",
      patientSuccess:false,
      hospitalSuccess:false,
      identification_no:"",
      connect_with_hospital:false,
      departmentId:"",
      hospitalprofileErrorMessage:"",
      successMessage:"",
      errorMessage:"",
      kind: "",
      phoneOTP: ["","","",""],
      resendCounter:0,
      isResendActive:false,
      isError:false,
      otpSend:false,
      otpVerified:"",
      setToken:"",
      emailSend:false,
      confimEmail:false,
      phNumber:"",
      mail:"",
      verifyType:"",
      profile_image: null,
      openHelpCenterModal:false,
      openDeactivateModal:false,
      settingPopoverEl : null
      // Customizable Area End
    };
    // Customizable Area Start
    this.fileInputRef = React.createRef();
    this.webCameraRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.handleClickOutsideEditBLock = this.handleClickOutsideEditBLock.bind(this);
    this.capturePhoto = this.capturePhoto.bind(this);
    await this.fetchHospitalProfileDatas();
    this.fetchDepartmentsList();
    this.fetchInsurancesList();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (this.checkSession(message)) return;
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson) {
        this.checkUsersProfile(message);
        this.updateUsersProfile(message);
        this.getDiseasesData(message);
        this.checkHospitalProfile(message);
        this.updateHospitalDetails(message);
        this.fetchDepartmentsResponse(message);
        this.fetchInsuranceResponse(message);
        this.checkUsersHealthcarePersonnelProfile(message);
        this.verifyUserPhoneOrEmail(message);
        this.verifyOTPResponse(message);
        if (responseJson.errors) return;
        this.checkOtherResponses(message);
        this.checkProfileAccount(message);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  checkUsersProfile = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileDetailsCallId) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const address_data = dataResponse.data.attributes.address?.data.attributes
      let city = '', address = '', pincode = '', district = '';
      if (address_data) {
        address = address_data["address"]
        city = address_data["city"]
        district = address_data["district"]
        pincode = address_data["pincode"]
      }
      let languages = ""
      if(dataResponse.data.attributes.languages.length>0){
        const arrayOfNames = dataResponse.data.attributes.languages.map((item:any) => item.name);
        languages = arrayOfNames.join(", ");
      }

      const profile = dataResponse.data.attributes.profile_image_url?.url ? dataResponse.data.attributes.profile_image_url.url : ProfileIcon;
      this.setState({
        user_name: dataResponse.data.attributes.name,
        user_type: dataResponse.data.type,
        gender: dataResponse.data.attributes.gender || "",
        city: city,
        postalCode: pincode,
        district: district,
        address: address,
        phoneNumber: dataResponse.data.attributes.phone_number,
        userAge: dataResponse.data.attributes.age || "",
        email: dataResponse.data.attributes.email,
        languages: languages,
        familyMemberName: dataResponse.data.attributes.fm_name,
        familyMemberPhone: dataResponse.data.attributes.fm_phone_number,
        familyMemberRelation: dataResponse.data.attributes.fm_relation,
        phNumber: dataResponse.data.attributes.phone_number,
        mail: dataResponse.data.attributes.email,
        profilePicture:profile
      },()=>{
        setStorageData("profile", this.state.profilePicture)
        setStorageData("userName", this.state.user_name)
      });
      return true;
    }
  };


  updateUsersProfile = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.updateProfileApiCallId) {
      if (responseJson.data) {
        this.setState({patientSuccess:true})
      } else {
        this.showAlert("Error", responseJson.errors ? responseJson.errors.message : responseJson.error.message);
      }
    }
  };

  getDiseasesData = async(message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.diseasesApiCallId) {
      if (!responseJson.errors && !responseJson.error) {
        const loginStatus = await getStorageData("isLogin");
        const isLogin = loginStatus.toLowerCase() === "true";
        this.setState({ diseasesList: responseJson, isLogin })

      } else {
        this.showAlert("Error", responseJson.errors ? responseJson.errors.message : responseJson.error.message);
      }
    }
  };

  checkResponceDataType = (type:string) => {
   return type !=="email_account";
  }

  handleSetVerifiedError = (verifyType:string,responseJson:any) => {
    this.setState((prevState) => ({
      ...prevState,
      errors: { 
        ...prevState.errors, 
        [verifyType]: responseJson.errors ? responseJson.errors.message : responseJson.error.message,
      },
      hospitalErrors: { 
        ...prevState.hospitalErrors, 
        [verifyType]: responseJson.errors ? responseJson.errors.message : responseJson.error.message,
      },
      isError: true,
    }));
  }

  verifyUserPhoneOrEmail = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.verifyCallId) {
      if (responseJson.data) {
        if(this.checkResponceDataType(responseJson.data.type)){
          this.setState({otpSend:true,setToken:responseJson.meta.token,isError:false})
        }else{
          this.setState({emailSend:true})
        }
      } else {
        const verifyType = this.state.verifyType
        this.handleSetVerifiedError(verifyType,responseJson)    
      }
    }
  };

  verifyOTPResponse = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.verifyOTPCallId) {
      if (responseJson.message) {
        this.setState({otpVerified:responseJson.message,setToken:"",otpSend:false})
      } else {
        this.setState({errorMessage:responseJson.errors ? responseJson.errors.message : responseJson.error.message ,isError:true})
      }
    }
  };

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };

  async componentDidUpdate() {
    if (this.state.currentProfile) {
      if (JSON.stringify(this.state.currentProfile) !== JSON.stringify(this.state.profile)) {
        if (!this.state.cancelEnabled) this.setState({ cancelEnabled: true });
        this.checkRequiredFields();
      }
    }
    this.fetchAttributes();
  }

  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchUserProfileDatas = async () => {
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileDetailsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfile
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": "application/json",
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  openEditProfiles = () => {
    this.props.navigation.navigate("CustomisableEditUserProfile")
  }

  userProfileValidationSchemas = Yup.object().shape({
    user_name: Yup.string()
      .required("Name is required").matches(/^\S.*$/, 'Name is required').test('is-valid-name', 'Invalid name', function (value) {
        if (value !== '' && value !== null) {
          const regex = /^(?=[a-zA-Z]*[aeiou])[a-zA-Z]{2,}(?:\s+[a-zA-Z]{2,}){0,11}$/;
          if (regex.test(value)) { const enterName = value.split(' ');
            for (const username of enterName) { if (!/[aeiou]/i.test(username)) { return false; }}
            return true;
          } else { return false;}
        } return true; }), 
    gender: Yup.string()
      .notOneOf(["Enter gender"], "Gender is required").required("Gender is required"),
    address: Yup.string()
      .required("Address is required").matches(/^\S.*$/, 'Address is required'),
    userAge: Yup.string()
      .required("Age is required") .matches(/^(?:\b(?:[0-9]\d?|1[01]\d|110)\b)$/, "Invalid age")
      .test('is-valid-name', 'Invalid age', function (value) {
        if (value > 110) {
          return false
        }else{
          return true
        }
      }).matches(/^\S.*$/, 'Age is required'),
    familyMemberPhone: Yup.string()
      .nullable().notRequired()
      .test('is-valid-name', 'Invalid phone number', function (value) {
        if (value !== null && value !== '') {return /^[6-9]\d{9}$/.test(value);}
        return true;
      }),
    phoneNumber: Yup.string()
    .required("Phone number is required")
    .test('is-valid-name', 'Invalid phone number', function (value) {
      if (value !== null && value !== '') {return /^[6-9]\d{9}$/.test(value);}
      return true;
    }),
    familyMemberName: Yup.string()
    .nullable()
    .notRequired()
    .test('is-valid-name', 'Invalid family member name', function (value) {
      if (value !== null && value !== '') {
        const regex = /^(?=[a-zA-Z]*[aeiou])[a-zA-Z]{2,}(?:\s+[a-zA-Z]{2,}){0,11}$/;
        if (regex.test(value)) {
          const words = value.split(' ');
          for (const word of words) {
            if (!/[aeiou]/i.test(word)) {
              return false; 
            }
          }
          return true;
        } else {
          return false; 
        }
      }
      return true;
    }), 
    familyMemberRelation: Yup.string()
      .nullable().notRequired()
      .test('is-valid-name', 'Family member relation should contain only alphabets', function (value) {
        if (value !== null && value !== '') { return /^[a-zA-Z\s]+$/.test(value); }
        return true;
      }),
    email:Yup.string().required("Email is required.").email("Invalid email")
  });

  handleChanges = (field: keyof ProfileValue, value: any) => {
    try {
      value = value?.replace(/ {2,}/g,' ');
      const fieldValues: Partial<ProfileValue> = {
        [field]: value,
      };
      this.userProfileValidationSchemas.validateSyncAt(field, fieldValues as ProfileValue);

      this.setState((prevState) => ({
        ...prevState,
        errors: { ...prevState.errors, [field]: "" },
        [field]: value
      }));     
    } catch (errs) {
      if (errs instanceof Yup.ValidationError) {
        const errorMessage = errs.message;

        this.setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [field] : errorMessage },
          [field] : value,
        }));
      }
    }
  };

  openGenderDropdowns = () => {
    this.setState({
      showDropdown: !this.state.showDropdown,
    })
  }


  handleGenderCheckboxChanges = (value: string) => {
    if (this.state.gender !== value) {
      this.setState((prevState) => ({
        gender: value,
        showDropdown: false,
        errors: {
          ...prevState.errors,
          gender: ""
        }
      }));
    } else {
      this.setState((prevState) => ({
        gender: "",
        showDropdown: false,
        errors: {
          ...prevState.errors,
          gender: ""
        }
      }));
    }
  };

  handleSubmits = async () => {
    const { ...prevState } = this.state;
    let moveNext = true;
    Object.keys(prevState).forEach((field) => {
      try {
        const fieldValues: Partial<ProfileValue> = {
          [field]: prevState[field as keyof ProfileValue],
        };
        this.userProfileValidationSchemas.validateSyncAt(field, fieldValues as ProfileValue);
        this.setState((prevState) => ({
          ...prevState,errors: { ...prevState.errors, [field]: "" },
        }));
      } catch (errObj) {
        if (errObj instanceof Yup.ValidationError) {
          const errorMessage = errObj.message;
          moveNext = false;
          this.setState((prevState) => ({
            ...prevState,errors: { ...prevState.errors, [field]: errorMessage },}));
        }
      }
    });

    if (moveNext) { 
      this.callupdateAPI()
    }
  };

  callupdateAPI =()=> {
    const {phoneNumber,phNumber,email,mail} = this.state;

    const tempPhoneNumber=Number(phoneNumber)
    const tempphNumber=Number(phNumber)

    if(tempPhoneNumber === tempphNumber && email === mail){this.updateUserProfileDetail()}
    else if(tempPhoneNumber !== tempphNumber && email !== mail){this.callverifyPhone()}
    else if( tempphNumber !== tempPhoneNumber && mail === email){this.callverifyPhone()}
    else if( tempPhoneNumber === tempphNumber && email !== mail){
      this.verifyPhoneOrEmail("email")
    }
  }

  updateUserProfileDetail = async () => {

    let userData = {
      name: this.state.user_name,
      age: this.state.userAge,
      gender: this.state.gender,
      address_attributes: {
        address: this.state.address,
        district: this.state.district,
        city: this.state.city,
        pincode: this.state.postalCode
      },
      fm_name: this.state.familyMemberName,
      fm_phone_number: this.state.familyMemberPhone,
      fm_relation: this.state.familyMemberRelation,
      full_phone_number: `+91${this.state.phoneNumber}`,
      email: this.state.email
    } as unknown as UserDataType;

    if(this.state.profile_image){
      userData = {...userData,profile_image: this.state.profile_image}
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfile
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(userData));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),configJSON.httpPostMethod);

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCloseDialog = () => {
    this.setState({
      openDialog:false,
      UploadImageError:"",
      enableWeb:false,
      url:null
    })
  }

  handleOpenDialogs = () => {
    this.setState({
      openDialog: true
    })
  }

  handleUploadProfiles = () => {
    this.webCameraRef.current?.click();
    this.setState({registrationCertifcateDoc: null})
  };
  handleUploadProfileImages = (event: any) => {
    const selectedUploadedProffile = event.target.files?.[0];
    if (!selectedUploadedProffile) {
        return;
    }
    const allowedExtensions = ['jpg', 'jpeg', 'png'];
    const extension = selectedUploadedProffile.name.split('.').pop()?.toLowerCase();
    if (!extension || !allowedExtensions.includes(extension)) {
        this.setState({ UploadImageError: 'Invalid file type. Please upload only image' });
        return;
    } else {
        this.setState({ UploadImageError: "" });
    }
    const reader = new FileReader();
    reader.onloadend = () => {
        if (typeof reader.result === 'string') {
            const base64data = reader.result.split(',')[1];
            const content_type = selectedUploadedProffile.type;
            const filename = selectedUploadedProffile.name;
            const profileImage = {
                data: base64data,
                content_type: content_type,
                filename: filename,
            };
            this.setState({profile_image:profileImage,openDialog:false,profilePicture:reader.result})
        }
    };

    reader.readAsDataURL(selectedUploadedProffile);
};



  handleEditPatientHealthDetail = () => {
    this.setState({
      showHealthDetails: true
    })
  }

  callHealthDetails = () => {
    this.props.navigation.navigate("CustomisableHealthDetailsUserProfiles")
  }
  
  fetchDiseasesDetails = async () => {
    const token = await getStorageData("authToken");
    this.getdiseasesList(token);
  }

  getdiseasesList = (token: string) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.diseasesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDiseasesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


capturePhoto = () => {
  const imageSrc =this.webCameraRef?.current?.getScreenshot();
  this.setState({url : imageSrc})
};

getUrlExtension = (urlLink:string) => {
  return urlLink.split(/[#?]/)[0].split(".").pop();
}

openWeb = () => {
  this.setState({enableWeb:true})
}

closeWebCamera = () => {
  this.setState({
    enableWeb:false,
    url:null
  })
}

handleClickOutsideEditBLock() {
  this.setState({
    showDropdown:false,
  })
}


fetchHospitalProfileDatas = async () => {
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

  this.getHospitalProfileDetailsCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getHospitalProfile
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod
  );

  const token = await getStorageData("authToken");

  const header = {
    "Content-Type": "application/json",
    token: token,
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

openHospitalEditProfiles = () => {
  this.props.navigation.navigate("CustomisableEditHospitalProfile")
}

goToBlock = (blockName: string) => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), blockName);
  message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
  );
  this.send(message);
}

handleOpenBedView=()=>{
  this.setState({manageView:"bed"})
}

handleOpenHealthCarePersonView =()=>{
  this.setState({manageView:"healthcare-person"})
}

handleBedManage = (_event: React.ChangeEvent<{}>,checked:boolean) => {
  this.setState((prevState)=>{
    return {...prevState ,isBedAvailable: checked }
  });
};

handleOpenSettings = (event: React.MouseEvent<HTMLButtonElement>) => {
  this.setState({ settingPopoverEl : event.currentTarget })
}

closeSettingsPopover = () => {
  this.setState({ settingPopoverEl: null})
}

openAvailableDepartmentView = () => {
  this.setState({ isDepartment : true, isInsurance:false, settingsView:false, errorMessage:"", successMessage:""})
}

openInsuranceView = () => {
  this.setState({isInsurance : true,isDepartment:false,settingsView:false,errorMessage:"", successMessage:""})
}

handleChangeCheckManageBedRow = (event: React.ChangeEvent<HTMLInputElement>,checked:boolean) => {
  const {name}= event.target;
  this.setState((prevState)=>{
    let prevBedRows = [...prevState.checkedBedRows]
    if(checked){
      prevBedRows = [...prevState.checkedBedRows,name];
    } else {
      prevBedRows = prevBedRows.filter((rowId)=>rowId !== name)
    }
    return {
      checkedBedRows:prevBedRows
    }
  })
};

openMailSentModal = () => {
  this.setState({ openHelpCenterModal : true })
}

closeMailSentModal = () => {
  this.setState({ openHelpCenterModal : false })
}

openDeactivateConfirmModal = () => {
  this.setState({ openDeactivateModal : true })
}

closeDeactivateConfirmModal = () => {
  this.setState({ openDeactivateModal : false })
}

navigateFromSettings = (toPage:string) => {
  switch(toPage){
    case "about-us": return ()=> this.goToBlock("HomeCureAboutus");
    case "deactivate": return ()=>this.goToBlock("DeactivateProfile");
    case 'help': return ()=>this.goToBlock("HelpCenter");
    case 'terms&condition' : return ()=> this.goToBlock('HomeCureTermsAndConditions');
    case 'hospital-manage': return ()=> this.goToBlock("CustomisableManageHospital");
    case 'privacy-policy': return ()=> this.goToBlock('HomeCurePrivacyPolicy')
  }
}

handleLogout = async () => {
  await removeStorageData('authToken');
  await removeStorageData('isLogin');
  await removeStorageData('userType')
  await removeStorageData('isSignUp')
  await removeStorageData('phoneNumber')
  await removeStorageData('doctorId')
  await removeStorageData('hpUserRole')
  await removeStorageData('priceMode')
  await removeStorageData('redirectFromDashboard')
  await removeStorageData('userName')
  await removeStorageData('serviceType')
  await removeStorageData('isConnectedHospital')
  await removeStorageData('profile');
  this.goToBlock('UserRole');
}

hospitalValidationSchemas = Yup.object().shape({
  user_name: Yup.string()
  .required("Hospital name is required")
  .matches(/^\S.*$/, 'Hospital name is required')
  .matches(/^[a-zA-Z\s]+$/, "Please enter valid name"),  
  city: Yup.string()
    .required("City is required").matches(/^\S.*$/, 'City is required')
    .matches(/^[a-zA-Z\s]+$/, "Enter valid city"),
  postalCode: Yup.string()
    .required("Pin Code is required")
    .matches(/^\d{0,9}$/, "Invalid Pin Code")
    .matches(/^\d{6}$/, "Pin Code must be 6 digits"),
  address: Yup.string()
    .required("Address is required")
    .matches(/^\S.*$/, 'Enter valid address'),
  district: Yup.string()
    .required("District is required")
    .matches(/^\S.*$/, 'District is required')
    .matches(/^[a-zA-Z\s]+$/, "Enter valid district"),
  registrationNo: Yup.string()
    .required("Registration number is required")
    .matches(/^\d{0,9}$/, "Enter valid registration no")
    .matches(/^\d{8}$/, "Enter valid registration no"),
  image: Yup.string().required("Please upload a valid document"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .test('is-valid-name', 'Invalid phone number', function (value) {
      if (value !== null && value !== '') {return /^[6-9]\d{9}$/.test(value);}
      return true;
    }),  
  email:Yup.string().required("Email is required.").email("Invalid email")
});

handleHospitalProfileChanges = (field: keyof HospitalProfileValue, value: string|number) => {
  try {
    if (field === 'image' && this.state.image === "") {
      this.setState((prevState) => ({
        ...prevState,
        hospitalErrors: { ...prevState.hospitalErrors, image: 'Please upload a valid document' },
      }));
      return
    }

    const fieldValues: Partial<HospitalProfileValue> = {
      [field]: value,
    };
    this.hospitalValidationSchemas.validateSyncAt(field, fieldValues as HospitalProfileValue);
    this.setState((prevState) => ({
      ...prevState,
      [field]: value,
      hospitalErrors: { ...prevState.hospitalErrors, [field]: "" },
    })); 
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      const errorMessage = error.message;
      this.setState((prevState) => ({
        ...prevState,
        [field]: value,
        hospitalErrors: { ...prevState.hospitalErrors, [field]: errorMessage },
      }));
    }
  }
};

handleButtonClick = () => {
  this.fileInputRef.current?.click();
};

handleHospitalUploadDoc = (event: any) => {
  const selectHospitalDocument = event.target.files?.[0];
  if (!selectHospitalDocument) {
    return;
  }
  if (!selectHospitalDocument.type.startsWith('image/')) {
    this.setState((prevState) => ({
      hospitalErrors: {...prevState.hospitalErrors, image: "Please select a valid image file"},
    }));
    return;
  } else {
    this.setState((prevState) => ({
      hospitalErrors: {...prevState.hospitalErrors, image: ""},
    }));
  }

  const readerFile = new FileReader();
  readerFile.onloadend = () => {
    if (typeof readerFile.result === 'string') {
      const base64Data = readerFile.result.split(',')[1];
      const contentType = selectHospitalDocument.type;
      const filename = selectHospitalDocument.name;
      this.setState({
        registrationCertifcateDoc: {
          base64Data,
          contentType,
          filename,
        },
        isUploadDoc: true,
        image: readerFile.result
      });
    }
  };
  readerFile.readAsDataURL(selectHospitalDocument);
};

handleHospitalDetailsSubmit = async () => {
  const { ...prevState } = this.state;
  let nextStep = true;

  Object.keys(prevState).forEach((field) => {
    try {
      if (field === 'image' && this.state.image === "") {
        nextStep = false;
        this.setState((prevState) => ({
          ...prevState,
          hospitalErrors: { ...prevState.hospitalErrors, image: 'Please upload a valid document' },
        }));
        return
      } 
      const fieldValues: Partial<HospitalProfileValue> = {
        [field]: prevState[field as keyof typeof prevState],
      };
      this.hospitalValidationSchemas.validateSyncAt(field, fieldValues as HospitalProfileValue);
      this.setState((prevState) => ({
        ...prevState,
        hospitalErrors: { ...prevState.hospitalErrors, [field]: "" },
      }));
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessage = error.message;
        nextStep = false;
        this.setState((prevState) => ({
          ...prevState,
          hospitalErrors: { ...prevState.hospitalErrors, [field]: errorMessage },
        }));
      }
    }
  });
  if (nextStep) {
    this.callVerificationAPI()
  }
};

callVerificationAPI() {
  const {phoneNumber,phNumber,email,mail}=this.state;
  const tempPhoneNumber=Number(phoneNumber)
  const tempphNumber=Number(phNumber)

  if (tempPhoneNumber === tempphNumber && email === mail) {
    this.updateHospitalDetailsApi();
  } else if (tempPhoneNumber !== tempphNumber && email !== mail) {
    this.callverifyPhone()
  } else if (tempPhoneNumber !== tempphNumber && email === mail) {
      this.callverifyPhone()
    }
    else if (tempPhoneNumber === tempphNumber && email !== mail) {
      this.verifyPhoneOrEmail("email")
    }
}

callverifyPhone = () =>{
  this.verifyPhoneOrEmail("phone")
}

updateHospitalDetailsApi = async () => {
  let hospitalData = {
    name: this.state.user_name,
    registration_no: this.state.registrationNo,
    address_attributes: {
      address: this.state.address,
      district: this.state.district,
      city: this.state.city,
      pincode: this.state.postalCode
    },
    department_ids: this.state.departments,
    insurance_ids: this.state.insurances,
    full_phone_number: `+91${this.state.phoneNumber}`,
    email: this.state.email
  } as unknown as HospitalDataType;

  if (this.state.registrationCertifcateDoc !== null) {
    const uploadFile = {
      certificate: {
        data: this.state.registrationCertifcateDoc.base64Data,
        filename: this.state.registrationCertifcateDoc.filename,
        content_type: this.state.registrationCertifcateDoc.contentType
      }
    };
    hospitalData = {
      ...hospitalData, ...uploadFile
    };
  }

  if(this.state.profile_image){
    hospitalData = {...hospitalData,profile_image: this.state.profile_image}
  }
  
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.updateHospitalApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.updatePatientProfile
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(hospitalData)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpPostMethod
  );

  const token = await getStorageData("authToken");

  const header = {
    "Content-Type": configJSON.searchApiContentType,
    token: token,
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

checkHospitalProfile = (message: Message) => {
  const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  if (apiRequestCallId === this.getHospitalProfileDetailsCallId) {
    if (!responseJson.data || !responseJson.data.attributes) return;
    this.setState({image:""})
    const hospitalDataResponse = responseJson;
    const address_hospitaldata = hospitalDataResponse.data.attributes.address.data.attributes
    let  pincode = '', district = '', city = '', address = '';
    if (address_hospitaldata) {
      address = address_hospitaldata["address"]
      city = address_hospitaldata["city"]
      district = address_hospitaldata["district"]
      pincode = address_hospitaldata["pincode"]
    };


    const profile= hospitalDataResponse.data.attributes.profile_image_url?.url ? hospitalDataResponse.data.attributes.profile_image_url.url : ProfileIcon; 
    const certificate= hospitalDataResponse.data.attributes.certificate_url?.url ? hospitalDataResponse.data.attributes.certificate_url.url : ""; 
    this.setState({
      user_name: hospitalDataResponse.data.attributes.name,
      user_type: hospitalDataResponse.data.type,
      city: city,
      postalCode: pincode,
      district: district,
      address: address,
      departments:hospitalDataResponse.data.attributes.department_ids,
      insurances:hospitalDataResponse.data.attributes.insurance_ids,
      phoneNumber: hospitalDataResponse.data.attributes.phone_number,
      email: hospitalDataResponse.data.attributes?.email || "",
      phNumber: hospitalDataResponse.data.attributes.phone_number,
      mail: hospitalDataResponse.data.attributes?.email || "",
      registrationNo : hospitalDataResponse.data.attributes.registration_no,
      profilePicture:profile,
      image:certificate,
      isUploadDoc:true
    });
    return true;

  }
};

updateHospitalDetails = async(message: Message) => {
  const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  if (apiRequestCallId === this.updateHospitalApiCallId) {
    if (!responseJson.errors && !responseJson.error) {
      const successMessage =  this.state.isDepartment ? "Department updated successfully.":"Insurance updated successfully." ;
      this.setState({hospitalSuccess:true, search:"", successMessage})
      setTimeout(() => {
        this.setState({successMessage:""})
      }, 3000);
    } else {
      const identificationNoError = responseJson.errors.message;
      this.setHospitalMessage(identificationNoError,responseJson)
    }
  }
};

  setHospitalMessage = (identificationNoError: string[], responseJson: { errors: { message: string; }; error: { message: string; }; }) => {

    if (identificationNoError && identificationNoError[0]) {
      this.setState({ hospitalprofileErrorMessage: "Registration number has already been registered." });
    } else {
      this.setState({ hospitalprofileErrorMessage: responseJson.errors ? responseJson.errors.message : responseJson.error.message });
    }
  }
  
  fetchInsuranceResponse = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.fetchInsurancesApiCallId) {
      if (!responseJson.errors) {
        this.setState((prevState) => ({
          ...prevState,
          insurancesList: responseJson?.data
        }));
      } else {
        this.showAlert("Error", responseJson.errors.message);
      }
    }
  }

  fetchDepartmentsResponse = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.fetchDepartmentsApiCallId) {
      if (!responseJson.errors) {
        this.setState((prevState) => ({
          ...prevState,
          departmentsList: responseJson?.data
        }));
      } else {
        this.showAlert("Error", responseJson.errors.message);
      }
    }
  }

  fetchDepartmentsList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchDepartmentsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchDepartments
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  fetchInsurancesList = async () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchInsurancesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchInsurances
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleSearch = (search: string) => {
    this.setState({ search });
  };


  handleInsuranceCheckboxChange = (insuranceId: number) => {
      this.setState((prevState) => ({
        insurances: prevState.insurances.includes(insuranceId)
          ? prevState.insurances.filter((SerialNum: number) => SerialNum !== insuranceId)
          : [...prevState.insurances, insuranceId]
      }));
    };
  
    handleDepartmentCheckboxChange = (departmentId: number) => {
    this.setState((prevState) => ({
      departments: prevState.departments.includes(departmentId)
        ? prevState.departments.filter((SerialNum: number) => SerialNum !== departmentId)
        : [...prevState.departments, departmentId]
    }));
  }; 

handelSuccess = ()  =>{
  this.setState({patientSuccess:false});
  this.props.navigation.navigate("CustomisableViewUserProfile")
}

otpSuccess = (pageType:string) => {
  this.setState({otpVerified:"",phNumber:this.state.phoneNumber},()=>{
  if(this.state.email !== this.state.mail){
    this.verifyPhoneOrEmail("email")
  }else{
    if(pageType==="paitent"){
      this.updateUserProfileDetail()
    }
    if(pageType==="hospital"){
      this.updateHospitalDetailsApi()
    }
  }
  })
}

otpSuccessEmail = (pageType:string) => {
  this.setState({confimEmail:false,mail:this.state.email},()=>{
    if(pageType==="paitent"){
      this.updateUserProfileDetail()
    }
    if(pageType==="hospital"){
      this.updateHospitalDetailsApi()
    }
  })
}

handelHospitalSuccess = () => {
  this.setState({hospitalSuccess:false});
  this.props.navigation.navigate("CustomisableViewHospitalProfile")
}

backToProfilePage = () => {
  this.props.navigation.navigate("CustomisableViewHospitalProfile")
}


checkUsersHealthcarePersonnelProfile = (message: Message) => {
  const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  if (apiRequestCallId === this.getHealthcarePersonnelCallId) {
    if (!responseJson.data || !responseJson.data.attributes) return;
    const dataResponse = responseJson;
    const address_data = responseJson.data.attributes.address.data?.attributes
    let  pincode = '', district = '', city = '', address = '';
    if (address_data) {
      address = address_data["address"]
      city = address_data["city"]
      district = address_data["district"]
      pincode = address_data["pincode"]
    };
    const profile= dataResponse.data.attributes?.profile_image_url && dataResponse.data.attributes?.profile_image_url?.url !== "" ? dataResponse.data.attributes?.profile_image_url?.url : ProfileIcon; 
    this.setState({
      user_name: dataResponse.data.attributes.name,
      user_type: dataResponse.data.attributes.profile_type,
      gender: dataResponse.data.attributes.gender,
      city: city,
      postalCode: pincode,
      district: district,
      address: address,
      phoneNumber: dataResponse.data.attributes.phone_number,
      userAge: dataResponse.data.attributes.age,
      email: dataResponse.data.attributes.email,
      identification_no: dataResponse.data.attributes.identification_no,
      connect_with_hospital:dataResponse.data.attributes.profile_type_specific_data.connect_with_hospital,
      departmentId: dataResponse.data.attributes.profile_type === "doctor" ? JSON.stringify(dataResponse.data.attributes.profile_type_specific_data.department_id): "",
      profilePicture:profile,
    });
    return true;
  }
};

fetchDoctorDetails = async () => {
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

  this.getHealthcarePersonnelCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getHealthcarePersonnelDetails
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.httpGetMethod
  );

  const token = await getStorageData("authToken");

  const header = {
    "Content-Type": "application/json",
    token: token,
  };

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
}

hpEditProfile = () => {
  this.props.navigation.navigate("CustomisableEditHealthcarePersonnel")
  setStorageData("hpRole",this.state.user_type)
}

isMatchFound = (data: DataItem[]) => {
  // Filter the insurancesList based on the search criteria
  if(this.state.isDepartment && this.state.departments.length===0){
    this.setState({errorMessage:"Please select department."})
    return
  }else if(!this.state.isDepartment && this.state.insurances.length===0){
    this.setState({errorMessage:"Please select insurance."})
    return
  }else{
    this.setState({errorMessage:""})
  }

  const filteredData = data.filter((item: { attributes: { name: string } }) =>
      item.attributes.name.toLowerCase().includes(this.state.search.toLowerCase())
  );

  if(filteredData.length > 0){
    this.updateHospitalDetailsApi()
  }else{
    this.setState({search:""})
  }
};

verifyPhoneOrEmail = async (type:string) => {
  let updatedData;
  if (type === 'phone') {
    updatedData = {
      "data": {
        "attributes": {
            "full_phone_number": `+91${this.state.phoneNumber}`
        }
    }
    };
    this.setState({verifyType:"phoneNumber"})
  } 
  
  if (type === 'email') {
    updatedData = {
      data: {
        attributes: {
          email: this.state.email
        }
      }
    };
    this.setState({verifyType:"email"})
  }

  if(updatedData){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.verifyCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifications
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(updatedData)
    );
    
    const token = await getStorageData("authToken");
  
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token,
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
};


toggleResend = (state: boolean) => {
  this.setState({
    isResendActive: state
  })
}

handleOTPChange = (index: any, event: any) => {
  const { phoneOTP } = this.state;
  const newOTP = [...phoneOTP];
  const inputValue = event.target.value;

  // Check if the input value is a number
  if (/^\d{0,9}$/.test(inputValue)) {
    newOTP[index] = inputValue;

    if (inputValue === "" && index > 0) {
      // If the user deletes a digit, move focus to the previous input field
      const prevInput = index - 1;
      const inputElement = document.getElementById(`updateOtpInput${prevInput}`);
      if (inputElement) {
        inputElement.focus();
      }
    } else if (index < phoneOTP.length - 1 && inputValue !== "") {
      // Move focus to the next input field
      const nextInput = index + 1;
      const inputElement = document.getElementById(`updateOtpInput${nextInput}`);
      if (inputElement) {
        inputElement.focus();
      }
    }

    this.setState({ phoneOTP: newOTP });
  }
};

verifyOTPData = async () => {
  if (this.checkOtpValidation()) {
    const updatedOtp ={
      "data": {
          "attributes": {
              "pin": this.state.phoneOTP.map((otp: any) => otp.toString()).join('')
          }
      }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    this.verifyOTPCallId = requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpVerification
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(updatedOtp)
    );
      
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.state.setToken,
    };
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
}

checkOtpValidation() {
  if (this.state.phoneOTP.every((item: string) => item === "")) {
    this.setState({
      isError: true,
      errorMessage: "Please enter OTP"
    })
    return false;
  } else if (this.state.phoneOTP.length !== 4 || this.state.phoneOTP.some((item: any) => item === "")) {
    this.setState({
      isError: true,
      errorMessage: "Please enter 4 digit OTP"
    });
    return false;
  } else if (this.state.phoneOTP.length === 4) {
    this.setState({
      isError: false,
      errorMessage: ""
    });
  }
  return true;

}
confirmEmail = () => {
this.setState({emailSend:false,confimEmail:true})
}

checkPhoneOtp = (pageType:string) =>{
  if(this.state.email !== this.state.mail){
    this.setState({phoneNumber:this.state.phNumber})
    this.verifyPhoneOrEmail('email')
  }else{
    this.setState({phoneNumber:this.state.phNumber},()=>{
      if(pageType==="paitent"){
        this.updateUserProfileDetail();
      }
      if(pageType==="hospital"){
        this.updateHospitalDetailsApi();
      }
    })
  }
}

closeOtpPopup = (pageType:string, type:string) => {
  this.setState({otpSend:false,emailSend:false})
  if(type === "phone"){
    this.checkPhoneOtp(pageType)
  }
  if(type === "email"){
    this.setState({phoneNumber:this.state.phNumber,email:this.state.mail},()=>{
      if(pageType==="paitent"){
        this.updateUserProfileDetail();
      }
      if(pageType==="hospital"){
        this.updateHospitalDetailsApi();
      }
    })
  }
  }
  // Customizable Area End
}
