import React from 'react';
import { Avatar, Box, Button, Checkbox, FormControlLabel, InputAdornment, styled, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import CustomisableUserProfilesController, { configJSON } from './CustomisableUserProfilesController';
import { checked, ratingStar, search_Icon, unChecked } from './assets';

class CustomisableManageHospital extends CustomisableUserProfilesController {
    renderCellOverlay = (dataId: number) => {
        return !this.state.checkedBedRows.includes(`${dataId}`) && (
            <Box style={webStyle.cellOverlay}></Box>
        )
    }
    render() {
        return <Box style={webStyle.container}>
            <Box sx={webStyle.sidebarWrapper}>
                <Sidebar />
            </Box>
            <Box style={webStyle.rightWrapper}>
                <Box style={webStyle.rightSideContentWrapper}>
                    <StyledSearch
                        fullWidth
                        data-test-id="search-doctor"
                        variant="outlined"
                        placeholder={"Search..."}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <img
                                        src={search_Icon}
                                        alt="search"
                                        style={webStyle.searchIcons}
                                    />
                                </InputAdornment>
                            ),
                            style: {
                                borderRadius: "8px",
                            }
                        }}
                    />

                    <Box sx={webStyle.switchWrapper}>
                        <Typography style={webStyle.manageTitle}>{configJSON.TableTitles.Manage}</Typography>
                        <StyledFormControlLabel
                            data-test-id='isBedAvailable'
                            onChange={this.handleBedManage}
                            control={<StyledSwitch name="isBedAvailable" checked={this.state.isBedAvailable} />}
                            label="Do you have available beds?"
                            labelPlacement="start"
                        />
                    </Box>

                    <Box style={{ paddingTop: '40px' }}>
                        <Box sx={webStyle.tabsWrapper}>
                            <Button data-test-id='bedView' onClick={this.handleOpenBedView} style={this.state.manageView === configJSON.ManagePageViewType.bed ? webStyle.activeTab : webStyle.inActiveTab}>
                                {configJSON.ManagePageButtons.Beds}
                            </Button>
                            <Button data-test-id='healthcare-view' onClick={this.handleOpenHealthCarePersonView} style={this.state.manageView === configJSON.ManagePageViewType.healthcareperson ? webStyle.activeTab : webStyle.inActiveTab}>
                                {configJSON.ManagePageButtons.HealthcarePersonnel}
                            </Button>
                        </Box>
                        <Box style={{ paddingTop: '48px' }}>
                            {this.state.manageView === configJSON.ManagePageViewType.bed && <Box sx={webStyle.tableWrapper}>
                                {!this.state.isBedAvailable && <Box data-test-id='table-overlay' sx={webStyle.tableOverlay}></Box>}
                                <Box sx={webStyle.tableTitleWrapper}>
                                    <Typography style={webStyle.tableTitle}>{configJSON.TableTitles.TotalBeds}</Typography>
                                    <Typography style={webStyle.tableTitle}>204 Beds</Typography>
                                </Box>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow style={{ backgroundColor: '#EBF9FF' }}>
                                                <TableCell style={{ ...webStyle.tableHeaderCell, paddingLeft: '26px' }} >
                                                    {configJSON.TableTitles.Select}
                                                </TableCell>
                                                <TableCell style={webStyle.tableHeaderCell} align='center'>
                                                    {configJSON.TableTitles.Beds}
                                                </TableCell>
                                                <TableCell style={webStyle.tableHeaderCell} align='center'>
                                                    {configJSON.TableTitles.Count}
                                                </TableCell>
                                                <TableCell style={webStyle.tableHeaderCell} align="center">
                                                    {configJSON.TableTitles.Price}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {configJSON.rowBedData.map((data: { id: number, bed: string, count: number, price: number }, indx: number) => {
                                                return <TableRow>
                                                    <TableCell style={webStyle.checkboxCellStyle}>
                                                        {this.renderCellOverlay(data.id)}
                                                        <Checkbox disableRipple data-test-id='row-checkbox' checked={this.state.checkedBedRows.includes(`${data.id}`)} name={`${data.id}`} onChange={this.handleChangeCheckManageBedRow} checkedIcon={<img src={checked} alt="checked_icon" />} icon={<img src={unChecked} alt="unchecked_icon" />} />
                                                    </TableCell>
                                                    <TableCell style={webStyle.dataCellWrapper}>
                                                        {this.renderCellOverlay(data.id)}
                                                        <Typography style={{ ...webStyle.cellValue, color: '#292929' }}>{data.bed}</Typography>
                                                    </TableCell>
                                                    <TableCell style={webStyle.dataCellWrapper}>
                                                        {this.renderCellOverlay(data.id)}
                                                        <Typography style={webStyle.cellValue}> {data.count}</Typography>
                                                    </TableCell>
                                                    <TableCell style={webStyle.dataCellWrapper}>
                                                        {this.renderCellOverlay(data.id)}
                                                        <Typography style={webStyle.cellValue}>{`${'\u20B9'}${data.price}`}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>}

                            {this.state.manageView === configJSON.ManagePageViewType.healthcareperson && <Box>
                                <Box sx={webStyle.doctorCardWrapper} data-test-id='healthcare-person-view'>
                                    <Box sx={webStyle.doctorCard} >
                                        <Box sx={webStyle.avatarNameWrapper}>
                                            <Avatar style={webStyle.avatar} />
                                            <Box sx={webStyle.ratingWrapper}>
                                                <Typography style={webStyle.doctorName}>Dr. Torsten Paulsson</Typography>
                                                <Typography style={webStyle.speciality}>Nephrologist</Typography>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Typography style={webStyle.rating}> <img src={ratingStar} />4.2 <span style={{ color: '#3780E0' }}> / 5</span></Typography>
                                        </Box>
                                    </Box>
                                    <Button style={webStyle.viewDetailsButton}>{configJSON.ManagePageButtons.ViewDetails}</Button>
                                </Box>
                            </Box>}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={webStyle.notificationWrapper}></Box>
        </Box >
    }
}

export default CustomisableManageHospital;

const StyledFormControlLabel = styled(FormControlLabel)({
    '&.MuiFormControlLabel-labelPlacementStart': {
        alignItems: 'center',
        gap: '24px'
    },
    '& .MuiTypography-root': {
        color: 'black',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Regular',
        lineHeight: '18px'
    }
})

const StyledSwitch = styled(Switch)({
    "&.MuiSwitch-root": {
        height: '26px',
        width: '48px',
        padding: '0'
    },
    '& .MuiSwitch-thumb': {
        height: '18px',
        width: '18px'
    },
    '& .MuiSwitch-switchBase': {
        padding: '4px'
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(20px)'
    },
    '& .MuiSwitch-colorSecondary.Mui-checked': {
        color: "white"
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#02A405',
        opacity: 1
    },
    '& .MuiSwitch-track': {
        borderRadius: '13px',
    },
})

const StyledSearch = styled(TextField)({
    maxWidth: '848px',
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #014866",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        borderRadius: "8px",
        paddingLeft: "16px",
    },
    "& .MuiFormHelperText-root": {
        marginLeft: 0,
        fontFamily: 'SF Pro Text Regular',
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '0'
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
        paddingLeft: '10px'
    }
})


const webStyle = {
    checkboxCellStyle: {
        padding: '0 16px 0 40px',
        position: 'relative'
    } as React.CSSProperties,
    dataCellWrapper: {
        padding: '0 16px',
        height: '45px',
        position: 'relative'
    } as React.CSSProperties,
    cellOverlay: {
        position: "absolute",
        backgroundColor: 'white',
        opacity: '0.5',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    } as React.CSSProperties,
    doctorCardWrapper: {
        display: 'flex',
        alignItems: 'center',
        gridColumnGap: '50px'
    },
    manageTitle: {
        fontFamily: 'SF Pro Text Bold',
        fontSize: '28px'
    },
    cellValue: {
        color: '#3780E0',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Regular',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: "ellipsis"
    } as React.CSSProperties,
    viewDetailsButton: {
        color: 'white',
        backgroundColor: '#014866',
        maxWidth: "170px",
        width: '100%',
        textTransform: 'capitalize',
        borderRadius: '8px',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold',
        height: '52px'
    } as React.CSSProperties,
    tabsWrapper: {
        display: 'flex',
        padding: '11px 20px',
        alignItems: 'center',
        boxSizing: 'border-box',
        maxWidth: '350px',
        width: '100%',
        border: '1px solid #808080',
        borderRadius: '8px',
        justifyContent: 'space-between'
    },
    switchWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '40px',
        maxWidth: '848px'
    },
    tableTitleWrapper: {
        padding: '30px 40px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tableWrapper: {
        position: "relative",
        boxShadow: "0px 2px 24px 0px #B3B3B31F",
        border: 'border: solid #5F5F5F09',
        borderRadius: '25px',
        overflow: 'hidden',
        boxSizing: 'border-box',
        maxWidth: '700px'
    } as React.CSSProperties,
    tableOverlay: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'white',
        zIndex: 10,
        opacity: '0.5',
        cursor: "not-allowed"
    } as React.CSSProperties,
    tableTitle: {
        color: '#6C6C6C',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Bold'
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%'
    },
    sidebarWrapper: {
        width: '132px'
    },
    rightSideContentWrapper: {
        padding: '46px 60px',
        display: 'flex',
        flexDirection: 'column'
    } as React.CSSProperties,
    searchIcons: {
        width: "24px",
        objectFit: "cover" as const,
        height: "24px",
        display: "flex",
        justifyContent: "center",
        margin: "0px auto"
    },

    activeTab: {
        textTransform: 'capitalize',
        backgroundColor: '#3780E0',
        color: 'white',
        borderRadius: '8px',
        fontSize: '18px',
        padding: "10px 25px",
        lineHeight: '22px',
        fontFamily:'SF Pro Text Semibold'
    } as React.CSSProperties,
    inActiveTab: {
        color: '#808080',
        fontFamily: 'SF Pro Text Medium',
        padding: '10px 0px',
        fontSize: '18px',
        lineHeight: '22px',
        textTransform: 'capitalize'
    } as React.CSSProperties,
    tableHeaderCell: {
        color: '#014866',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Semibold',
        borderBottom: 'none'
    },
    doctorCard: {
        display: 'flex',
        alignItems: 'center',
        padding: '18px 32px',
        borderRadius: '8px',
        justifyContent: 'space-between',
        border: '1px solid #80808021',
        boxShadow: "0px 2px 40px 0px #B3B3B31F",
        maxWidth: '540px',
        width: '100%'
    },
    avatarNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '20px'
    },
    avatar: {
        width: '85px',
        height: '85px'
    },
    ratingWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    doctorName: {
        fontSize: '20px',
        fontFamily: 'SF Pro Text Bold',
        marginBottom: "8px"
    },
    speciality: {
        color: '#AC8E68',
        fontSize: '14px',
        fontFamily: 'SF Pro Text Regular'
    },
    rating: {
        color: '#014866',
        fontSize: '14px',
        fontFamily: 'SF Pro Text Medium',
        textAlign: 'end',
        marginBottom: "8px"
    } as React.CSSProperties,
    notificationWrapper: {
        minWidth: '340px'
    },
}

