// Customizable Area Start
import React from "react";
import SchedulingController, { Props } from "./SchedulingController";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, Button, Checkbox, ClickAwayListener, Dialog, Grid, InputAdornment, Popover, SvgIcon, TextField, ThemeProvider, Typography, createTheme, withStyles } from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Calender, PenIcon, successIcon, GreenToggle, GrayToggle, Arrow, DownArrow, GrayDownArrow } from "./assets";
import CloseIcon from "@material-ui/icons/Close";

const theme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#0000ff",
        }
    }
});

const CustomsCheckboxIconChecked = () => (<SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" /><path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></SvgIcon>);
  
const CustomsCheckboxIconUnChecked = () => (
<SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/></SvgIcon>);
// Customizable Area End

export class SchedulingCalendar extends SchedulingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        await this.apiHealthcarePersonnelAvailabilities()
    }

    TimeSlot = (
        title:  "Morning" | "Evening",
        duration: string,
        slots: string[],
        classes: any,
        index: number
    ) => {
        let updatedSlot = []
        if(title === "Morning"){
            updatedSlot = this.updateSlots(slots, "Morning", "3:30 pm");
        }
        else {
            updatedSlot = this.updateSlots(slots, "Evening", "3:30 pm");
        }
        return (
            <Box className={classes.availabilitySlotContainer}>
                <Box style={{ width: "25%", maxWidth: "150px", marginTop: "12px" }}>
                    <Typography variant="h4" className={classes.weekDays}>
                        {title}
                    </Typography>
                    <Typography variant="h4" className={classes.slotDuration}>
                        {duration}
                    </Typography>
                </Box>
                <Box
                    key={index}
                    style={{
                        width: "75%",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "2px",
                        justifyContent: "flex-start",
                    }}
                >
                    {updatedSlot.length > 0 && updatedSlot.map((slot: string, slotIndex: number) => (
                        <Button
                            key={slotIndex}
                            variant="contained"
                            data-test-id={`selectSlot${slotIndex}${index}`}
                            onClick={() => this.handleSlotSelect(slot,title)}
                            className={`${this.state.selectedSlots.includes(slot)? classes.selected: classes.unSelected}`}
                        >
                         {slot}
                        </Button>
                    ))}
                </Box>
            </Box>
        );
    };

    showHeading = (classes:any) => {
        return (
            <Typography variant="h6" className={classes.calendarTitle}>
                {this.state.isEdit ? "Edit Availability" : "Calendar"}
            </Typography>
        )
    }

    getSuccessConfirmationPopup = (classes: any) => {
        return (
            <>
                {this.state.showSuccessMsg ?
                    (
                        <Box className={classes.successPopover}>
                            <img src={successIcon} width={140} height={140} className={classes.successIcon} />
                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                The slot has been cancelled
                            </Typography>
                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                successfully. The appointment gets
                            </Typography>
                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                moved to the other slot
                            </Typography>
                            <Button
                                variant="contained"
                                className={classes.doneBtn}
                                data-test-id="closeSlotCancellation"
                                onClick={this.closeSlotCancellation}
                            >
                                Done
                            </Button>
                        </Box>
                    )
                    :
                    (
                        <Box className={classes.hpPopoverConfirmationContainer}>
                            <Typography variant="h6" className={classes.popoverHeading}>
                                Are you sure?
                            </Typography>
                            <Box className={classes.confirmCancellation}>
                                <Button
                                    variant="contained"
                                    className={classes.cancelSlotBtn}
                                    data-test-id="handelCancelAllSlot"
                                    onClick={() => this.handelCancelAllSlot()}
                                >
                                    No
                                </Button>
                                <Button
                                    variant="contained"
                                    className={classes.cancelSlotConfirmationBtn}
                                    data-test-id="handelShowSuccessMsg"
                                    onClick={() => this.handelShowSuccessMsg()}
                                >
                                    Yes
                                </Button>
                            </Box>
                        </Box>
                    )}</>
        )
    }

    getSlotCancellationContainer = (classes: any) => {
        return (
            <>
                <Box className={classes.hpPopoverContainer}>
                    <Typography variant="h6" className={classes.popoverHeading}>
                        Select slot to be cancelled
                    </Typography>
                    <Box
                        data-test-id="slot1"
                        className={
                            this.state.cancelSlot.includes("10:30 AM - 11:15 AM")
                                ? classes.cancelAppointmentCard
                                : classes.appointmentCard
                        }
                        onClick={() => this.handleCancelBooking("10:30 AM - 11:15 AM")}
                    >
                        <Typography variant="h6" className={classes.patientSlot}>
                            Appointment with Mrs. Marina & Mr.John
                        </Typography>
                        <Typography variant="h6" className={classes.slotBooked}>
                            10:30 AM - 11:15 AM
                        </Typography>
                    </Box>
                    <Box
                        data-test-id="slot2"
                        className={
                            this.state.cancelSlot.includes("11:15 AM - 12 PM")
                                ? classes.cancelAppointmentCard
                                : classes.appointmentCard
                        }
                        onClick={() => this.handleCancelBooking("11:15 AM - 12 PM")}
                    >
                        <Typography variant="h6" className={classes.patientSlot}>
                            Appointment with Mrs. Marina & Mr.John
                        </Typography>
                        <Typography variant="h6" className={classes.slotBooked}>
                            11:15 AM - 12 PM
                        </Typography>
                    </Box>
                    <Button
                        variant="contained"
                        data-test-id="continueHandelSlot"
                        className={classes.continueBtn}
                        onClick={this.handelSelectedSlot}
                    >
                        Continue
                    </Button>
                </Box>
            </>
        )
    }

    getSingleSlotPopup = (classes: any) => {
        return (
            <Box className={classes.hpPopoverContainer}>
                <Typography variant="h6" className={classes.popoverHeading}>
                    Which appointment do you want to cancel?
                </Typography>
                <Box className={this.state.slot === "10:30 - 11-15am" ? classes.appointmentSelectedNewCard : classes.appointmentSelectedTransparentCard} data-test-id="showSlotCancelPopup0" onClick={() => this.handelShowSlotCancelPopup("10:30 - 11-15am")}>
                    <Typography variant="h6" className={classes.patientSlot}>Appointment with Mrs. Marina</Typography>
                </Box>
                <Box className={this.state.slot === "11:15 - 12pm" ? classes.appointmentSelectedNewCard : classes.appointmentSelectedTransparentCard} data-test-id="showSlotCancelPopup1" onClick={() => this.handelShowSlotCancelPopup("11:15 - 12pm")}>
                    <Typography variant="h6" className={classes.patientSlot}>Appointment with  Mr.John</Typography>
                </Box>
                <Button
                    variant="contained"
                    className={classes.confirmButton}
                    data-test-id="confirmRemoveSlot"
                    onClick={() => this.handelRemoveSlot()}
                >
                    Confirm
                </Button>
            </Box>
        )
    }

    getCancelConfirmationContent = (classes: any) => {
        return (
            <>
                <Box>
                    {this.state.cancelAllSlot ? (
                        <Box className={classes.confirmationMessage}>
                            {this.state.cancelSlot.length === 2
                                ?
                                (<>
                                    {this.state.removeSlot ? (
                                        <>
                                            {this.getSuccessConfirmationPopup(classes)}
                                        </>
                                    ) : (
                                        <Box style={{ paddingTop: "40px", paddingBottom: "40px" }} className={classes.hpPopoverContainer}>
                                            <Typography variant="h6" className={classes.popoverHeading}>
                                                Do you want to cancel both the slots?
                                            </Typography>
                                            <Box className={classes.confirmCancellation}>
                                                <Button
                                                    variant="contained"
                                                    className={classes.cancelSlotBtn}
                                                    data-test-id="handelCancelSlot"
                                                    onClick={() => this.handelCancelSlot()}
                                                >
                                                    No
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    data-test-id="handelRemoveSlot"
                                                    className={classes.cancelSlotConfirmationBtn}
                                                    onClick={() => this.handelRemoveSlot()}
                                                >
                                                    Yes
                                                </Button>
                                            </Box>
                                        </Box>
                                    )}
                                </>)
                                :
                                (<>
                                    {this.state.removeSlot ? (
                                        <>
                                            {this.getSucceesConfirmatiomAllSlots(classes)}
                                        </>
                                    ) : (
                                            <>
                                                {this.getSingleSlotPopup(classes)}
                                            </>
                                    )}</>
                                )}
                        </Box>
                    ) : (
                        <>
                            {this.getSlotCancellationContainer(classes)}
                        </>
                    )}
                </Box>
            </>
        )
    }

    getSlotListContent = (classes: any) => {
        return (
            <>
                <Box className={classes.hpPopoverContainer}>
                    <Typography variant="h6" className={classes.popoverHeading}>
                        Slots
                    </Typography>
                    <Box className={classes.appointmentCard} data-test-id="currentSlotAppointment0" onClick={() => this.handelShowSlotCancelPopup("10:30 - 11-15am")}>
                        <Typography variant="h6" className={classes.patientSlot}>
                            Appointment with Mrs. Marina & Mr.John
                        </Typography>
                        <Typography variant="h6" className={classes.slotBooked}>
                            10:30 AM - 11: 15 AM
                        </Typography>
                    </Box>
                    <Box className={classes.appointmentCard} data-test-id="currentSlotAppointment1" onClick={() => this.handelShowSlotCancelPopup("11-15 - 12pm")}>
                        <Typography variant="h6" className={classes.patientSlot}>
                            Appointment with Mrs. Marina & Mr.John
                        </Typography>
                        <Typography variant="h6" className={classes.slotBooked}>
                            11: 15 AM - 12 PM
                        </Typography>
                    </Box>
                </Box>
            </>
        )
    }

    getSucceesConfirmatiomAllSlots = (classes: any) => {
        return(
        <>
            {this.state.showSuccessMsg ?
                (
                    <Box className={classes.successPopover}>
                        <img src={successIcon} width={140} height={140} className={classes.successIcon} />
                        <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                            Appointment with Mrs. Marina
                        </Typography>
                        <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                            slot {this.state.slot} cancelled
                        </Typography>
                        <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                            successfully.
                        </Typography>
                        <Button
                            data-test-id="clearSingleSlot"
                            variant="contained"
                            className={classes.doneBtn}
                            onClick={this.closeSlotCancellation}
                        >
                            Done
                        </Button>
                    </Box>
                )
                :
                (
                    <Box className={classes.hpPopoverConfirmationContainer}>
                        <Typography variant="h6" className={classes.popoverHeading}>
                            Are you sure?
                        </Typography>
                        <Box className={classes.confirmCancellation}>
                            <Button
                                variant="contained"
                                data-test-id="singleSlotStopSlotCancellation"
                                className={classes.cancelSlotBtn}
                                onClick={() => this.handelCancelAllSlot()}
                            >
                                No
                            </Button>
                            <Button
                                variant="contained"
                                data-test-id="singleSlotCancellation"
                                className={classes.cancelSlotConfirmationBtn}
                                onClick={() => this.handelShowSuccessMsg()}
                            >
                                Yes
                            </Button>
                        </Box>
                    </Box>
            )}
        </>)
    }

    getDayBoxes = (activeDays:string[]) => {
        const days = [
            { text: "S", day: "Sunday", isActive: activeDays.includes("Sunday") },
            { text: "M", day: "Monday", isActive: activeDays.includes("Monday") },
            { text: "T", day: "Tuesday", isActive: activeDays.includes("Tuesday") },
            { text: "W", day: "Wednesday", isActive: activeDays.includes("Wednesday") },
            { text: "T", day: "Thursday", isActive: activeDays.includes("Thursday") },
            { text: "F", day: "Friday", isActive: activeDays.includes("Friday") }
          ];
      
        return days.map((selectedDay: {
            text: string;
            day: string;
            isActive: boolean;
        }) => (
            <Box
                key={selectedDay.day}
                data-test-id={`selectedDay-${selectedDay.day}`} 
                onClick={() => this.handleDayClick(selectedDay.day)}
                style={{
                    width: "27px",
                    height: "33px",
                    borderRadius: "6px",
                    color: selectedDay.isActive ? "#FFFFFF" : "#7A7A7A",
                    backgroundColor: selectedDay.isActive ? "#3780E0" : "#D7D2D2",
                    fontFamily: "SF Pro Text Medium",
                    fontSize: "16px",
                    marginRight: "5px",
                    alignContent: "center",
                    textAlign: "center",
                    cursor: "pointer",
                }}
            >
                {selectedDay.text}
            </Box>
        ));
    }
    
    getRecurrenceType = () => {
        const classes = this.props.classes;
        return this.state.isRecurrence ? classes.recurrence : classes.notePopover
    } 

    getDateIfFullDayFalse = (classes:any) =>{
        return(
            <>
             {!this.state.isFullDay && 
            (
                <>
                    <Typography variant="h4" className={classes.text}>{this.handleCustomDateFormatChange(this.state.date)}</Typography>
                    <Typography variant="h4" className={classes.text}>{this.handleCustomDateFormatChange(this.state.date)}</Typography>
                </>
            )}
            </>
        )
    }

    showStartTiming = (classes: any, start_time: string) => {
        let newStartTime = start_time;
        if(newStartTime.startsWith("0")){
            newStartTime = newStartTime.slice(1)
        };

        return (
            <>
                {!this.state.isEditStartDate ?
                    (
                        <Typography variant="h4" className={classes.slotdate}>
                            <span onClick={() => this.handleStartTimeClick()} data-test-id="isEditStartDate" style={{ cursor: "pointer" }}>
                                {newStartTime}
                            </span>
                        </Typography>
                    ) : (
                        <Box className={classes.selectTimeContainer}>
                            <select style={{
                                width: "46px",
                                cursor: "pointer",
                                fontFamily: "SF Pro Text Regular",
                                fontSize: "18px",
                                backgroundColor: "#fff",
                                color: "#292929",
                                border: "1px solid #808080",
                                borderRadius: "4px"
                               }}
                                data-test-id="selectStartingHour"
                                onChange={(event) => this.handleHourChange(event)}
                                value={start_time.split(/[:\s]/)[0]}>
                                {Array.from({ length: 12 }, (_, i) => (<option key={i} value={String(i + 1).padStart(2, '0')}>{String(i + 1).padStart(2, '0')}</option>))}
                            </select>
                            <select style={{
                                width: "46px",
                                border: "1px solid #808080",
                                cursor: "pointer",
                                fontFamily: "SF Pro Text Regular",
                                fontSize: "18px",
                                backgroundColor: "#fff",
                                color: "#292929",
                                borderRadius: "4px"
                            }}
                               data-test-id="selectStartingMinute"
                                onChange={(event) => this.handleMinuteChange(event)}
                                value={start_time.split(/[:\s]/)[1]}>
                                <option value="00">00</option>
                                <option value="30">30</option>
                            </select>
                            <select style={{
                                width: "50px",
                                backgroundColor: "#fff",
                                color: "#292929",
                                border: "1px solid #808080",
                                cursor: "pointer",
                                fontFamily: "SF Pro Text Regular",
                                fontSize: "18px",
                                borderRadius: "4px"
                                }}
                                data-test-id="selectTime"
                                onChange={(event) => this.handleAmPmChange(event)}>
                                <option value="am">am</option>
                                <option value="pm">pm</option>
                            </select>
                        </Box>
                    )}
            </>)
    }

    showEndTiming = (classes: any, end_time: string) => {
        let newEndTime = end_time;
        if(newEndTime.startsWith("0")){
            newEndTime = newEndTime.slice(1)
        };
        return (
            <>
                {!this.state.isEditEndDate ?
                    (
                        <Typography variant="h4" className={classes.slotdate}>
                            <span onClick={() => this.handleEndTimeClick()} data-test-id="isEditEndDate" style={{ cursor: "pointer" }}>
                                {newEndTime}
                            </span>
                        </Typography>
                    ) : (
                        <Box className={classes.selectTimeContainer}>
                            <select style={{
                                width: "46px",
                                backgroundColor: "#fff",
                                color: "#292929",
                                border: "1px solid #808080",
                                cursor: "pointer",
                                fontFamily: "SF Pro Text Regular",
                                fontSize: "18px",
                                borderRadius: "4px"
                                }}
                                data-test-id="selectEndingHour"
                                onChange={(event) => this.handleEndHourChange(event)}
                                value={end_time.split(/[:\s]/)[0]}>
                                {Array.from({ length: 12 }, (_, i) => (
                                    <option key={i} value={String(i + 1).padStart(2, '0')}>
                                        {String(i + 1).padStart(2, '0')}
                                    </option>
                                ))}
                            </select>
                            <select style={{
                                width: "46px",
                                backgroundColor: "#fff",
                                color: "#292929",
                                border: "1px solid #808080",
                                cursor: "pointer",
                                fontFamily: "SF Pro Text Regular",
                                fontSize: "18px",
                                borderRadius: "4px"
                                }}
                                data-test-id="selectEndingMinute"
                                onChange={(event) => this.handleEndMinuteChange(event)}
                                value={end_time.split(/[:\s]/)[1]}>
                                <option value="00">00</option>
                                <option value="30">30</option>
                            </select>
                            <select style={{
                                width: "50px",
                                backgroundColor: "#fff",
                                color: "#292929",
                                border: "1px solid #808080",
                                cursor: "pointer",
                                fontFamily: "SF Pro Text Regular",
                                fontSize: "18px",
                                borderRadius: "4px"
                                }}
                                data-test-id="selectEndTime"
                                onChange={(event) => this.handleAmPmEndChange(event)}>
                                <option value="am">am</option>
                                <option value="pm">pm</option>
                            </select>
                        </Box>
                    )}
            </>)
    }

    getRecurrence = (classes:any) => {
        const [start, end] = this.state.slot.split(' - ');
        const start_time = this.state.slot && this.formatTimeBasedOnTitle(start);
        const end_time = this.state.slot && this.formatTimeBasedOnTitle(end);
        return(
            <Box className={this.getRecurrenceType()}>
                {!this.state.isRecurrence ? (
                    <>
                        <Box>
                        <Typography style={{fontFamily:"SF Pro Text Regular", fontSize:"14px", color:"FF0000",textAlign:"center"}}>{this.state.error}</Typography>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center" }}>
                                <Box>
                                    {!this.state.isAddTitle ? (
                                        <Typography
                                        variant="h4"
                                        data-test-id="addNewTitle"
                                        className={classes.title}
                                        onClick={this.handleClick}
                                        style={{ cursor: 'pointer' }} 
                                        >
                                        {this.state.title || "Add title"}
                                        </Typography>
                                    ) : (
                                        <input
                                        type="text"
                                        data-test-id="enteringTitle"
                                        className={classes.titleInput}
                                        value={this.state.title}
                                        onChange={this.handleTitleChange}
                                        autoFocus 
                                        />
                                    )}
                                    </Box>
                                <Box>
                                    <CloseIcon style={{ cursor: "pointer", width: "25px", height: "25px" }} onClick={() => this.handelAvaibilityPopup("","")} />
                                </Box>
                            </Box>
                            <Box style={{ border: "none", width: "355px", height: "1px", backgroundColor: "#D8D8D8" }} />
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center" }}>
                                <Box>
                                    <Typography variant="h4" className={classes.text}>Full Day</Typography>
                                    {this.getDateIfFullDayFalse(classes)}
                                </Box>
                                <Box>
                                    {!this.state.isFullDay ?
                                        (
                                            <>
                                                <img
                                                    width="46"
                                                    height="24"
                                                    src={GrayToggle}
                                                    style={{ margin: "30px 0px" }}
                                                    onClick={this.handelIsFullDay}
                                                />
                                                {this.showStartTiming(classes,start_time)}
                                                {this.showEndTiming(classes,end_time)}
                                            </>
                                        ) : (
                                            <img
                                                width="46"
                                                height="24"
                                                data-test-id="fullDay"
                                                src={GreenToggle}
                                                style={{ marginTop: "29px" }}
                                                onClick={this.handelIsFullDay}
                                            />)}
                                </Box>
                            </Box>
                            <Box style={{ border: "none8", width: "355px", marginTop: "15px", height: "1px", backgroundColor: "#D8D8D8" }} />
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center", marginTop: "20px" }}>
                                <Typography variant="h4" className={classes.title} onClick={this.handelIsRecurrence} data-test-id="noRepeat">Does not repeat</Typography>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignContent: "center", textAlign: "center", gap: "20px" }}>
                                <Button
                                    variant="contained"
                                    className={classes.discardButton}
                                    data-test-id="discard"
                                    onClick={() => this.handelAvaibilityPopup("")}
                                >
                                    Discard
                                </Button>
                                <Button
                                    variant="contained"
                                    data-test-id="save"
                                    className={classes.saveButton}
                                    onClick={() => this.createCustomAvailabilities()}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </>
                ) : (
                    <>
                        {["Does not repeat", "Every day", "Every week", "Every month", "Every year", "Custom"].map((scheduleDateLabel, index) => (
                            <div key={index}>
                                <Checkbox
                                    className={classes.checkbox}
                                    data-test-id={`selectedRecurringType${index}`}
                                    checked={this.state.selectedType === scheduleDateLabel}
                                    onChange={() => this.handelSelectedype(scheduleDateLabel, this.state.recurringType)}
                                    icon={<CustomsCheckboxIconUnChecked />}
                                    checkedIcon={<CustomsCheckboxIconChecked />}
                                />
                                <span className={this.state.selectedType === scheduleDateLabel ? classes.selectedDay : classes.unSelectedDay}>{scheduleDateLabel}</span>
                            </div>
                        ))}
                    </>
                )}
            </Box>
        )
    }

    getRecussingType = (classes: any) => {
        return (
            <>
                {!this.state.isOpen ?
                    (
                        <TextField
                            variant="outlined"
                            placeholder="Select recurring type"
                            value={this.state.recurringType}
                            className={classes.repeatInputFields}
                             InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img
                                            src={DownArrow}
                                            width="15"
                                            height="8"
                                            onClick={this.handelSelectOption}
                                        />
                                    </InputAdornment>
                                ),
                                readOnly: true,
                            }}
                            data-test-id="recurringType"
                            onClick={this.handelSelectOption}
                        />
                    ) : (
                        <Box style={{ width: "302px", height: "145px", border: "1px solid #808080", borderRadius: "8px", padding: "20px" ,marginBottom:"10px"}}>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography variant="h4" style={{ width: "50%", fontFamily: "SF Pro Text Medium", fontSize: "16px", color: "#292929", marginBottom: "15px" }}>
                                    {this.state.recurringType}
                                </Typography>
                                <img
                                    width="15"
                                    height="8"
                                    src={GrayDownArrow}
                                    onClick={this.handelSelectOption}
                                />
                            </Box>
                            <Box style={{ border: "none", width: "306px", height: "1px", backgroundColor: "#D8D8D8", marginBottom: "15px" }} />
                            <Typography variant="h4" style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", marginBottom: "15px" }} data-test-id="everyDay" onClick={() => this.handelCustomSelectedype('Every day', "Daily")}>
                                Daily
                            </Typography>
                            <Typography variant="h4" style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", marginBottom: "15px" }} data-test-id="everyWeek" onClick={() => this.handelCustomSelectedype('Every week', "Weekly")}>
                                Weekly
                            </Typography>
                            <Typography variant="h4" style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", marginBottom: "15px" }} data-test-id="everyMonthly" onClick={() => this.handelCustomSelectedype('Every month', "Monthly")}>
                                Monthly
                            </Typography>
                        </Box>
                    )
                }
                {this.state.selectedCustomType === "Every week" && (
                    <>
                        <Typography variant="h4" style={{ fontFamily: "SF Pro Text Medium", fontSize: "16px", color: "#292929", margin: "20px auto" }}>Repeate On</Typography>
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                            {this.getDayBoxes(this.state.activeDays)}
                        </Box>

                    </>
                )}
                {this.state.selectedCustomType === "Every month" && (
                    <Box>
                        {!this.state.isMonthly ? (<TextField
                            variant="outlined"
                            placeholder="Select"
                            value={this.state.selectedMonthType}
                            className={classes.repeatInputFields}
                            data-test-id="selectMonthType"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <img
                                            src={DownArrow}
                                            width="15"
                                            height="8"
                                            onClick={this.handelMonthlySelectOption}
                                        />
                                    </InputAdornment>
                                ),
                                readOnly: true,
                            }}
                            onClick={this.handelMonthlySelectOption}
                        />) : (
                            <Box style={{ width: "302px", height: "125px", border: "1px solid #808080", borderRadius: "8px", padding: "20px" }}>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography
                                        variant="h4"
                                        style={{
                                            width: "50%",
                                            fontSize: "16px",
                                            fontFamily: this.state.selectedMonthType ? "SF Pro Text Medium" : "SF Pro Text Regular",
                                            color: this.state.selectedMonthType ? "#292929" : "#808080",
                                            marginBottom: "15px"
                                        }}
                                    >
                                        Select
                                    </Typography>
                                    <img
                                        width="15"
                                        height="8"
                                        src={GrayDownArrow}
                                        onClick={this.handelMonthlySelectOption}
                                    />
                                </Box>
                                <Box style={{ border: "none", width: "306px", height: "1px", backgroundColor: "#D8D8D8", marginBottom: "15px" }} />
                                <Typography variant="h4" style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", marginBottom: "15px" }} data-test-id="selectDate" onClick={() => this.handelselectedMonthType(`Monthly on day ${new Date(this.state.date).getDate()}`,"on_date")}>
                                    Monthly on day {new Date(this.state.date).getDate()}
                                </Typography>
                                <Typography variant="h4" style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", marginBottom: "15px"}} data-test-id="selectDay" onClick={() => this.handelselectedMonthType(`Monthly on second ${this.formatDateToDayAbbreviation('day')}`,"on_day")}>
                                    Monthly on second {this.formatDateToDayAbbreviation('day')}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </>
        )
    }

    recurringClasses = (classes:any)=>{
        if (!this.state.isRecurrence && !this.state.isRepeat) { return classes.popover }
        else if (this.state.isRecurrence) { return classes.customRecurrence }
        else { return classes.hpNewPaperPropsClass }
    }

    leaveHandel = (classes: any) => {
        return (
            <Box className={classes.availabilitySlotContainer}>
                <Box className={this.state.isEdit ? classes.leaveLabel : classes.manageAvaibilityscheduleDateLabel}>
                    <Box className={this.state.isEdit ? classes.leaveLabel : classes.manageAvaibilitylabel} data-test-id="selectRecurringOption">
                        {this.state.isEdit ? this.formatDateToDayAbbreviation("date") : "Manage Availability"}
                    </Box>
                </Box>
                <Box className={this.state.isEdit ? classes.leaveLabel : classes.editBtn} data-test-id="selectLeave" onClick={this.handelOpenEdit}>
                    {this.state.isEdit ? (
                        <>
                            <span>Leave{' '}</span>
                            <span>
                                {this.state.isLeave ? (
                                    <img width="46" height="24" src={GreenToggle} alt="Green Toggle" data-test-id="leave" onClick={this.handelLeave}/>
                                ) : (
                                    <img width="46" height="24" src={GrayToggle} alt="Gray Toggle" data-test-id="noLeave" onClick={this.handelLeave} />
                                )}
                            </span>
                        </>
                    ) : (
                        <>
                            <span style={{ cursor:"pointer !important" }}> <img width="16" height="16" src={PenIcon} alt="Edit Icon" style={{ marginTop: "2px", cursor:"pointer !important" }} /> </span> {' '}Edit
                        </>
                    )}
                </Box>
            </Box>
        )
    }

    calendarPopUp = (classes:any, stateMessage:string, messageType:string) => {
        return (
            <Box>
              <Dialog open={!!stateMessage}>
              <Box className={classes.customContainer}>
                <Box style={{ padding: "0px 10px" }}>
                  <p className={classes.scheduleCalendarHeading}>{messageType}</p>
                  <p className={classes.scheduleCalendarParagraph}>{stateMessage}</p>
                  <Button style={{
                    borderRadius: "3px",
                    backgroundColor: "rgb(33, 150, 243)",
                    border: "none",
                    color: "#fff",
                    padding: "5px",
                    marginBottom: "15px",
                    marginTop:"10px",
                    fontFamily:"SF Pro Text Regular"
                  }} 
                  onClick={this.closePopup}>OK</Button>
                </Box>
              </Box>
            </Dialog>
            </Box>
        )
      }

    showOccurance = (classes: any) => {
        const endTypeClasses = this.state.endType ? classes.selectedDay : classes.unSelectedDay;
        const isOccuranceClasses = this.state.isOccurrence ? classes.selectedDay : classes.unSelectedDay;
        return (
            <>
                <Popover
                    className={this.recurringClasses(classes)}
                    open={this.state.avaibilityPopup}
                    data-test-id="closeAvaibilityPopup"
                    anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                    transformOrigin={{ horizontal: 'center', vertical: 'center', }}
                    onClose={() =>this.handelAvaibilityPopup}
                >
                    {!this.state.isRepeat ? (
                        <>
                            {this.getRecurrence(classes)}
                        </>
                    ) : (
                        <Box className={classes.notePopover}>
                            <Box style={{ display: "flex", marginBottom: "15px", gap: "25px", alignContent: "center", alignItems: "center" }}>
                                <img width="10" height="15" src={Arrow} onClick={this.handelUpdateSelectedType} />
                                <Typography variant="h4" className={classes.customReferanceBackBtn}>Custom Recurrence</Typography>
                            </Box>
                            <Box style={{ border: "none", width: "343px", height: "1px", backgroundColor: "#D8D8D8", marginBottom: "15px" }} />
                            <Typography variant="h4" style={{ fontFamily: "SF Pro Text Medium", fontSize: "16px", color: "#292929", margin: "20px auto" }}>Repeate Every</Typography>
                            {this.getRecussingType(classes)}
                            <Typography variant="h4" style={{ fontFamily: "SF Pro Text Medium", fontSize: "16px", color: "#292929", margin: "20px auto" }}>Ends</Typography>
                            <Box style={{ marginBottom: "12px" }}>
                                <Checkbox
                                    className={classes.checkbox}
                                    data-test-id="onEnd"
                                    checked={this.state.endType}
                                    onClick={this.handelEndType}
                                    icon={<CustomsCheckboxIconUnChecked />}
                                    checkedIcon={<CustomsCheckboxIconChecked />}
                                />
                                <span className={endTypeClasses}>On</span>
                                <span className={endTypeClasses} style={{ margin: "0px 15px" }}>
                                    <DatePicker
                                        data-test-id="selectCustomDate"
                                        popperPlacement="bottom-end"
                                        popperModifiers={[
                                            {
                                                name: "offset",
                                                options: {
                                                    offset: [60, 0],
                                                },
                                            },
                                            {
                                                name: "preventOverflow",
                                                options: {
                                                    rootBoundary: "viewport",
                                                    tether: false,
                                                    altAxis: true,
                                                },
                                            },
                                        ]}
                                        onChange={this.handleCustomDateChange}
                                        className={classes.customDateField}
                                        dateFormat="yyyy/MM/dd"
                                        calendarClassName={classes.endDatepickerClass}
                                        value={this.formatCustomDate(this.state.customDate)}
                                        placeholderText="Select Date"
                                        minDate={new Date()}
                                        selected={this.state.customDate}
                                    />
                                </span>
                            </Box>
                            <ClickAwayListener onClickAway={this.closeDropdown}>
                                <Box style={{ marginBottom: "12px", position: "relative" }}>
                                    <Checkbox
                                        className={classes.checkbox}
                                        checked={this.state.isOccurrence}
                                        data-test-id="isOccurrence"
                                        onClick={this.handleOccurrence}
                                        icon={<CustomsCheckboxIconUnChecked />}
                                        checkedIcon={<CustomsCheckboxIconChecked />}
                                    />
                                    <span className={isOccuranceClasses}>After</span>
                                    <span
                                        className={isOccuranceClasses}
                                        style={{
                                            height: "22px",
                                            border: "1px solid #808080",
                                            padding: "10px",
                                            borderRadius: "8px",
                                            margin: "0px 15px",
                                            cursor: "pointer",
                                        }}
                                        data-test-id="openToggle"
                                        onClick={this.toggleDropdown}
                                    >
                                        {this.state.occurrenceCount}
                                    </span>
                                    {this.checkStatus() && (
                                        <Box
                                            style={{
                                                position: "absolute",
                                                top: "44px",
                                                left: "95px",
                                                border: "1px solid rgb(147, 143, 143)",
                                                borderRadius: "8px",
                                                backgroundColor: "rgb(255, 255, 255)",
                                                zIndex: 1000,
                                                maxHeight: "124px",
                                                overflowY: "auto",
                                                width: "32px",
                                                scrollbarColor: "transparent transparent",
                                                scrollbarWidth:"none"
                                            }}
                                        >
                                            {Array.from({ length: 60 }, (_, index) => (
                                               <div
                                                    key={index + 1}
                                                    data-test-id={`selectOccurance${index}`}
                                                    onClick={() => this.handleOccurrenceCountChange(index + 1)}
                                                    style={{
                                                        paddingTop: "5px",
                                                        paddingBottom: "5px",
                                                        cursor: "pointer",
                                                        textAlign: "center",
                                                        backgroundColor: this.state.occurrenceCount === index + 1 ? "#3780E0" : "#fff",
                                                        color: this.state.occurrenceCount === index + 1 ? "#fff" : "#000",
                                                    }}
                                                >
                                                    {index + 1}
                                                </div>
                                            ))}
                                        </Box>
                                    )}
                                    <span className={isOccuranceClasses}>Occurrence</span>
                                </Box>
                            </ClickAwayListener>
                            <Button
                                data-test-id="setAvaibility"
                                variant="contained"
                                className={classes.doneBtn}
                                onClick={this.goToRecurrence}
                            >
                                Done
                            </Button>
                        </Box>
                    )}
                </Popover>
            </>
        )
    }

    checkStatus = () => {
        return this.state.showDropdown && this.state.isOccurrence;
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const totalWidth = 60;
        const height = 14;
        const selectedWidth = (50 / 100) * totalWidth;
        const unSelectedWidth = totalWidth - selectedWidth;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Grid container className={classes.schedularContainer}>
                        <Grid item xs={1}>
                            <Sidebar />
                        </Grid>
                        <Grid item xs={11}>
                            <Box className={classes.schedularAvailbleContainer}>
                                <Box className={classes.schedularHeaderContainer}>
                                    <Box className={classes.schedularHeading}>
                                        <ArrowBackIosIcon
                                            className={classes.calendarBackBtn}
                                            data-test-id="calendarBackBtn"
                                            onClick={this.handelPreviousPage}
                                        />
                                        {this.showHeading(classes)}
                                    </Box>
                                </Box>
                                <Box>
                                    <Box className={classes.scheduleFieldContainer}>
                                        <Typography variant="h4" className={classes.scheduleDateLabel}>Set Date</Typography>
                                        <Box className={classes.customSCName}>
                                            <span className={classes.customDateInputName}>
                                                <DatePicker
                                                    monthsShown={2}
                                                    popperClassName={classes.popperScheduleClassName}
                                                    wrapperClassName={classes.datepickerScheduleClassFullWidth}
                                                    calendarClassName={classes.datepickerNurseClass}
                                                    formatWeekDay={nameOfDay => nameOfDay.slice(0, 1)}
                                                    onChange={this.handleDateChange}
                                                    className={classes.dateField}
                                                    dateFormat="Select date"
                                                    value={this.handleDateFormatChange(this.state.date)}
                                                    placeholderText="Select date"
                                                    minDate={new Date()}
                                                    startOpen={true}
                                                    shouldCloseOnSelect={false}
                                                    open={true}
                                                    data-test-id="select-schedule-date"
                                                    selected={this.state.date}
                                                />
                                            </span>
                                        </Box>
                                        <Box className={classes.selectBox}>Select Week</Box>
                                        <Box>{this.calendarPopUp(classes,this.state.dateError,"Error")}</Box>
                                    </Box>
                                    <Box className={classes.availabilityContainer}>
                                        <Box>
                                            {this.leaveHandel(classes)}
                                            <Box className={classes.slotContainer}>
                                                <Box style={{ display: "flex", gap: "4px" }}>
                                                    <Box
                                                        style={{display: "flex",
                                                            width: `${totalWidth}px`,
                                                            height: `${height}px`,
                                                            border: "1px solid #808080",
                                                            borderRadius: "4px"
                                                        }}
                                                    ></Box>
                                                    <Box className={classes.slotText}>- Full Free Slot</Box>
                                                </Box>
                                                <Box style={{ display: "flex", gap: "4px" }}>
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            width: `${totalWidth}px`,
                                                            height: `${height}px`,
                                                            border: "none",
                                                        }}
                                                    >
                                                        <Box
                                                            style={{
                                                                width: `${selectedWidth}px`,
                                                                height: "100%",
                                                                backgroundColor: "#3780E0",
                                                                borderTopLeftRadius: "4px",
                                                                borderBottomLeftRadius: "4px"
                                                            }}
                                                        />
                                                        <Box
                                                            style={{
                                                                width: `${unSelectedWidth}px`,
                                                                height: "100%",
                                                                backgroundColor: "#d3e3f8",
                                                                borderTopRightRadius: "4px",
                                                                borderBottomRightRadius: "4px"
                                                            }}
                                                        />
                                                    </Box>
                                                    <Box className={classes.slotText}>- Half Booked</Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box className={classes.availabilityAllSlotContainer}>
                                            <>
                                                {this.state.firstSlotTime.length > 0 &&
                                                    (
                                                        <>
                                                            {this.TimeSlot("Morning", `${this.handelRemoveSpace(this.state.firstStartDate)} - ${this.handelRemoveSpace(this.state.firstEndDate)}`, this.state.firstSlotTime, classes, 0)}
                                                        </>
                                                    )}
                                                {this.state.secondSlotTime.length > 0 &&
                                                    (
                                                        <>
                                                            {this.TimeSlot("Evening", `${this.handelRemoveSpace(this.state.secondStartDate)} - ${this.handelRemoveSpace(this.state.secondEndDate)}`, this.state.secondSlotTime, classes, 1)}
                                                        </>
                                                    )}
                                            </>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Popover
                                className={classes.hpNewPaperPropsClass}
                                open={this.state.showSlot}
                                anchorOrigin={{ vertical: 'center',horizontal: 'center',}}
                                transformOrigin={{ vertical: 'center',horizontal: 'center'}}
                                data-test-id="showSlotCancelPopup"
                                onClose={this.handleClose}
                            >
                                <Box>
                                    
                                    {this.state.showSlotCancelPopup ? this.getCancelConfirmationContent(classes) : this.getSlotListContent(classes)}
                                </Box>
                            </Popover>
                            {this.showOccurance(classes)}
                            <Popover
                                className={classes.hpNewPaperPropsClass}
                                open={this.state.isLeaveConfirmation}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                date-test-id="isLeaveConfirmation"
                                onClose={this.handelLeaveConfirmation}
                                transformOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                            >
                                <Box className={classes.leavePopover}>
                                    {!this.state.showSuccessMsg ?
                                        (<Box>
                                            <Typography variant="h6" className={classes.leaveText}>
                                                Taking a leave will cancel all your
                                            </Typography>
                                            <Typography variant="h6" className={classes.leaveText}>
                                                appointments (if any) for the day.
                                            </Typography>
                                            <Typography variant="h6" className={classes.leaveConfirmation}>
                                                Are you sure?
                                            </Typography>
                                            <Box className={classes.confirmCancellation}>
                                                <Button
                                                    variant="contained"
                                                    className={classes.cancelSlotBtn}
                                                    onClick={this.handelLeaveConfirmation}
                                                    data-test-id="cancelLeave"
                                                >
                                                    No
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    className={classes.cancelSlotConfirmationBtn}
                                                    onClick={this.handelConfirmation}
                                                    data-test-id="applyLeaveBtn"
                                                >
                                                    Yes
                                                </Button>
                                            </Box>

                                        </Box>) : (
                                        <Box>
                                            <img src={successIcon} width={140} height={140} className={classes.successIcon} />
                                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                                All your appointments for 
                                            </Typography>
                                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                                {this.formatDateToDDMMYYYY(new Date(this.state.date),"/")} have been
                                            </Typography>
                                            <Typography variant="h6" className={classes.hpPopoverTextSemiBold}>
                                                cancelled.
                                            </Typography>
                                            <Button
                                                variant="contained"
                                                className={classes.doneBtn}
                                                data-test-id="confirmLeave"
                                                onClick={this.closeLeaveConfirmation}
                                            >
                                                Done
                                            </Button>
                                        </Box>)}
                                </Box>
                            </Popover>
                        </Grid>
                    </Grid>
                </ThemeProvider>
                <Box>{this.calendarPopUp(classes,this.state.errorMessage,"Error")}</Box>
                <Box>{this.calendarPopUp(classes,this.state.successMessage,"Success")}</Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    schedularContainer: {
        display: "flex",
    },
    schedularAvailbleContainer: {
        margin: "40px 50px",
        width: "70%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "20px",
    },
    schedularHeaderContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    schedularHeading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "25px",
    },
    calendarBackBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        padding: "13px 9px 13px 17px",
    },
    calendarTitle: {
        fontFamily: "SF Pro Text Bold",
        fontSize: "28px",
        lineHeight: "33.41px",
    },
    scheduleFieldContainer: {
        width: "94%",
        margin: "15px 0px 20px"
    },
    scheduleDateLabel: {
        fontSize: "18px",
        fontFamily: "SF Pro Text Regular",
        margin: "0px 0px 15px",
        textAlign: "left" as "left",
        caretColor: "transparent",
        color: "#292929"
    },
    customSCName: {
        display: "flex",
        alignItems: "center",
    },
    customDateInputName: {
        width: "100%",
        borderRadius: "8px",
        "& input": {
            color: "#292929",
            fontSize: "18px",
            background: `url(${Calender}) left / contain no-repeat`,
            backgroundPosition: "12px",
            backgroundSize: "25px",
            width: "382px",
            height: "58px",
            border: "1px solid #808080",
            display: "flex",
            borderRadius: "8px",
            fontFamily: "SF Pro Text Regular",
            caretColor: "transparent"
        },
        "& ::-webkit-input-placeholder": {
            color: "#808080",
        },
        "&:focus": {
            border: "1px solid #292929",
        },
    },
    datepickerScheduleClassFullWidth: {
        width: "100%",
        color: "#292929",
        border: "none",
    },
    datepickerNurseClass: {
        "& .react-datepicker__day-name": {
            padding: "11px",
            fontSize: "16px",
            color: "#808080",
            fontFamily: "SF Pro Text Medium",
            width: "25px"
        },
        "& .react-datepicker__day--selected":{
            border: "none !important",
            color: "#ffffff !important",
            backgroundColor: "#3780E0",
            width: "25px",
        },
        "& .react-datepicker__day--outside-month":{
            backgroundColor: "transparent !important",
        },
        "& .react-datepicker__day": {
            padding: "11px !important",
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            color: "#292929",
            width: "25px",
        },
        "& .react-datepicker__day--disabled": {
            color: "#808080 !important",
            width: "25px",
        },
        "& .react-datetime-picker__wrapper": {
            border: "none"
        },
        "& .react-datepickerClass-wrapper": {
            display: "flex !important",
        },
        "& .react-datepickerClass__month": {
            margin: "0px !important"
        },
        "& .react-datepickerClass-popper": {
            transform: "translate(90px, 209px) !important"
        },
        "& .react-datepickerClass__day": {
            width: "1.3rem !important",
            height: "1rem !important"
        },
        "& .react-datepickerClass__day-name": {
            width: "1.3rem !important",
            height: "1rem !important"
        },
        "& .react-datepickerClass__month-container": {
            margin: "0px 0px 5px !important"
        },
        "& .react-datepickerClass__day-names": {
            marginBottom: "-1px !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__navigation": {
            height: "22px !important",
            width: "22px !important",
            margin: "5px -2px !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__day--keyboard-selected": {
            color: "#000 !important",
            backgroundColor: "white !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__day--today": {
            color: "#1d5d90 !important",
            backgroundColor: "white !important",
            fontWeight: "bold !important",
            caretColor: "transparent"
        },
        "& .react-datepickerClass__day:hover": {
            color: "#ccc !important",
            backgroundColor: "white !important",
            caretColor: "transparent"
        },
        "& .react-datepicker__triangle": {
            display: "none !important"
        },
        "& .react-datepicker__navigation--next": {
            right: "30px",
            backgroundColor: "#292929",
            width: "28px",
            height: "28px",
            borderRadius: "6px",
            top: "30px"
        },
        "& .react-datepicker__navigation--previous": {
            left: "30px",
            backgroundColor: "#292929",
            width: "28px",
            height: "28px",
            borderRadius: "6px",
            top: "30px"
        },
        "& .react-datepicker__navigation-icon::before": {
            borderColor: "#fff !important",
            borderRadius: "2px !important",
            top: "7px !important",
            borderWidth: "2px 2px 0 0"
        },
        "& .react-datepicker__navigation-icon::after": {
            borderColor: "#fff !important",
            borderRadius: "2px !important",
            top: "7px !important",
            borderWidth: "2px 2px 0 0"
        },
        "& .react-datepicker__month-container": {
            padding: "10px"
        },
        "& .react-datepicker__header": {
            backgroundColor: "#fff !important",
            border: "none !important"
        },
        "& .react-datepicker__current-month": {
            margin: "15px auto",
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            color: "#292929"
        },
        "& .react-datepicker__day--keyboard-selected": {
            border: "none !important",
            color: "#ffffff !important",
            backgroundColor: "#3780E0",
            width: "25px",
        },
        "& .react-datepicker__day:hover": {
            width: "25px",
        },
        "& .react-datepicker__day.react-datepicker__day--030.react-datepicker__day--selected.react-datepicker__day--outside-month" :{
            backgroundColor:"transparent !important",
            "&:focus": {
                backgroundColor:"transparent !important",
            },
        }
    },
    dateField: {
        "& .react-datepicker-ignore-onclickoutside": {
            border: "none !important",
            borderColor: "transparent !important"
        },
        display: "flex",
        padding: "15px 60px",
        border: "1px solid #808080",
        justifyContent: "center",
        borderRadius: "8px",
        cursor: "pointer",
        "&:focus-visible": {
            outline: "none",
            border: "2px solid #292929",
        },
        "&:focus": {
            outline: "none",
            border: "2px solid #292929",
        },
        "&:hover": {
            outline: "none",
            border: "1px solid #292929",
        },
        ".react-datepicker__input-container input::placeholder": {
            opacity: 1,
            fontSize: "18px",
            color: "#808080",
        },
        color: "#292929",
        fontSize: "18px",
        "& input": {
            color: "#292929",
        }
    },
    popperScheduleClassName: {
        "& .react-datepicker": {
            fontFamily: "SF Pro Text Regular !important",
            fontSize: "18px !important",
            marginTop: "60px !important",
            borderBottom: "1px solid #aca8a840 !important",
            borderRight: "1px solid #aca8a840 !important",
            borderLeft: "1px solid #aca8a840 !important",
            borderTop: "1px solid transparent !important",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px"
        }
    },
    availabilityContainer: {
        marginTop: "458px",
        maxWidth:"885px"
    },
    availabilityAllSlotContainer: {
        maxHeight:"275px",
        overflowY: "scroll" as "scroll",
        scrollbarColor: "#d9d5d5 #fff",
        scrollbarWidth: "thin" as "thin",
        "& ::-webkit-scrollbar-button": {
        display: "none"
        }
    },
    availabilitySlotContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    slotContainer: {
        display: "flex",
        width: "53%",
        gap: "20px",
        margin: "35px 0px"
    },
    manageAvaibilityscheduleDateLabel: {
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        color: "#292929"
    },
    editBtn: {
        fontFamily: "SF Pro Text Medium",
        fontSize: "16px",
        display: "flex",
        justifyContent: "space-between",
        gap: "14px",
        color: "#3780E0",
        cursor:"pointer"
    },
    slotText: {
        fontSize: "13px",
        fontFamily: "SF Pro Text Medium",
        color: "#808080"
    },
    selected: {
        width: "160px",
        height: "50px",
        borderRadius: "8px",
        backgroundColor: "#3780E0",
        color: "#FFFFFF",
        fontFamily: "SF Pro Text Regular",
        border: "none",
        padding: "16px 12px",
        cursor: "pointer",
        fontSize: "16px",
        margin: "10px 0px 20px 10px",
        caretColor: "transparent",
        textTransform: "lowercase " as "lowercase",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#292929"
        }
    },
    unSelected: {
        width: "160px",
        height: "50px",
        borderRadius: "8px",
        backgroundColor: "#ffffff",
        color: "#292929",
        fontFamily: "SF Pro Text Regular",
        border: "1px solid #808080",
        padding: "16px 12px",
        cursor: "pointer",
        fontSize: "16px",
        margin: "10px 0px 20px 10px",
        caretColor: "transparent",
        textTransform: "lowercase " as "lowercase",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#292929"
        }
    },
    weekDays: {
        color: "#292929",
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
    },
    slotDuration: {
        color: "#808080",
        fontSize: "16px",
        fontFamily: "SF Pro Text Regular",
    },
    popover: {
        "& .MuiPopover-paper": {
            borderRadius: "12px",
            overflow: "unset",
            maxWidth: "500px",
            padding: "20px",
            top: "200px !important" 
        },
        "& > div:nth-child(1)": {
            backgroundColor: "#050505a3 !important",
            marginLeft: "8.30%"
        },
        "@media (max-height: 660px)": {
            "& .MuiPopover-paper": {
                top: "100px !important"
            },
        },
    },
    customRecurrence: {
        "& .MuiPopover-paper": {
            borderRadius: "12px",
            overflow: "unset",
            maxWidth: "500px",
            padding: "20px",
            top: "200px !important" 
        },
        "& > div:nth-child(1)": {
            backgroundColor: "#050505a3 !important",
            marginLeft: "8.30%"
        },
        "@media (max-height: 760px)": {
            "& .MuiPopover-paper": {
                top: "120px !important"
            },
        },
        "@media (max-height: 700px)": {
            "& .MuiPopover-paper": {
                top: "100px !important"
            },
        },
    },
    hpNewPaperPropsClass: {

        "& > div:nth-child(1)": {
            backgroundColor: "#050505a3 !important",
            marginLeft: "8.30%"
        },
        "& .MuiPopover-paper": {
            borderRadius: "12px",
            overflow: "unset",
            maxWidth: "500px",
            padding: "20px",
            top: "125px !important" 
        },
        "@media (max-height: 700px)": {
            "& .MuiPopover-paper": {
                top: "80 !important"
            },
        },
        "@media (max-height: 660px)": {
            "& .MuiPopover-paper": {
                top: "50 !important"
            },
        },
        "@media (max-height: 640px)": {
            "& .MuiPopover-paper": {
                top: "25px !important"
            },
        },
    },
    hpPopoverContainer: {
        width: "445px",
        margin: "auto 20px"
    },
    hpPopoverConfirmationContainer: {
        width: "325px",
        margin: "auto 20px",
        paddingTop: "40px",
        paddingBottom: "40px"
    },
    hpPopoverTextSemiBold: {
        fontSize: "20px",
        caretColor: "transparent",
        color: "#292929",
        margin: "auto",
        fontFamily: "SF Pro Text Semibold",
        textAlign: "center" as "center",
        lineHeight: "32px"
    },
    hpPopoverText: {
        fontSize: "20px",
        caretColor: "transparent",
        color: "#292929",
        margin: "auto",
        fontFamily: "SF Pro Text Medium",
        textAlign: "center" as "center",
        lineHeight: "32px"
    },
    hpClosePopup: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "181px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "10px auto",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    popoverHeading: {
        color: "#292929",
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
        textAlign: "center" as "center",
        marginBottom: "20px"
    },
    appointmentCard: {
        height: "67px",
        border: "1px solid #014866",
        borderRadius: "6px",
        marginBottom: "25px",
        padding: "10px 35px"
    },
    cancelAppointmentCard: {
        height: "67px",
        border: "1px solid #014866",
        borderRadius: "6px",
        marginBottom: "20px",
        padding: "10px 35px",
        backgroundColor: "#d3e3f8"
    },
    patientSlot: {
        color: "#014866",
        fontSize: "16px",
        fontFamily: "SF Pro Text Medium",
        lineHeight: "30px"
    },
    slotBooked: {
        color: "#014866",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
        lineHeight: "30px"
    },
    doneBtn: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "345px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "30px auto",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    continueBtn: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "275px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "10px auto",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    cancelSlotBtn: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "120px",
        height: "42px",
        backgroundColor: "#ffffff",
        color: "#014866",
        border: "1px solid #014866",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "10px auto",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#ffffff",
            color: "#014866",
        }
    },
    cancelSlotConfirmationBtn: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "120px",
        height: "42px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "10px auto",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    appointmentSelectedNewCard: {
        border: "1px solid #014866",
        borderRadius: "6px",
        margin: "auto auto 20px",
        width: "320px",
        height: "46px",
        textAlign: "center" as "center",
        alignContent: "center" as "center",
        backgroundColor: "#d3e3f8"
    },
    appointmentSelectedTransparentCard: {
        border: "1px solid #014866",
        borderRadius: "6px",
        margin: "auto auto 20px",
        width: "320px",
        height: "46px",
        textAlign: "center" as "center",
        alignContent: "center" as "center"
    },
    confirmButton: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "253px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "25px auto 20px",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    successIcon: {
        display: "flex",
        margin: "30px auto"
    },
    confirmCancellation: {
        display: "flex",
        justifyContent: "space-between",
        width: "300px",
        margin: "auto"
    },
    successPopover: {
        width: "374px",
        margin: "auto 20px"
    },
    leaveText: {
        color: "#292929",
        fontSize: "20px",
        fontFamily: "SF Pro Text Bold",
        textAlign: "center" as "center"
    },
    leaveConfirmation: {
        color: "#292929",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        textAlign: "center" as "center",
        margin: "10px auto"
    },
    leaveLabel: {
        color: "#292929",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        display: "flex",
        alignContent: "center" as "center",
        textAlign: "center" as "center",
        gap: "12px",
    },
    leavePopover: {
        width: "342px",
        margin: "10px 25px"
    },
    notePopover: {
        width: "360px",
        margin: "20px 15px"
    },
    recurrence: {
        width: "340px",
        margin: "20px 15px"
    },
    title:{
        fontFamily:"SF Pro Text Medium",
        fontSize:"20px",
        color:"#3780E0",
        marginBottom:"15px",
        alignContent: "center" as "center",
        textAlign: "center" as "center",
        cursor:"pointer"
    },
    titleInput:{
        fontFamily:"SF Pro Text Medium",
        fontSize:"20px",
        color:"#3780E0",
        marginBottom:"15px",
        alignContent: "center" as "center",
        textAlign: "left" as "left",
        border: 'none', 
        outline: 'none'
    },
    text: {
        color: "#292929",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        textAlign: "start" as "start",
        margin: "30px auto"
    },
    saveButton: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "163px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "25px auto 20px",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    discardButton: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        width: "163px",
        height: "56px",
        backgroundColor: "#fff",
        color: "#014866",
        border: "1px solid #014866",
        padding: "16px",
        cursor: "pointer",
        fontFamily: "SF Pro Text Bold",
        fontSize: "18px",
        margin: "25px auto 20px",
        textTransform: "capitalize" as "capitalize",
        "&:hover": {
            backgroundColor: "#fff",
            color: "#014866"
        }
    },
    checkbox: {
        color: "#fff !important",
        padding: "9px 20px 9px 0px"
    },
    selectedDay:{
        fontFamily: "SF Pro Text Medium",
        fontSize: "16px",
        color:"#292929"
    },
    unSelectedDay:{
        fontFamily: "SF Pro Text Regular",
        fontSize: "16px",
        color:"#292929"
    },
    customReferanceBackBtn: {
        color: "#292929",
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
    },
    repeatInputFields: {
        width: "100%",
        fontSize: "16px",
        marginBottom: "10px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          cursor: "pointer"
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          borderWidth: "1px",
          borderColor: "#3f51b5 !important",
        },
        fontFamily: "SF Pro Text Regular",
        "& .MuiInputBase-input": {
          cursor: "pointer",
        },
        "& input": {
          color: '#292929',
          fontSize: "16px",
          fontFamily:"SF Pro Text Regular",
          "&::placeholder": {
            opacity: 1,
            fontSize: "16px",
            fontFamily:"SF Pro Text Regular",
            color:"#808080"
          },
        },
      },
      customContainer: {
        display: "block",
        width: "500px",
        maxWidth: "550px",
        height: "auto",
        textAlign: "left" as "left",
        borderRadius: "5px",
      },
      scheduleCalendarHeading: {
        width: "100%",
        fontSize: "28px",
        fontWeight: 700,
        fontFamily: "SF Pro Text Regular",
        height: "8px",
        marginTop: "15px",
        color: "#292929",
        caretColor: "white"
      },
      scheduleCalendarParagraph: {
        width: "100%",
        color: "#292929",
        fontSize: "16px",
        height: "8px",
        caretColor: "white",
        fontFamily: "SF Pro Text Regular",
        margin: "30px 0px"
      },
      button: {
        border: "none",
        color: "white",
        padding: "5px",
        marginBottom: "15px",
        borderRadius: "3px",
        caretColor: "transparent",
        backgroundColor: "rgb(33, 150, 243)",
        "&.MuiButton-root:hover": {
          backgroundColor: "rgb(33, 150, 243)",
          color: "white"
        },
        cursor: "pointer",
      },
      customDateField: {
        "& .react-datepicker-ignore-onclickoutside": {
            border: "none !important",
            borderColor: "transparent !important"
        },
        display: "flex",
        padding: "15px 10px",
        border: "1px solid #808080",
        justifyContent: "center",
        borderRadius: "8px",
        cursor: "pointer",
        width:"124px",
        height:"44px",
        fontFamily:"SF Pro Text Regular",
        fontSize: "16px",
        "&:focus-visible": {
            outline: "none",
            border: "2px solid #292929",
        },
        "&:focus": {
            outline: "none",
            border: "2px solid #292929",
        },
        "&:hover": {
            outline: "none",
            border: "1px solid #292929",
        },
        ".react-datepicker__input-container input::placeholder": {
            opacity: 1,
            fontSize: "16px",
            color: "#808080",
        },
        color: "#292929",
        "& input": {
            color: "#292929",
        }
    },  
    endDatepickerClass: {
        "& .react-datepicker__day--selected":{
            border: "none !important",
            color: "#ffffff !important",
            backgroundColor: "#3780E0",
        },
        "& .react-datepicker__month": {
            margin: "0px !important"
          },
        "& .react-datepicker__triangle": {
            display: "none !important"
        }, 
        "& .react-datepicker__day": {
          width: "1.3rem !important",
          height: "1rem !important"
        },
        "& .react-datepicker__day-name": {
          width: "1.3rem !important",
          height: "1rem !important"
        }, 
         "& .react-datepickerClass-popper": {
            transform: "translate(118px, 376px) !important"
        },
        "& .react-datepicker__month-container": {
          margin: "0px 0px 5px !important"
        },
        "& .react-datepicker__day-names": {
          marginBottom: "-1px !important"
        },
        "& .react-datepicker__day--keyboard-selected": {
            color: "#000 !important",
            backgroundColor: "white !important",
        },
        "& .react-datepicker__navigation": {
          width: "22px !important",
          margin: "5px -2px !important",
          height: "22px !important",
        },
        "& .react-datepicker__day:hover": {
          color: "#ccc !important",
          backgroundColor: "white !important",
        },
        "& .react-datepicker__day--today": {
          color: "#1d5d90 !important",
          backgroundColor: "white !important",
          fontWeight: "bold !important"
        }
      },
      selectBox:{
            height: "55px",
            backgroundColor: "rgb(235, 249, 255)",
            fontSize: "18px",
            fontFamily: "Sf Pro Text Semibold",
            width: "120%",
            maxWidth:"774px",
            margin:"15px 0px 0px",
            alignItems: "center" as "center",
            display: "flex",
            paddingLeft: "26px",
            color: "#014866",
            borderTopLeft: "1px solid #aca8a840 !important",
            borderTopRight: "1px solid #aca8a840 !important",
            borderTopLeftRadius: "8px",
            borderTopRightRadius: "8px",
      },
      slotdate: {
        color: "#292929",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        textAlign: "start" as "start",
        margin: "0px auto 28px"
    },
    selectTimeContainer:{
        margin: "0px auto 25px",
        display: "flex",
        justifyContent: "space-between",
        width: "145px"
    }
}
export default withStyles(styles)(SchedulingCalendar);
// Customizable Area End
