import { Theme } from "@material-ui/core";
import { flexDirectionType, OverflowXProperty, Position, ScrollbarType } from "../types"
// Customizable Area Start
type textType = "-moz-initial" | "inherit" | "unset" | "right" | "initial" | "revert" | "revert-layer" | "left" | "center" | "end" | "start" | "justify";
// Customizable Area End

export const styles = (theme: Theme | any) => ({
  // Customizable Area Start
  ayahDashboardMainContainer:{
    width: "90%",
    minHeight:"100vh",
    height:"auto",
    margin: "auto auto 60px",
    display:"flex",
    flexDirection:"column" as flexDirectionType,
  },
  topTotalMainContainer:{
    width: "100%",
    minHeight:"25vh",
    display: "flex",
    alignItems: "end",
    position:"relative" as Position,
    paddingBottom:"15px",
    "@media (max-height:900px)": {
      minHeight: "30vh",
    },
    "@media (max-height:800px)": {
      minHeight: "34vh",
    },
  },
  totalBookingContainer:{
    width: "256px",
    border: "2px solid #292929",
    height: "146px",
    display: "flex",
    alignItems: "center",
    marginRight: "30px",
    borderRadius: "10px",
    cursor:"pointer",
    "@media (max-width:1300px)": {
      width: "240px",
      height: "135px",    
    },
    "@media (max-width:1200px)": {
      width: "220px",
      height: "130px",    
    },
    "@media (max-width:1100px)": {
      width: "220px",
      height: "130px",    
    },
    "@media (max-width:900px)": {
      width: "220px",
      height: "130px",    
    },

  },

  sosImageContainer:{
    position: "absolute" as Position,
    top: "7%",
    right: "0px",
    "@media (max-width:900px)": {
      top:"23%",
    },
    "@media (max-width:1300px)": {
      top:"17%",
    },
  },
  totalBookingContent:{
    marginLeft:"20px"
  },
  titleNumbersBooking:{
    fontFamily: "SF Pro Text Bold",
    fontSize: "50px",
    color: "#014866",
    display:"flex",
    "@media (max-width:900px)": {
      fontSize: "36px",
    },
    "@media (max-width:1200px)": {
      fontSize: "40px",
    },
  },
  titleTotalBooking:{
    fontFamily: "SF Pro Text Bold",
    fontSize: "20px",
    color: "#292929",
    "@media (max-width:900px)": {
      fontSize: "18px",
    },
  },
  newBookingMainContainer:{
    minHeight:"25vh",
  },
  newBookingHeadingContainer:{
    display:"flex",
    justifyContent: "space-between",
    paddingTop:"20px",
    alignItems:"center",
  },
  titleBookingType:{
    fontSize:"22px",
    fontFamily:"SF Pro Text Bold",
    color:"#292929",
  },
  titleSeeAll:{
    fontSize:"18px",
    fontFamily:"SF Pro Text Medium",
    color:"#3780E0",
    cursor: "pointer",
  },
  bookingContainer:{
    width:"95%",
    display:"flex",
    marginTop:"20px",
    justifyContent: "space-between",
    gap: "40px",
  },
  ayahInfoContainer:{
    width: "70%",
    minHeight: "80px",
    display: "flex",
    padding:"15px",
    background: "#FFFFFF",
    border: "1px solid rgba(179, 179, 179, 0.18)",
    boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
    borderRadius: "8px",
    maxWidth:"488px"
  },
  ayahImageContainer:{
    width:"85px",
    height:"85px",
  },
  ayahProfile:{
    width:"85px",
    borderRadius:"50%",
    height:"85px",
  },

  ayahInfoSection:{
    display: "flex",
    flexDirection: "column" as flexDirectionType,
    width:"100%",
    justifyContent:"center",
    marginLeft: "20px"
  },
  ayahDetailsContainer:{
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems:"center",
  },
  nameLabel:{
    fontSize:"20px",
    fontFamily:"SF Pro Text Bold",
    color:"#292929",
    overflow: "hidden" as "hidden",
    whiteSpace: "nowrap" as "nowrap",
    textOverflow: "ellipsis" as "ellipisis",
    width:"112px",
    "@media (max-width:1000px)": {
      fontSize:"18px",    
    },
  },
  HealthDetailsLabel:{
    fontSize:"16px",
    fontFamily:"SF Pro Text Regular",
    color:"#3780E0",
    cursor: "pointer",
    margin:"8px 0px 5px"
  },
  ayahTimingLabel:{
    fontSize:"16px",
    fontFamily:"SF Pro Text Medium",
    color:"#808080",
    textAlign:"right" as "right"
  },
  ayahWeekdayLabel:{
    fontSize:"16px",
    fontFamily:"SF Pro Text Regular",
    color:"#808080",
    textAlign:"right" as "right",
    margin:"8px 0px 5px"
  },
  acceptedBookingLabel:{
    fontSize:"16px",
    fontFamily:"SF Pro Text Regular",
    color:"#165AAF",
  },
  ayahShiftContainer:{
    display: "flex",
    justifyContent: "space-between",
    padding: "7px 0px 15px 0px",
  },
  ayahShiftAction:{
    display:"flex",
    alignItems:"center",
  },
  shiftText:{
    fontSize:"14px",
    fontFamily:"SF Pro Text Regular",
    color:"#292929",
    lineHeight:"unset",
    marginRight:"10px",
  },
  ayahContactBtnContainer:{
    display:"flex",
    alignItem:"center",
    justifyContent:"center",
    width:"100%",
  },
  contactBtn:{
    fontSize:"18px",
    fontFamily:"SF Pro Text Bold",
    color:"#014866",
    border:"1px solid #014866",
    borderRadius:"8px",
    background: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "@media (max-width:1200px)": {
      fontSize: "15px",
    },
    "@media (max-width:1050px)": {
      fontSize: "12px",
    },
  },

  ayahActionButtonsContainer:{
    display:"flex",
    width:"30%",
    minWidth:"222px",
  },
  ayahtBtnContainer:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    width:"100%"
  },
  declineBtn:{
    fontSize:"18px",
    fontFamily:"SF Pro Text Bold",
    color:"#014866",
    border:"1px solid #014866",
    borderRadius:"8px",
    background: "#ffffff",
    marginRight:"20px",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
    "@media (max-width:1200px)": {
      fontSize: "15px",
    },
  },
  acceptBtn:{
    fontSize:"18px",
    fontFamily:"SF Pro Text Bold",
    color:"#ffffff",
    border:"1px solid #014866",
    borderRadius:"8px",
    background: "#014866",
    "&:hover": {
      backgroundColor: "#014866",
    },
    "@media (max-width:1200px)": {
      fontSize: "15px",
    },
  },

  acceptedBtn:{
    borderRadius:"8px",
    background: "#30D158",
    width:"230px",
    fontSize: "18px",
    "&:hover": {
      backgroundColor: "#30D158",
    },
    "@media (max-width:1200px)": {
      fontSize: "15px",
    },
  },

  acceptedBtnText:{
    fontSize:"18px",
    fontFamily:"SF Pro Text Bold",
    color:"#ffffff",
    paddingLeft:"15px",
    "@media (max-width:1200px)": {
      fontSize: "15px",
    },
  },

  completedBtn:{
    border:"none",
    background: "#ffffff",
    width:"230px",
    boxShadow:"none",
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow:"none",
    },
  },

  completedBtnText:{
    fontSize:"18px",
    fontFamily:"SF Pro Text Bold",
    color:"#30D158",
    paddingLeft:"15px",
    "@media (max-width:1200px)": {
      fontSize: "15px",
    },
  },

  icons:{},

  upcomingBookingMainContainer:{
    width: "100%",
    minHeight:"25vh",
  },
  dialogBoxWeb: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "420px !important",
      width: "100%",
      borderRadius: "20px !important",
    },
    "& .MuiDialog-paperScrollPaper": {
      height: "263px"
    },
    " & .MuiPaper-rounded": {
      borderRadius: "20px !important",
      padding: "15px"
    },
    " & .MuiDialog-paper": {
      borderRadius: "20px !important",
    }
  },
  dialogBoxContentMainContainer:{
    display:"flex",
    flexDirection:"column"  as flexDirectionType,
    justifyContent:"center"
  },
  dialogBoxContentContainer:{
    display:"flex",
    flexDirection:"column" as flexDirectionType,
    alignItems:"center"
  },
  dialogTitleConatiner:{
    padding:"15px 15px 0px 15px",
    textAlign:"end" as textType
  },
  dialogContentText:{
    fontFamily:"SF Pro Text Bold",
    fontSize:"20px",
    color:"#292929",
    lineHeight:"22px"
  },
  dialogConfirmText:{
    fontFamily:"SF Pro Text Medium",
    fontSize:"18px",
    color:"#292929",
    padding: "20px 0px"
  },
  capturedBtnContainer:{
    display:"flex",
    justifyContent: "center",
    gap:"20px"
  },
  dialogNoBtn:{
    fontSize:"18px",
    fontFamily:"SF Pro Text Bold",
    color:"#014866",
    border:"1px solid #014866",
    borderRadius:"8px",
    background: "#ffffff",
    width:"100px",
    height:"42px",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  dialogYesBtn:{
    fontSize:"18px",
    fontFamily:"SF Pro Text Bold",
    color:"#ffffff",
    border:"1px solid #014866",
    borderRadius:"8px",
    background: "#014866",
    width:"100px",
    height:"42px",
    "&:hover": {
      backgroundColor: "#014866",
    },
  },
  earningDashboardMainContainer:{
    padding: '50px',
    height: '100%',
    display:"flex",
    flexDirection:"column" as flexDirectionType,
    gap:"40px",
  },
  backBtnContainer:{
    display: 'flex',
    gap: '25px',
  },
  backBtn:{
    cursor:"pointer",
  },
  backIcon:{},
  dropDown: {
    marginTop:"3px"
  },
  backBtnTitleContainer:{
    display: 'flex',
    alignItems: 'center',
  },
  pageTitle:{
    fontSize: '28px',
    fontFamily: 'SF Pro Text Bold',
    color: '#292929',
  },
  totalEarningsContainer:{
    display:"flex",
    gap:"30px",
  },
  earningSection:{
    width:"282px",
    height:"150px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: "#FFFFFF",
    border: "1px solid rgba(179, 179, 179, 0.18)",
    boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
    borderRadius: "8px",
  },
  earningSubSection:{
    display:"flex",
    gap:"15px"
  },
  earningTypeImageContainer:{},
  earningTypeImage:{},
  earningTypeSection:{
    display:"flex",
    flexDirection:"column" as flexDirectionType,
  },
  earnings:{
    fontFamily:"SF Pro Text Bold",
    fontSize:"30px",
    color:"#014866",
  },
  type:{
    fontFamily:"SF Pro Text Medium",
    fontSize:"16px",
    color:"#292929",
  },


  monthEarningMainContainer:{
    width:"70%",
    display:"flex",
    flexDirection:"column" as flexDirectionType,
    gap:"25px",
  },
  monthEarningTitle:{
    fontFamily:"SF Pro Text Semibold",
    fontSize:"22px",
    color:"#292929",
  },
  graphContainer:{
    background: "#FFFFFF",
    border: "1px solid rgba(179, 179, 179, 0.18)",
    boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
    borderRadius: "8px",
    padding:'15px'
  },
  recentTransactionsMainContainer:{
    width:"100%",
    display:"flex",
    flexDirection:"column" as flexDirectionType,
    gap:"25px",
  },


  transactionContainer:{
    display: "flex",
    gap:"15px",
  },

  allTransactionContainer:{
    maxWidth:"410px",
    background: "#FFFFFF",
    border: "1px solid rgba(179, 179, 179, 0.18)",
    boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
    width:"70%",
    padding:"20px",
    borderRadius:"10px",
  },

  transitionInfoSection:{
    display: "flex",
    justifyContent:"space-between",
    width:"100%",
    alignItems:"center",
  },
  transactionTiming:{
    fontFamily:"SF Pro Text Regular",
    fontSize:"16px",
    color:"#808080",
  },
  transactionTotalBookingTiming:{
    fontFamily:"SF Pro Text Regular",
    fontSize:"14px",
    color:"#808080",
  },
  transactionAmt:{
    fontFamily:"SF Pro Text Semibold",
    fontSize:"24px",
    color:"#014866",
  },
  horizontalLine:{
    height:"1px",
    background:"#D8D8D8",
    margin:"10px 0px",
  },
  transactionFiltersContainer:{
    display:"flex",
    gap:"25px"
  },
  allFilterSection:{
    padding:"10px 20px",
    boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
    borderRadius:"8px",
  },
  allFilterlabel:{
    fontSize:"16px",
    fontFamily:"SF Pro Text Medium",
    color:"#014866",
  },
  filterLabel:{
    fontSize:"16px",
    fontFamily:"SF Pro Text Regular",
    color:"#292929",
    "@media (max-width:983px)": {
      fontSize:"14px",
    },
  },
  filterSection:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    padding:"10px 20px",
    boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
    borderRadius: "8px",
  },
  subFilterSection:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap:"20px",
  },
  changeMonthBtn:{
    alignContent:"center"
  },
  yearFilterContainer:{
    display:"flex",
    gap:"15px"
  },
  timeFilterMainContainer:{
    width:"340px"
  },
  timeFilterContainer:{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    gap:"30px",
    padding:"10px 30px",
    marginBottom:"10px",
    border: "1px solid #BABABA",
    boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
    borderRadius: "8px",
  },
  monthFilterContainer:{
    display:"flex",
    gap:"30px",
  },
  selectFilter:{
    border:"none",
    background:"#ffffff",
    fontSize:"18px",
    fontFamily:"SF Pro Text Medium",
    color:"#014866",
  },
  filterBottomText:{
    fontSize:"14px",
    fontFamily:"SF Pro Text Regular",
    color:"#808080"
  },
  dayTransactionsListContainer:{
    display:"flex",
    flexDirection:"column" as flexDirectionType,
    gap:"20px",
  },
  dayTransactionsListTitle:{
    fontSize:"18px",
    fontFamily:"SF Pro Text Semibold",
    color:"#292929"
  },

  totalBookingListContainer:{
    display:"flex",
    flexDirection:"column" as flexDirectionType,
    gap:"20px",
    width:"65%",
    minWidth:"561px",
    "@media (max-width:1250px)": {
      width: "75%",
    },
    "@media (max-width:1110px)": {
      width: "85%",
    },
    "@media (max-width:1000px)": {
      width: "90%",
    },
    "@media (max-width:962px)": {
      width: "65%",
    },
  },

  bookingWrapper: {
    display: "flex",
    padding: "15px 0px 15px 15px",
    background: "#FFFFFF",
    border: "1px solid rgba(179, 179, 179, 0.18)",
    boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
    borderRadius: "8px",
    gap:"15px",

  },

  homeVisitLabel:{
    fontSize:"16px",
    fontFamily:"SF Pro Text Medium",
    color:"#3780E0",
    background:"#3780E04D",
    borderRadius:"5px 0px 0px 5px",
    padding:"5px 10px"
  },
  bookingsInfoSection:{
    display: "flex",
    justifyContent:"space-between",
    width:"100%",
    alignItems:"flex-start"
  },

  dialogWrapper:{
    padding:"15px",
    scrollbarWidth: "thin" as ScrollbarType,
    "&::-webkit-scrollbar": {
      display: "none" 
    },
    overflowY: "scroll" as OverflowXProperty,
    scrollbarColor:" white white",
  },
  healthDetailsDialogTitleConatiner:{
    display:"flex",
    justifyContent:"space-between",
    padding:"15px 30px",
  },
  dialogTitle:{
    fontSize:"28px",
    fontFamily:"SF Pro Text Bold",
    color:"#292929"
  },
  questionField:{
    fontSize:"16px",
    fontFamily:"SF Pro Text Medium",
    color:"#808080",
    padding:"5px",
  },
  valueField:{
    fontSize:"18px",
    fontFamily:"SF Pro Text Medium",
    color:"#292929",
    padding:"5px",
  },
  upcommingBookingListContainer: {
    display:"flex",
    flexDirection:"column" as flexDirectionType,
    gap:"15px",
    width:"100%",
  },
  upcomingBookingContainer: {
    overflow:"scroll" as OverflowXProperty,
    scrollbarWidth: "thin" as ScrollbarType,
    scrollbarColor:"#fff #fff",
    '& ::-webkit-scrollbar-button': {
        display: "none"
    },
  },
  recentTransaction : {
    overflowY:"scroll" as OverflowXProperty,
    scrollbarWidth: "thin" as ScrollbarType,
    scrollbarColor:"#fff #fff", 
    '& ::-webkit-scrollbar-button': {
        display: "none"
    },
  },
  reservation: {
    scrollbarWidth: "thin" as ScrollbarType,
    scrollbarColor:"#fff #fff", 
    overflowY:"scroll" as OverflowXProperty,
    '& ::-webkit-scrollbar-button': {
        display: "none"
    }, 
  },
  HealthDetailsLabels:{
    fontSize:"16px",
    fontFamily:"SF Pro Text Regular",
    color:"#3780E0",
    cursor: "pointer",
    margin:"8px 0px 5px"
  },
  bookingTiming:{
    fontFamily:"SF Pro Text Regular",
    fontSize:"14px",
    color:"#808080",
  },
  hidescroll:{
    scrollbarColor:"#fff #fff", 
    overflow:"hidden !important"  as OverflowXProperty,
    '& ::-webkit-scrollbar-button': {
        display: "none"
    }, 
  },
  cardContainer: {
    paddingRight:"18px",
    maxHeight: "74vh",
    overflowY:"scroll" as OverflowXProperty,
    scrollbarColor:"#d9d5d5 #fff",
    '& ::-webkit-scrollbar-button': {
        display: "none"
    },
    scrollbarWidth: "thin" as ScrollbarType,
  },
  noDataDashboardContainer:{
    width:"362px",
    display:"block",
    margin:"50px auto"
  },
  closeIcon:{
    display:"flex",
    margin:"auto"
  },
  noData:{
    fontSize:"20px",
    fontFamily:"SF Pro Text Medium",
    color:"#808080", 
    width:"100%",
    display:"flex",
    justifyContent:"center",
    margin:"60px auto"
  },
  DialogContentSpace:{
    marginBottom:"15px"
  },
  yearBox: {
    width: "92px",
    height: "315px",
    overflowY:"scroll" as OverflowXProperty,
    scrollbarColor:"#fff #fff",
    '& ::-webkit-scrollbar-button': {
        display: "none"
    },
    scrollbarWidth: "thin" as ScrollbarType,
    border: "1px solid #BABABA",
    boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
    borderRadius: "8px",
    padding: "5px",
    position: "absolute" as 'absolute',
    zIndex: 1000,
    background: "#ffffff",
    marginTop: "45px"
  },
  BookingHealthDetailsLabel:{
    fontSize:"16px",
    fontFamily:"SF Pro Text Regular",
    color:"#3780E0",
    cursor: "pointer",
    margin:"0px 0px 4px"
  },
  // Customizable Area End
});