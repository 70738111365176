import React from 'react';
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import { Box, Checkbox, FormControlLabel, styled, Typography } from '@material-ui/core';
import Sidebar from '../../../components/src/Sidebar.web';
import HospitalProfileWrapper from './HospitalProfileWrapper.web';
import CustomStyledButton from '../../../components/src/CustomStyledButton.web';
import { checked, unCheckedPolicyBox } from './assets';

class HomeCurePrivacyPolicy extends CustomisableUserProfilesController {
    render() {
        return (
            <Box sx={webStyle.container}>
                <Box sx={webStyle.sidebarWrapper}>
                    <Sidebar />
                </Box>
                <Box sx={webStyle.rightWrapper}>
                    <HospitalProfileWrapper
                        data-test-id="profile-wrapper"
                        {...this.props}
                        profileBackBtn
                        profilePageTitle={configJSON.PageTitle.PrivacyPolicy}
                        handleOpenHospitalEditProfileView={this.openHospitalEditProfiles}
                    >
                        <Box sx={webStyle.contentWrapper} data-test-id='privacy-policy'> 
                            <Typography style={webStyle.titleText}>WELCOME TO HOMECURE</Typography>
                            <Typography style={webStyle.subtitleText}>The Best Medical Service of Lucknow City.</Typography>
                            <Typography style={webStyle.featureText}>Check Our All Feature</Typography>

                            <Typography style={webStyle.descriptionText}>
                                Welcome to our new website. We encourage you to find out more about the values and vision that guides us, the services and programs we offer, the indicators that ensure our continued accountability and transparency, and the news and events that matter most to you.
                            </Typography>
                            <br />
                            <Typography style={webStyle.additionalText}>
                                It is the place where you will find information on career opportunities with HMH and be able to access general healthcare information to keep you well informed.
                            </Typography>
                            <br />
                            <Typography style={webStyle.additionalText}>
                                India Hospital has a proud tradition of care. For the last so many years, India Hospital has cared for the people of Lucknow, surrounding communities, and beyond, being a significant player within the District. Residing in the heart of a beautiful cottage country on the shores of the Gomti River, we offer healthcare not only to our community residents but to the many visitors who frequent our beautiful area year-round.
                            </Typography>
                            <Box sx={webStyle.actionButtonWrapper}>
                                <StyledFormControlLabel
                                    label="I agree with the terms and conditions"
                                    control={<Checkbox disableRipple data-test-id='row-checkbox' checkedIcon={<img src={checked} alt="checked_icon" />} icon={<img src={unCheckedPolicyBox} alt="unchecked_icon" />} />}
                                    labelPlacement="end"
                                />
                                <CustomStyledButton style={{ width:'250px' }} label={configJSON.ButtonLabel.Done} />
                            </Box>
                        </Box>
                    </HospitalProfileWrapper>
                </Box>
            </Box>
        );
    }
}

export default HomeCurePrivacyPolicy;

const StyledFormControlLabel = styled(FormControlLabel)({
    '&.MuiFormControlLabel-root': {
        alignItems: 'center',
        gap: '22px',
        marginLeft: '0'
    },
    '& .MuiTypography-root': {
        color: '#292929',
        fontSize: '16px',
        fontFamily: 'SF Pro Text Medium',
        lineHeight: '18px'
    }
})

const webStyle = {
    actionButtonWrapper: {
        display: 'flex',
        flexDirection: "column",
        gridRowGap: "24px",
        marginTop: '67px'
    },
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 140px 72px 60px',
    },
    titleText: {
        color: '#292929',
        fontSize: '24px',
        fontFamily: 'SF Pro Text Medium',
        textTransform: 'uppercase',
        marginBottom: '14px',
    } as React.CSSProperties,
    subtitleText: {
        color: '#808080',
        fontSize: '20px',
        fontFamily: 'SF Pro Text Regular',
        marginBottom: '10px',
    },
    featureText: {
        color: '#014866',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Regular',
        marginBottom: '20px',
    },
    descriptionText: {
        color: '#555555',
        textAlign: 'justify',
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        marginBottom: '10px',
    } as React.CSSProperties,
    additionalText: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        color: '#555555',
        textAlign: 'justify',
    } as React.CSSProperties,
};
