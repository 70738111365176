import React from 'react';
import { IconButton, Typography, Box, InputAdornment, InputBaseComponentProps, InputLabel, TextField, styled } from '@material-ui/core';

interface Props {
    onChange?: (text: string) => void;
    value?: unknown;
    type?: "number" | "text" | "email" | "password" | "date";
    placeholder?: string;
    disabled?: boolean;
    helperText?: string;
    error?: boolean;
    label?: string;
    inputProps?: InputBaseComponentProps;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
    multiline?: boolean;
    minRows?: string | number;
    style?: React.CSSProperties;
    fullWidth?: boolean;
    asterisk?: boolean;
    labelStyle?: GenericStyle;
    readOnly?: boolean;
    subLabel?: string;
    iButtonClick?: (event: React.MouseEvent<HTMLElement>) => void;
    ref?: ((instance: unknown) => void) | React.RefObject<unknown> | null | undefined
}

interface GenericStyle {
    [key: string]: string;
}

const StyledInputField = styled(TextField)({
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #014866",
        borderRadius: "8px",
        fontFamily: "SF Pro Text Regular",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        borderRadius: "8px",
        paddingLeft: "0px",
        fontFamily: "SF Pro Text Regular",
    },
    "& .MuiFormHelperText-root": {
        marginLeft: 0,
        fontFamily: "SF Pro Text Regular",
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '0'
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
        paddingLeft: '10px'
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#808080", 
        fontSize: "14px", 
        opacity: 1,
        fontFamily:'SF Pro Text Regular'
      },
});


const CustomInputField = (props: Props) => {
    const {
        onChange,
        ref,
        value,
        type,
        placeholder,
        disabled,
        error,
        helperText,
        multiline,
        minRows,
        label,
        subLabel,
        inputProps,
        endAdornment,
        startAdornment,
        style,
        fullWidth = false,
        asterisk = false,
        labelStyle,
        readOnly = false,
        ...rest
    } = props;
    return <Box sx={webStyle.inputWrapper} >
        {label && <Box style={webStyle.labelWrapper}>
            <InputLabel style={{ ...webStyle.labelStyle, ...labelStyle }} >
                {label} {asterisk && <span style={{ color: 'red' }} >*</span>}
            </InputLabel>
        </Box>}
        {subLabel ? <StyledSubLabel>{subLabel}</StyledSubLabel> : null}
        <StyledInputField
            variant='outlined'
            fullWidth={fullWidth}
            inputProps={inputProps}
            value={value}
            type={type}
            onChange={(event) => onChange && onChange(event.target.value)}
            placeholder={placeholder}
            disabled={disabled}
            helperText={helperText}
            error={error}
            multiline={multiline}
            minRows={minRows}
            style={style}
            {...rest}
            InputProps={{
                readOnly: readOnly,
                startAdornment:
                    <InputAdornment position='start'>
                        {startAdornment}
                    </InputAdornment>,
                endAdornment:
                    <InputAdornment position="end">
                        {endAdornment}
                    </InputAdornment>
            }}
        />
    </Box>
}

export default CustomInputField;

const StyledSubLabel = styled(Typography)({
    '&.MuiTypography-root': {
        color: '#014866',
        fontSize: '12px',
        lineHeight: '22px',
        width: '100%',
        fontFamily: "SF Pro Text Regular",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        overflow: 'hidden',
        WebkitLineClamp: 1,
        wordBreak: 'break-all',
    },
    '&:hover': {
        display: "block",
        height: "auto",
        WebkitLineClamp: "unset",
        overflow: "visible",
        whiteSpace: "normal",
    }
})

const webStyle = {
    labelStyle: {
        color: '#292929',
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: "SF Pro Text Regular",
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    labelWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
}