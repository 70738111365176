import React from "react";
// Customizable Area Start
import Reservation2Controller, { Props } from "./Reservations2Controller";
import {
  withStyles,
  Typography,
  Grid,
  Box,
  ThemeProvider,
  createTheme,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { FlexDirectionProperty, OverflowXProperty, PositionValue, textTransform } from "./types";
import Sidebar from "../../../components/src/Sidebar.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import { Calender, UserProfile } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import {formatUTC } from "../../../components/src/utils";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  }
});
// Customizable Area End
export class Reservations2ViewDoctorBooking extends Reservation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    try {
      await Promise.all([this.getAvaibilityList(), this.getPatientProfileApi()]);
    } catch (errors) {
      this.showAlert("Error fetching data:", "Something went wrong");
    }
  }

  renderSlotStyleObj = (slot: { start: string, end: string, halfBooked?: boolean }) => {
    let style = {};
  
    if (slot.start === this.state.selectedSlot.start || slot.end === this.state.selectedSlot.end) {
      style = { ...webStyles.selected, background: 'rgb(55, 128, 224)' };
    } else if (slot.halfBooked) {
      style = { ...webStyles.selected, background: 'linear-gradient(to right, rgb(55, 128, 224) 50%, rgb(211, 227, 248) 50%)' };
    } else {
      style = webStyles.unSelected;
    }
    return style;
  };

  TimeSlot = (
    title: "Morning" | "Evening",
    duration: string,
    slots: {start:string,end:string, halfBooked?:boolean}[],
    index: number
  ) => {
    let id = ""
    if(title === "Morning"){
        id="40"
    }
    else {
        id="41"
    }

    return (
      <Box style={webStyles.availabilitySlotContainer}>
        <Box style={{ width: "25%", maxWidth: "150px", marginTop: "12px" }}>
          <Typography variant="h4" style={webStyles.weekDays}>
            {title}
          </Typography>
          <Typography variant="h4" style={webStyles.slotDuration}>
            {duration}
          </Typography>
        </Box>
        <Box
          key={index}
          style={{
            width: "75%",
            display: "flex",
            flexWrap: "wrap",
            gap: "2px",
            justifyContent: "flex-start",
          }}
        >
          {slots.map((slot)=> {
            return(
              <Button
                style={this.renderSlotStyleObj(slot)}
                onClick={() => this.handleSelectSlot(slot, id, title)}
              >{formatUTC(slot.start,"h:mm")} - {formatUTC(slot.end,"h:mm A")}
              </Button>
            )
          })}
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const totalWidth = 60;
    const height = 14;
    const selectedWidth = (50 / 100) * totalWidth;
    const unSelectedWidth = totalWidth - selectedWidth;
    // Customizable Area End

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Grid container style={webStyles.bookDoctorProfileContainer}>
          <Grid item xs={1}>
            <Sidebar />
          </Grid>
          <style>
            {`
              .react-datepicker-wrapper {
                width: 100%;
              }
            `}
          </style>
          <Grid item xs={11}>
            <>
              <Box style={webStyles.bookDoctorContainer}>
                <Box style={webStyles.bookDoctorHeaderContainer}>
                  <Box style={webStyles.bookDoctorHeading}>
                    <ArrowBackIosIcon
                      style={webStyles.bookDoctorBackButton}
                      data-test-id="bookDoctorBackBtn"
                      onClick={this.navigateToDoctorAvaibility}
                    />
                    <Typography variant="h6" style={webStyles.bookDoctorTitle}>
                      Book Now
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Grid container style={{ margin: "15px 50px" }}>
                <Grid item xl={4} md={4} sm={4} xs={12}>
                  <Box className={classes.boxContainer}>
                    <Typography variant="h4" className={classes.registerHospitalBookingLabel}>
                      Patient Name<span className={classes.requiredField}>*</span>
                    </Typography>
                    <TextField
                      variant="outlined"
                      data-test-id="patientName"
                      className={classes.doctorBookingRegisterInputFields}
                      placeholder="Enter your name"
                      value={this.state.patientName}
                      onChange={(event) => this.handlePatientName(event)}
                      />
                  </Box>
                </Grid>
                <Grid item xl={4} md={4} sm={4} xs={12}>
                  <Box className={classes.boxContainer}>
                    <Typography variant="h6" className={classes.registerDateLabel}>
                      Set Date<span className={classes.requiredField}>*</span>
                    </Typography>
                    <Box className={classes.customDatePickerName} style={{ marginBottom: (this.state.errors?.date) ? "13px" : "20px" }}>
                      <span className={this.state.errors?.date ? classes.customDateInputNameError : classes.customDateInputName}>
                        <DatePicker
                          data-test-id="bookingdate"
                          popperPlacement="bottom-start"
                          popperModifiers={[
                            {
                              name: "offset",
                              options: {
                                offset: [60, 0],
                              },
                            },
                            {
                              name: "preventOverflow",
                              options: {
                                rootBoundary: "viewport",
                                tether: false,
                                altAxis: true,
                              },
                            },
                          ]}
                          wrapperClassName={classes.datepickerClassFullWidth}
                          calendarClassName={classes.datepickerClass}
                          onChange={this.handleBookingDateChange}
                          className={(this.state.errors?.date) ? classes.dateFieldError : classes.dateField}
                          dateFormat="Select date"
                          value={this.formatCustomDate(this.state.bookingDate)}
                          placeholderText="Select date"
                          minDate={new Date()}
                          selected={this.state.bookingDate}
                        />
                      </span>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid container style={{ margin: "0px 50px" }}>
                <Grid item xl={4} md={4} sm={4} xs={12}>
                  <Box style={{ fontFamily: "SF Pro Text Medium", fontSize: "24px", color: "#292929" }}>Available Time<span style={{ fontFamily: "SF Pro Text Medium", fontSize: "24px", color: "#FF0000" }}>*</span></Box>
                </Grid>
                <Grid item xl={4} md={4} sm={4} xs={12} style={{ alignContent: "center" }}>
                  <Box className={classes.slotContainer}>
                    <Box style={{ display: "flex", gap: "4px" }}>
                      <Box
                        style={{
                          display: "flex",
                          width: `${totalWidth}px`,
                          height: `${height}px`,
                          border: "1px solid #808080",
                          borderRadius: "4px"
                        }}
                      ></Box>
                      <Box className={classes.slotText}>- Full Free Slot</Box>
                    </Box>
                    <Box style={{ display: "flex", gap: "4px" }}>
                      <Box
                        style={{
                          display: "flex",
                          width: `${totalWidth}px`,
                          height: `${height}px`,
                          border: "none",
                        }}
                      >
                        <Box
                          style={{
                            width: `${selectedWidth}px`,
                            height: "100%",
                            backgroundColor: "#3780E0",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px"
                          }}
                        />
                        <Box
                          style={{
                            width: `${unSelectedWidth}px`,
                            height: "100%",
                            backgroundColor: "#d3e3f8",
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px"
                          }}
                        />
                      </Box>
                      <Box className={classes.slotText}>- Half Booked</Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Box style={webStyles.slotBookingContainer}>
                <Box className={classes.availabilityAllSlotContainer}>
                  <>
                    {this.state.firstSlotTime.length > 0 &&
                      (
                        <>
                          {this.TimeSlot("Morning", `${this.handelRemoveSpace(this.state.firstStartDate)} - ${this.handelRemoveSpace(this.state.secondStartDate)}`, this.state.firstSlotTime, 0)}
                        </>
                      )}
                    {this.state.secondSlotTime.length > 0 &&
                      (
                        <>
                          {this.TimeSlot("Evening", `${this.handelRemoveSpace(this.state.firstEndDate)} - ${this.handelRemoveSpace(this.state.secondEndDate)}`, this.state.secondSlotTime, 1)}
                        </>
                      )}
                  </>
                  <Box style={{ marginTop: "15px" }}>
                    <Typography variant="h4" style={webStyles.weekDays}>
                      Write the problem
                    </Typography>
                    <TextField
                      variant="outlined"
                      data-test-id="problem"
                      className={classes.doctorBookingRegisterTextFields}
                      placeholder="Write here..."
                      value={this.state.text}
                      onChange={(e) => {this.settext(e)}}
                      multiline rows={8}
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="primary"
                      data-test-id="showDoctorSummaryPopup"
                      style={webStyles.appointmentBookBtn}
                      onClick={this.bookDoctor}
                    >
                      Book Appointment
                    </Button>
                  </Box>
                </Box>
              </Box>
              {this.state.showDialog && (
                <Box
                  className={classes.PaperDoctorSummaryPropsClass}
                  data-test-id="showSummary"
                >
                  <Box className={classes.popoverDoctorSummaryContainer}>
                    <Box className={classes.selectSummaryDoctorContainer}>
                      <Typography variant="h6" className={classes.titleDoctorHeading}>
                        Summary
                      </Typography>
                      <Typography variant="h6" data-test-id="closeSummary" className={classes.closeInsuranceIconBtn} onClick={this.showDoctorSummaryPopup}>
                        <CloseIcon />
                      </Typography>
                    </Box>
                    <Box style={{ maxHeight:'calc(65vh - 100px)',overflowY: 'scroll', scrollbarColor: "white white", scrollbarWidth: "thin", marginRight: "5px" }}>
                      <Box className={classes.topContainer} data-test-id="summaryContainer">
                        <Box className={classes.profileDoctorImageContainer}>
                          <img
                            data-test-id="avatarSize"
                            className={classes.summaryDoctorProfile}
                            src={UserProfile}
                          />
                        </Box>
                        <Box className={classes.profileDoctorDetailContainer} data-test-id="summaryDetails">
                          <Typography variant="h6" className={classes.userDoctorName}>
                            Dr. {this.state.summaryData.attributes?.healthcare_personnel_name}
                          </Typography>
                          <Typography variant="h6" className={classes.headingAddress}>
                            {this.state.summaryData.attributes?.city}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={classes.bottomBorder} />
                      <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box className={classes.summaryDetailsContainer}>
                          <Typography variant="h6" className={classes.summaryDoctorTitle}>Service</Typography>
                          <Typography variant="h6" className={classes.summaryDoctorTitle}>Matching Languages</Typography>
                          <Typography variant="h6" className={classes.summaryDoctorTitle}>Patient Name</Typography>
                          <Typography variant="h6" className={classes.summaryDoctorTitle}>Date</Typography>
                          <Typography variant="h6" className={classes.summaryDoctorTitle}>Appointment Time</Typography>
                          <Typography variant="h6" className={classes.summaryDoctorTitle}>Problem</Typography>
                          <Typography variant="h6" className={classes.summaryDoctorTitle}>Consultation Fee</Typography>
                          <Typography variant="h6" className={classes.summaryDoctorTitle}>Service Fee</Typography>
                        </Box>
                        <Box className={classes.summaryDetailsContainer}>
                          {Array.from({ length: 8 }).map((_, index) => (
                            <Typography key={index} variant="h6" className={classes.summaryDoctorDetails}>
                              :
                            </Typography>
                          ))}
                        </Box>
                        <Box className={classes.summaryDetailsContainer}>
                          <Typography variant="h6" className={classes.summaryDoctorDetails}>
                          {this.state.summaryData.attributes?.service}
                          </Typography>
                          <Typography variant="h6" className={classes.summaryDoctorDetails}>
                            {this.state.summaryData?.attributes?.matching_languages[0]}
                          </Typography>
                          <Typography variant="h6" className={classes.summaryDoctorDetails}>
                            {this.state.patientName}
                          </Typography>
                          <Typography variant="h6" className={classes.summaryDoctorDetails}>
                            {this.formatSummaryDate(this.state.summaryData?.attributes?.date)}
                          </Typography>
                          <Typography variant="h6" className={classes.summaryDoctorDetails}>
                            <span>{this.state.period},</span> <span style={{textTransform:"lowercase"}}>{this.state.summaryData?.attributes?.start_time}</span>
                          </Typography>
                          <Typography variant="h6" className={classes.summaryDoctorDetails}>
                            {this.state.summaryData?.attributes?.problem}
                          </Typography>
                          <Typography variant="h6" className={classes.summaryDoctorDetails}>
                            {this.state.sign}{this.state.summaryData?.attributes?.healthcare_personnel_fee}
                          </Typography>
                          <Typography variant="h6" className={classes.summaryDoctorDetails}>
                           {this.state.sign}50
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Button data-test-id="changeDetails" variant="contained" color="primary" className={classes.changeDoctorDetailsButtons} style={{ textTransform: "capitalize" }} onClick={this.changeDocSummaryDetails}>
                        Change Details
                      </Button>
                      <Button data-test-id="makeDocPaymentBtn" variant="contained" color="primary" className={classes.paymentDoctorButtons} style={{ textTransform: "capitalize" }} onClick={this.handleMedicPayment}>
                        Select Payment
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
              <Dialog classes={{ paper: classes.dialogpapers }} style={{ textAlign: "center" }} id="errorBox" disableScrollLock={true} open={!!this.state.bookingErrorMessage}>
                <DialogContent><p className={classes.dialogTexts}> {this.state.bookingErrorMessage}</p></DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <Button color="primary" onClick={() => this.handleDialogClose()} variant="contained" className={classes.dialogButtons} style={{ textTransform: "capitalize" }} data-test-id="closePopUp">
                    Okay
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          </Grid>
        </Grid>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  bookDoctorProfileContainer: {
    display: "flex",
    overflowX:"hidden" as "hidden",
    '@media (max-width:1100px)': {
      overflowX:"scroll" as "scroll !important",
    },
  },
  bookDoctorContainer: {
    margin: "40px 50px 30px",
    width: "70%",
    minHeight: "10px",
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: '20px',
  },
  slotBookingContainer: {
    margin: "40px 50px 30px",
    width: "65%",
    minHeight: "10px",
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: '20px',
  },
  bookDoctorHeaderContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "25px",
    justifyContent: "space-between",
  },
  bookDoctorHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "25px",
  },
  bookDoctorBackButton: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #E9E9E9",
    padding: "12px 8px 12px 16px",
  },
  bookDoctorTitle: {
    fontFamily: "SF Pro Text Bold",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "33.41px",
    color: "#292929",
  },
  doctorBookingRegisterInputFields: {
    marginBottom: "10px",
    width: "100%",
    fontSize: "18px",
    '&::-webkit-input-placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    '&::placeholder': {
      fontSize: "18px",
      opacity: 1,
      color: '#808080 !important',
    },
    "& .MuiInputBase-input": {
      color: "#292929",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      fontSize: "18px",
      cursor: "pointer",
      fontFamily: "SF Pro Text Regular",
    },
    "& ::-webkit-input-placeholder": {
      color: "#808080",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    fontFamily: "SF Pro Text Regular",
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "18px",
        fontFamily: "SF Pro Text Regular",
        color: "#808080",
      }
    }
  },
  requiredField: {
    color: "#f44336"
  },
  registerHospitalBookingLabel: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    margin: "10px 0px",
    textAlign: "left" as "left",
    caretColor: "transparent",
    color: "#292929"
  },
  registerDateLabel: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    margin: "4px 0px 10px",
    textAlign: "left" as "left",
    caretColor: "transparent",
    color: "#292929"
  },
  boxContainer: {
    width: "94%"
  },
  customDateInputName: {
    width: "100%",
    borderRadius: "8px",
    "& input": {
      color: "#292929",
      fontSize: "18px",
      background: `url(${Calender}) left / contain no-repeat`,
      backgroundPosition: "12px",
      backgroundSize: "25px",
      width: "100%",
      height: "58px",
      border: "1px solid rgba(0, 0, 0, 0.23)",
      display: "flex",
      margin: "auto",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular",
      caretColor: "transparent"
    },
    "& ::-webkit-input-placeholder": {
      color: "#565454",
    },
    "&:focus": {
      border: "1px solid blue",
    },
  },
  customDateInputNameError: {
    width: "100%",
    "& input": {
      color: "#292929",
      borderRadius: "8px",
      background: `url(${Calender}) left / contain no-repeat`,
      backgroundPosition: "12px",
      backgroundSize: "25px",
      width: "100%",
      height: "58px",
      border: "1px solid red",
      fontSize: "18px",
      display: "flex",
      margin: "auto",
      caretColor: "transparent",
      fontFamily: "SF Pro Text Regular"
    },
    '&::placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      color: '#808080 !important',
      opacity: 1,
    },
    "&:focus": {
      border: "1px solid blue",
    },
    borderRadius: "8px",
  },
  customDateFieldName: {
    display: "flex",
    padding: "15px 60px",
    justifyContent: "center",
    borderRadius: "8px",
  },
  datepickerClass: {
    "& .react-datetime-picker__wrapper": {
      border: "none"
    },
    "& .react-datepickerClass-wrapper": {
      display: "flex !important",
    },
    "& .react-datepickerClass__month": {
      margin: "0px !important"
    },
    "& .react-datepickerClass-popper": {
      transform: "translate(90px, 209px) !important"
    },
    "& .react-datepickerClass__day": {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepickerClass__day-name": {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepickerClass__month-container": {
      margin: "0px 0px 5px !important"
    },
    "& .react-datepickerClass__day-names": {
      marginBottom: "-1px !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__navigation": {
      height: "22px !important",
      width: "22px !important",
      margin: "5px -2px !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day--keyboard-selected": {
      color: "#000 !important",
      backgroundColor: "white !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day--today": {
      color: "#1d5d90 !important",
      backgroundColor: "white !important",
      fontWeight: "bold !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day:hover": {
      color: "#ccc !important",
      backgroundColor: "white !important",
      caretColor: "transparent"
    },
  },
  dateFieldError: {
    "& .react-datepicker-ignore-onclickoutside": {
      border: "none !important",
      borderColor: "transparent !important"
    },
    display: "flex",
    padding: "15px 60px",
    border: "1px solid red",
    justifyContent: "center",
    borderRadius: "8px",
    cursor: "pointer",
    "&:focus-visible": {
      outline: "none",
      border: "2px solid #3a52d4",
    },
    "&:focus": {
      outline: "none",
      border: "2px solid #3a52d4",
    },
    "&:hover": {
      outline: "none",
      border: "1px solid #3f51b5",
    },
    color: "#292929",
    "& input": {
      color: "#292929"
    }
  },
  dateField: {
    "& .react-datepicker-ignore-onclickoutside": {
      border: "none !important",
      borderColor: "transparent !important"
    },
    display: "flex",
    padding: "15px 60px",
    border: "1px solid #3f51b5",
    justifyContent: "center",
    borderRadius: "8px",
    cursor: "pointer",
    "&:focus-visible": {
      outline: "none",
      border: "2px solid #3a52d4",
    },
    "&:focus": {
      outline: "none",
      border: "2px solid #3a52d4",
    },
    "&:hover": {
      outline: "none",
      border: "1px solid #3f51b5",
    },
    color: "#292929",
    "& input": {
      color: "#292929"
    }
  },
  slotContainer: {
    gap: "20px",
    display: "flex",
    width: "94%",
    justifyContent: "flex-end",
    marinTop: "6px"
  },
  slotText: {
    fontSize: "13px",
    fontFamily: "SF Pro Text Medium",
    color: "#808080"
  },
  unSelected: {
    width: "auto",
    height: "50px",
    borderRadius: "8px",
    backgroundColor: "#ffffff",
    color: "#292929",
    fontFamily: "SF Pro Text Regular",
    border: "1px solid #808080",
    padding: "16px 12px",
    cursor: "pointer",
    fontSize: "16px",
    margin: "10px 0px 20px 10px",
    caretColor: "transparent",
    textTransform: "lowercase " as "lowercase",
    "&:hover": {
      backgroundColor: "#ffffff",
      color: "#292929"
    }
  },
  selected: {
    width: "auto",
    height: "50px",
    borderRadius: "8px",
    backgroundColor: "#3780E0",
    color: "#FFFFFF",
    fontFamily: "SF Pro Text Regular",
    border: "none",
    padding: "16px 12px",
    cursor: "pointer",
    fontSize: "16px",
    margin: "10px 0px 20px 10px",
    caretColor: "transparent",
    textTransform: "lowercase " as "lowercase",
    "&:hover": {
      backgroundColor: "#3780E0",
      color: "#FFFFFF",
    }
  },
  availabilitySlotContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  weekDays: {
    color: "#292929",
    fontSize: "20px",
    fontFamily: "SF Pro Text Medium",
  },
  slotDuration: {
    color: "#808080",
    fontSize: "16px",
    fontFamily: "SF Pro Text Regular",
  },
  doctorBookingRegisterTextFields: {
    width: "75%",
    fontSize: "18px",
    marginBottom: "10px",
    marginTop: "15px",
    '&::-webkit-input-placeholder': {
      opacity: 1,
      color: '#808080 !important',
    },
    '&::placeholder': {
      color: '#808080 !important',
      fontSize: "18px",
      opacity: 1,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiInputBase-input": {
      fontSize: "18px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
    },
    "& .MuiOutlinedInput-root": {
      cursor: "pointer",
      borderRadius: "8px",
      fontSize: "18px",
      fontFamily: "SF Pro Text Regular",
    },
    fontFamily: "SF Pro Text Regular",
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        fontFamily: "SF Pro Text Regular",
        color: "#808080",
        opacity: 1,
        fontSize: "18px",
      }
    },
    "& ::-webkit-input-placeholder": {
      color: "#808080",
    },
  },
  appointmentBookBtn: {
    caretColor: "transparent",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    width: "250px",
    height: "52px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    marginRight: "20px",
    marginTop: "20px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    textTransform: "capitalize" as textTransform,
    '@media (max-width:1250px)': {
      width: "230px !important",
    },
  },
  PaperDoctorSummaryPropsClass: {
    backgroundColor: '#050505a3',
    position: "fixed" as PositionValue,
    top: 0,
    left: 0,
    marginLeft: "8.30%",
    width: "100%",
    height: "100%",
    zIndex: 999,
    overflowY: "auto" as OverflowXProperty
  },
  popoverDoctorSummaryContainer: {
    position: 'relative' as PositionValue,
    top: '65%',
    left: '46%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    borderRadius: '18px',
    boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.1)',
    zIndex: 9999,
    width: '430px',
    padding: '20px 30px',
    maxHeight: "calc(100vh - 100px)",
    '@media (max-height: 1000px)': {
      top: '50%',
    },

  },
  titleDoctorHeading: {
    display: "flex",
    fontSize: "28px",
    marginTop: "15px",
    alignItems: "start",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    justifyContent: "space-between",
  },
  selectSummaryDoctorContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "433px"
  },
  summaryDoctorProfile: {
    borderRadius: "50%",
    width: "138px",
    height: "138px",
    border: "1px solid #eeeaea",
    backgroundColor: "#eeeaea",
  },
  topContainer: {
    display: "flex",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 30px 0px",
    marginTop: "25px",
    gap: "20px"
  },
  userDoctorName: {
    fontSize: "24px",
    color: "#292929",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    lineHeight: "28px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    width: "100%",
    maxHeight: "55px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
  } as any,
  summaryDoctorTitle: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    caretColor: "transparent",
    marginTop: "20px",
    color: "#292929",
  },
  summaryDoctorDetails: {
    fontSize: "18px",
    minHeight:'28px',
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    color: "#808080",
    marginTop: "20px",
    textTransform :"capitalize" as const,
  },
  paymentDoctorButtons: {
    width: "300",
    height: "56px",
    backgroundColor: "#014866",
    color: "#F2F6F7",
    border: "none",
    borderRadius: "9px",
    padding: "16px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    display: "flex",
    margin: "25px auto 25px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff",
    },
  },
  changeDoctorDetailsButtons: {
    width: "300",
    height: "56px",
    fontSize: "18px",
    backgroundColor: "#fff",
    color: "#014866",
    border: "1px solid #014866",
    borderRadius: "9px",
    padding: "16px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Bold",
    margin: "30px auto 0px",
    display: "flex",
    "&:hover": {
      backgroundColor: "#fff",
      color: "#f014866",
    },
  },
  profileDoctorImageContainer: {
    width: "20%",
    [theme.breakpoints.down("md")]: {
      width: "40%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    }
  },
  profileDoctorDetailContainer: {
    width: "75%",
    marginTop: "20px",
    paddingLeft: "57px",
  },
  dialogpapers: {
    borderRadius: "20px",
    maxWidth: "450px",
    padding: "10px 40px 50px",
    marginLeft: "13vw",
    minWidth:"350px"
  },
  dialogTexts: {
    fontSize: "20px",
    color: "#383838",
    fontFamily: "SF Pro Text Medium",
    fontWeight: 500,
    lineHeight: "26px",
    caretColor: "transparent !important"
  },
  dialogButtons: {
    width: "100%",
    height: "65px",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "10px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Bold",
    fontWeight: 600,
    fontSize: "18px",
    margin: "20px auto 10px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    caretColor: "transparent"
  },
}

export default withStyles(webStyles)(Reservations2ViewDoctorBooking);
// Customizable Area End

