import React, { ReactNode } from 'react';
import { Dialog, DialogActions, DialogContent, IconButton, Typography, Box, Divider, styled } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    open: boolean;
    children: ReactNode,
    onClose: () => void;
    closeIcon?: boolean;
    actionComponent?: ReactNode;
    modalTitle?: string;
    subTitle?: string;
    contentBoxStyle?: React.CSSProperties;
    titleBoxStyle?: React.CSSProperties;
    dialogStyle?: React.CSSProperties | undefined
}

const CommonModal = (props: Props) => {
    const { children, onClose, subTitle = '', dialogStyle, contentBoxStyle = {}, titleBoxStyle = {}, open, closeIcon, actionComponent, modalTitle } = props;
    return <Dialog open={open} onClose={onClose} PaperProps={{ style: dialogStyle }} BackdropProps={{ style: webStyle.backDropStyle }} >
        <Box style={{ ...webStyle.modalTitleWrapper, ...titleBoxStyle }}>
            <Box>
                <Typography style={webStyle.modalTitle} >{modalTitle}</Typography>
                {subTitle && <Typography style={webStyle.subtitle} >{subTitle}</Typography>}
            </Box>
            {closeIcon && <IconButton data-test-id='closeIcon' aria-label="close" onClick={onClose}>
                <CloseIcon style={{ color: 'black' }} />
            </IconButton>}
        </Box>
        <StyledDialogContent style={contentBoxStyle}>
            {children}
        </StyledDialogContent>
        <DialogActions style={{ padding: '16px' }} >{actionComponent}</DialogActions>
    </Dialog >
}
export default CommonModal;

const StyledDialogContent = styled(DialogContent)({
    padding: '16px 40px',
})

const webStyle = {
    subtitle: {
        color: '#64748B',
        fontSize: '16px',
        lineHeight: '26px',
        fontFamily: "SF Pro Text Regular",
    },
    modalTitle: {
        color: '#334155',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '26px',
        fontFamily: "SF Pro Text Regular",
    },
    modalTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 16px 16px 40px'
    },
    backDropStyle: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    }
}