import React from "react";
// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from "@material-ui/core";
import Sidebar from "../../../components/src/Sidebar.web";
import DashboardController, { configJSON, Props } from "./DashboardController";
import { OverflowXProperty, Position, objectFitType, textAlignType } from './types'
import { searchIcon, SOSImage, Homevisit, Hospitalvisit, FilterIcon } from "./assets"
import DashboardView from "./DashboardView.web";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Skeleton from '@material-ui/lab/Skeleton';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#0000ff',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#0000ff',
        },
        '& input': {
          color: '#292929',
          fontSize: '16px',
          cursor: 'pointer',
          fontFamily: 'SF Pro Text Regular',
          '&::placeholder': {
            opacity: 1,
            fontSize: '16px',
            fontFamily: 'SF Pro Text Regular',
            color: '#808080',
          },
        },
      },
    },
    MuiInputLabel: {
      root: {
        '&.Mui-focused': {
          color: '#0000ff',
        },
      },
    },
  },
});

// Customizable Area End
class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderDoctorList = () => {
    return this.state.doctorList.length > 0 ?
      this.state.doctorList.map((docData, indx) => <>
        <Box sx={webStyle.doctorCard} key={docData.id}>
          <Box sx={webStyle.avatarNameWrapper}>
            <Avatar style={webStyle.avatar} src={`${docData.attributes?.profile_image_url}`} alt="profile_image" />
            <Box sx={webStyle.ratingWrapper}>
              <Typography style={webStyle.doctorName}>{docData.attributes?.name}</Typography>
              <Typography style={webStyle.availableSlots}>{configJSON.AvailableTimeSlots}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography style={webStyle.rating}>4.2 / 5</Typography>
            <Typography style={webStyle.speciality}>{docData.attributes?.profile_type_specific_data?.specialization?.name}</Typography>
          </Box>
        </Box>
        {indx !== this.state.doctorList.length - 1 && <Divider />}
      </>) : this.renderNoSearchResult()
  }

  renderHealthCarePersonList = () => {
    return this.state.healthCarePersonList.length > 0 ?
      this.state.healthCarePersonList.map((docData, indx) => <>
        <Box sx={webStyle.doctorCard} key={docData?.id}>
          <Box sx={webStyle.avatarNameWrapper}>
            <Avatar style={webStyle.avatar} src={`${docData.attributes?.profile_image}`} alt="profile_image" />
            <Box sx={webStyle.ratingWrapper}>
              <Typography style={webStyle.doctorName}>{docData.attributes?.name}</Typography>
              <Typography style={webStyle.speciality}>{docData.attributes?.profile_type}</Typography>
            </Box>
          </Box>
        </Box>
        {indx !== this.state.healthCarePersonList.length - 1 && <Divider />}
      </>) : this.renderNoSearchResult()
  }

  renderSkeletonLoader = () => {
    return <Box sx={webStyle.avatarNameWrapper}>
      <Skeleton variant="circle" style={webStyle.avatar} />
      <Box sx={webStyle.ratingWrapper}>
        <Skeleton variant="text" width={150} />
        <Skeleton variant="text" width={100} />
      </Box>
    </Box>
  }

  renderNoSearchResult = () => {
    return this.state.isAdvanceSearchLoading ? this.renderSkeletonLoader() : <Typography style={{ fontFamily: "SF Pro text Regular" }} >No healthcare person found</Typography>
  }

  renderAdvanceSearch = () => {
    return <Box sx={webStyle.searchAndResultWrapper}>
      <StyledSearch
        fullWidth
        onBlur={this.removeListOnBlur}
        ref={this.searchInputRef}
        onChange={this.onSearch}
        onKeyDown={this.handleKeyDown}
        data-test-id="search-doctor"
        value={this.state.searchValue}
        variant="outlined"
        placeholder={configJSON.PlaceholderSearchDoctors}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img
                src={searchIcon}
                alt="search"
                style={webStyle.searchIcons}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {this.state.searchValue && <IconButton onClick={this.clearSearch}>
                <CloseRoundedIcon />
              </IconButton>}
            </InputAdornment>
          ),
          style: {
            borderRadius: "8px",
          }
        }}
      />
      {this.state.isSearchActive && <Box data-test-id="search-result" style={{ ...webStyle.searchResultWrapper, width: this.searchInputRef.current?.offsetWidth, }}>
        {this.state.userType === configJSON.userType.Patient ? this.renderHealthCarePersonList() : this.renderDoctorList()}
      </Box>
      }
    </Box>
  }
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Box style={webStyle.container}>
            <Box sx={webStyle.sidebarWrapper}>
              <Sidebar />
            </Box>
            <Box style={webStyle.rightWrapper}>
              {this.state.userType === configJSON.userType.Patient && (
                <>
                  <Box style={webStyle.dashboardContainer}>
                    <Box style={webStyle.searchBarContainr}>
                      {this.renderAdvanceSearch()}
                      <img
                        style={webStyle.sosImage}
                        src={SOSImage}
                      />
                    </Box>
                    <Box>
                      <img
                        style={webStyle.bannerImage}
                        src={this.state.banner}
                      />
                    </Box>
                    <Box style={webStyle.ServiceBtn}>
                      <Box style={webStyle.serviceBox}>
                        <Typography variant="h6" style={webStyle.serviceText}>
                          Services
                        </Typography>
                      </Box>
                      <Box style={webStyle.serviceBoxTwo}>
                        <Typography variant="h6" style={webStyle.Services}>
                          Plans
                        </Typography>
                        <Typography variant="h6" style={webStyle.Services}>
                          View Prescriptions
                        </Typography>
                        <Typography variant="h6" style={webStyle.Services}>
                          Book Lab Tests
                        </Typography>
                      </Box>
                    </Box>
                    <Grid container spacing={2}>
                      {this.state.data.map((item: { image: string | undefined; name: string | undefined }, index: React.Key | undefined) => (
                        <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={index}>
                          <Box data-test-id={`clickToService${index}`} style={{
                            ...webStyle.serviceContainer, backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${item.image})`
                          }} onClick={() => this.openService(item.name)}>
                            <Typography style={webStyle.serviceName}>{item.name}</Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </>
              )}
              {this.state.userType === configJSON.userType.HealthcarePersonnel && (
                <DashboardView id={""} navigation={undefined} />
              )}

              {this.state.userType !== configJSON.userType.Patient && this.state.userType !== configJSON.userType.HealthcarePersonnel && (
                <Box sx={webStyle.hospitalDashboardWrapper} >
                  <Box sx={webStyle.searchFilterWrapper}>
                    {this.renderAdvanceSearch()}
                    <IconButton>
                      <img src={FilterIcon} alt="filter_icon" />
                    </IconButton>
                  </Box>
                  <Box sx={webStyle.hospitalDetailsWrapper}>
                    <Box sx={webStyle.hospitalInfoWrapper}>
                      <Box sx={webStyle.apointmentWrapper}>
                        <Typography style={webStyle.numberOfAppointment}>{this.state.hospitalDashboardData?.data.total_reservations}</Typography>
                        <Typography style={webStyle.appointmentDataText}>{configJSON.HospitalDashboard.TOTAL_APPOINTMENTS}</Typography>
                      </Box>
                      <Box sx={webStyle.apointmentWrapper}>
                        <Typography style={webStyle.numberOfAppointment}>{this.state.hospitalDashboardData?.data.todays_reservations}</Typography>
                        <Typography style={webStyle.appointmentDataText}>{configJSON.HospitalDashboard.TODAYS_APPOINTMENTS}</Typography>
                      </Box>
                      <Box sx={webStyle.apointmentWrapper}>
                        <Typography style={webStyle.numberOfAppointment}>{this.state.hospitalDashboardData?.data.total_beds_booked}</Typography>
                        <Typography style={webStyle.appointmentDataText}>{configJSON.HospitalDashboard.TOTAL_BED_BOOKED}</Typography>
                      </Box>
                    </Box>
                    <Box sx={webStyle.bedInfoWrapper}>
                      <Typography style={webStyle.availableBeds} >{configJSON.HospitalDashboard.AVAILABLE_BEDS}</Typography>
                      <Box sx={webStyle.bedInfoCardWrapper} >
                        {
                          this.state.hospitalDashboardData && this.state.hospitalDashboardData?.data?.available_bed_types.length > 0 ? this.state.hospitalDashboardData?.data.available_bed_types.map((bed) => {
                            return <Box sx={webStyle.bedInfoCard}>
                              <Typography style={webStyle.numberOfAvailableBeds}>{bed.available_beds} <span style={webStyle.availableOutOf}>/ <span style={webStyle.availableOutofFontSize} > {bed.total_beds} </span> </span></Typography>
                              <Divider style={webStyle.divider} />
                              <Typography style={webStyle.bedTypeInfo}>{bed.bed_name}</Typography>
                            </Box>
                          })
                            : <Typography style={{ fontFamily: "SF Pro text Regular" }}>{configJSON.NoBedsAvailable}</Typography>
                        }
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}

            </Box>
            <Box sx={webStyle.notificationWrapper}></Box>
          </Box>
          <style>
            {`
                .MuiDialog-paper {
                  border-radius: 15px;
                }
              `}
          </style>
          <Dialog open={!!this.state.showPopup} data-test-id="popup">
            <Box>
              <Box style={{ padding: "35px 45px" }}>
                <p style={{
                  fontSize: "20px",
                  color: "#292929",
                  margin: "auto",
                  textAlign: "center" as "center",
                  lineHeight: "32px",
                  caretColor: "transparent",
                  fontFamily: "SF Pro Text Medium",
                }}>All Doctors are filtered according to</p>
                <p style={{
                  fontSize: "20px",
                  color: "#292929",
                  margin: "auto",
                  textAlign: "center" as "center",
                  lineHeight: "32px",
                  caretColor: "transparent",
                  fontFamily: "SF Pro Text Medium",
                }}>languages known by you.</p>
                <Button style={{
                  display: "flex",
                  borderRadius: "8px",
                  justifyContent: "center",
                  width: "100%",
                  height: "56px",
                  backgroundColor: "#014866",
                  color: "#fff",
                  border: "none",
                  padding: "16px",
                  cursor: "pointer",
                  fontFamily: "SF Pro Text Bold",
                  fontSize: "18px",
                  margin: "40px auto 15px",
                  textTransform: "capitalize" as "capitalize"
                }}
                  data-test-id="selectConsultationType"
                  onClick={this.selectConsultationType}>Okay</Button>
              </Box>
            </Box>
          </Dialog>
          <Dialog open={!!this.state.showHospitalType} data-test-id="popup">
            <Box style={{
              display: "flex",
              justifyContent: "center",
              width: "380px",
              minHeight: "230px",
              gap: "40px",
              alignItems: "center",
              margin: "auto"
            }}>
              <Box>
                <img
                  style={{
                    width: "40px",
                    objectFit: "cover" as "cover",
                    display: "block",
                    borderRadius: "8px",
                    margin: "5px auto",
                    border: "1px solid #d3e3f8",
                    padding: "20px",
                    background: this.state.isConnectedHospital ? "transparent" : "#d3e3f8"
                  }}
                  src={Homevisit}
                  data-test-id="Homevisit"
                  onClick={() => this.handelIsConnectedHospital(false)}
                />
                <Box style={{ display: "flex", justifyContent: "center", fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929" }}>Home Visit</Box>
              </Box>
              <Box>
                <img
                  style={{
                    width: "47px",
                    objectFit: "cover" as "cover",
                    display: "block",
                    borderRadius: "8px",
                    border: "1px solid #d3e3f8",
                    padding: "20px",
                    margin: "5px auto",
                    background: this.state.isConnectedHospital ? "#d3e3f8" : "transparent"
                  }}
                  src={Hospitalvisit}
                  data-test-id="Hospitalvisit"
                  onClick={() => this.handelIsConnectedHospital(true)}
                />
                <Box style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", display: "flex", justifyContent: "center" }}>Hospital Visit</Box>
              </Box>
            </Box>
          </Dialog>
          <Dialog open={this.state.showConsultationType} data-test-id="popup">
            <Box style={{
              display: "flex",
              justifyContent: "center",
              width: "450px",
              minHeight: "250px",
              gap: "30px",
              alignItems: "center",
              margin: "auto"
            }}>
              <Box style={{ width: "150px" }}>
                <img
                  style={{
                    width: "40px",
                    objectFit: "cover" as "cover",
                    display: "block",
                    borderRadius: "8px",
                    margin: " 5px auto",
                    border: "1px solid #d3e3f8",
                    padding: "20px",
                    background: "#d3e3f8"
                  }}
                  src={Homevisit}
                  data-test-id="appointment"
                  onClick={() => this.handelIsAppointmentHospital("appointment")}
                />
                <Box style={{ display: "flex", justifyContent: "center", fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929" }}>Appointment</Box>
              </Box>
              <Box style={{ width: "150px" }}>
                <img
                  style={{
                    width: "47px",
                    objectFit: "cover" as "cover",
                    display: "block",
                    borderRadius: "8px",
                    border: "1px solid #d3e3f8",
                    padding: "20px",
                    margin: " 5px auto",
                    background: "transparent"
                  }}
                  src={Hospitalvisit}
                  data-test-id="online"
                  onClick={() => this.handelIsAppointmentHospital("online")}
                />
                <Box style={{ fontFamily: "SF Pro Text Regular", fontSize: "16px", color: "#292929", display: "flex", justifyContent: "center" }}>Online Consultation</Box>
              </Box>
            </Box>
          </Dialog>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

export default Dashboard;

// Customizable Area Start

const StyledSearch = styled(TextField)({
  fontFamily: "SF Pro text Regular",
  maxWidth: '848px',
  "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #014866",
    borderRadius: "8px",
  },
  "& .MuiInputBase-root.MuiOutlinedInput-root": {
    borderRadius: "8px",
    paddingLeft: "16px",
  },
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    fontFamily: "SF Pro text Regular",
  },
  '& .MuiOutlinedInput-adornedStart': {
    paddingLeft: '0'
  },
  '& .MuiOutlinedInput-inputAdornedStart': {
    paddingLeft: '10px'
  }
})
const webStyle = {
  availableOutOf: {
    color: '#014866B2',
  },
  availableOutofFontSize: {
    fontSize: "28px"
  },
  searchAndResultWrapper: {
    width: '100%'
  },
  hospitalDashboardWrapper: {
    padding: '46px 60px'
  },
  searchFilterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    gridGap: '20px',
    alignItems: 'center'
  },
  searchResultWrapper: {
    position: 'absolute',
    zIndex: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    marginTop: '5px',
    backdropFilter: 'blur(32px)',
    boxShadow: "0px 2px 24px 0px #98989838",
    padding: "20px 25px",
    boxSizing: 'border-box',
    border: "1px solid #C2C2C24D",
    borderRadius: '8px',
    maxHeight: "376px",
    overflowY: "auto"
  } as React.CSSProperties,
  doctorCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  avatarNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    gridGap: '20px'
  },
  avatar: {
    width: '85px',
    height: '85px',
    textTransform: 'capitalize'
  } as React.CSSProperties,
  ratingWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  availableSlots: {
    color: '#3780E0',
    fontSize: '14px',
    fontFamily: 'SF Pro Text Regular'
  },
  doctorName: {
    fontSize: '18px',
    fontFamily: 'SF Pro Text Bold',
    marginBottom: "8px"
  },
  speciality: {
    color: '#AC8E68',
    fontSize: '14px',
    fontFamily: 'SF Pro Text Regular',
    textTransform: 'capitalize'
  } as React.CSSProperties,
  rating: {
    color: '#014866',
    fontSize: '14px',
    fontFamily: 'SF Pro Text Medium',
    textAlign: 'end',
    marginBottom: "8px"
  } as React.CSSProperties,
  bedInfoCardWrapper: {
    display: "flex",
    alignItems: 'center',
    gridGap: '40px',
    paddingTop: '40px',
    flexWrap: "wrap"
  },
  bedInfoWrapper: {
    paddingTop: "60px"
  },
  bedTypeInfo: {
    color: 'black',
    fontSize: '16px',
    fontFamily: 'SF Pro Text Medium'
  },
  divider: {
    height: '1px',
    width: '60%',
    margin: '9px 0 12px'
  },
  numberOfAvailableBeds: {
    fontSize: '35px',
    fontFamily: 'SF Pro Text Bold',
    color: "#014866"
  },
  bedInfoCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '8px',
    boxShadow: "0px 4px 40px 0px #0148661A",
    minWidth: '150px', 
    width: 'auto', 
    maxWidth: '100%' ,
    padding: '24px 18px',
    boxSizing:'border-box'
  },
  availableBeds: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '22px'
  },
  hospitalDetailsWrapper: {
    padding: "50px 0"
  },
  hospitalInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    gridGap: "34px",
    flexWrap: 'wrap'
  },
  apointmentWrapper: {
    border: '2px solid black',
    borderRadius: '8px',
    maxWidth: '260px',
    width: '100%',
    padding: '38px 24px',
    boxSizing: 'border-box',
  },
  appointmentDataText: {
    fontFamily: "SF Pro text Medium",
    fontSize: "20px",
    fontWeight: 500,
    whiteSpace: "nowrap"
  } as React.CSSProperties,
  numberOfAppointment: {
    color: "#014866",
    fontSize: '54px',
    lineHeight: '1.1',
    fontWeight: 600,
    fontFamily: "SF Pro text Heavy"
  },
  sidebarWrapper: {
    width: '132px',
    minHeight: 'max(100vh, 700px)'
  },
  notificationWrapper: {
    minWidth: '340px'
  },
  dashboardContainer: {
    margin: "40px 50px",
  },
  searchBarContainr: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    gap: '40px'
  },
  searchField: {
    width: "85%",
    margin: "15px 0px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px !important",
      border: "1px solid #808080"
    },
    "& input": {
      paddingLeft: "8px",
      paddingTop: "16px",
      fontSize: "16px !important",
      color: "#292929 !important",
      fontFamily: "SF Pro Text Regular !important",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "16px !important",
        fontFamily: "SF Pro Text Regular !important",
      }
    },
  },
  searchIcons: {
    width: "24px",
    objectFit: "cover" as objectFitType,
    height: "24px",
    display: "flex",
    justifyContent: "center",
    margin: "0px auto"
  },
  sosImage: {
    width: "100px",
    height: "100px",
    cursor: "pointer",
  },
  container: {
    height: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: "100vh",
    overflowX: "hidden" as OverflowXProperty
  },
  rightWrapper: {
    minHeight: '100vh',
    width: '100%'
  },
  bannerImage: {
    width: "100%",
    objectFit: "cover" as objectFitType,
    display: "block",
    borderRadius: "8px",
    margin: "5% 0% 10%"
  },
  serviceText: {
    fontSize: "22px",
    color: "#292929",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Bold",
  },
  Services: {
    caretColor: "transparent",
    color: "#3780E0",
    fontSize: "16px",
    fontFamily: "SF Pro Text Semibold",
  },
  ServiceBtn: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "30px",
    cursor: "pointer"
  },
  serviceName: {
    caretColor: "transparent",
    display: 'block',
    position: 'absolute' as Position,
    top: '80%',
    left: '50%',
    margin: '0 auto',
    transform: 'translate(-50%,-50%)',
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#fff"
  },
  imageText: {
    position: 'absolute' as Position, // Position absolutely within the container
    top: 0, // Align text to the top
    left: 0, // Align text to the left
    width: '100%', // Take full width of container
    padding: '10px', // Adjust padding as needed
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background color
    color: '#ffffff', // Text color
    textAlign: 'center' as textAlignType // Center text horizontally
  },
  serviceBox: {
    width: "55%"
  },
  serviceBoxTwo: {
    width: "45%",
    display: "flex",
    justifyContent: "space-between"
  },
  serviceContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    position: 'relative' as Position,
    borderRadius: "8px",
    width: '100%',
    height: '150px',
    backgroundSize: 'cover',
    cursor: "pointer"
  },
};
// Customizable Area End

