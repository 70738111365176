import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  SvgIcon,
  withStyles,
} from "@material-ui/core";
import FilterController, { Props } from "./FilterController";
import { YellowStar, GrayStar  } from "./assets";
const Checked = () => (
  <SvgIcon
    viewBox="0 0 18 18"
    fill="none"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" rx="1.5" width="15" height="15" stroke="#014866" />
    <path
      d="M3.75 7.99992L6.58 10.8299L12.25 5.16992"
      stroke="#014866"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);

const UnChecked = () => (
  <SvgIcon
    fill="none"
    width="16"
    height="16"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
  </SvgIcon>
);

const TimeSlots = [
  { name: "Morning",value:"7:30am - 3pm" },
  { name: "Evening", value:"3pm - 10:30pm" },
];

const filters = {
  "0": [
    { name: "One Time", value: "one_time" },
    { name: "Recurring", value: "recurring" },
    { name: "Long Term", value: "long_term" }
  ],
  "1": [
    { name: "Morning", value: "shift_1" },
    { name: "Evening", value: "shift_2" },
    { name: "Night", value: "shift_3" }
  ],
  "2": [
    { name: "0 - 2 Years", from: "0", to: "2" },
    { name: "2 - 4 Years", from: "2", to: "4" },
    { name: "4 - 6 Years", from: "4", to: "6" },
    { name: "6+ Years", from: "6", to: null }
  ],
  "3": [
    { name: "Morning", value: "shift_1" },
    { name: "Night", value: "shift_2" },
  ],
  "4": [
    { name: "All Price", from: "0", to: null },
    { name: "Under ₹300", from: "0", to: "300" },
    { name: "₹300 - ₹800", from: "300", to: "800" },
    { name: "Above ₹800", from: "800", to: null }
  ]
};
 
// Customizable Area End

export class Filter extends FilterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // Customizable Area Start
      this.userRole()
      await this.getLanguages(); 
      await this.getDepartmentDetails();  
    // Customizable Area End
  }

  renderDoctorField = () => {
    if (this.state.hpRole === 'doctors' || this.state.hpRole === 'dietician' || this.state.hpRole === 'physiotherapist') {
      return (
        <>
          {this.state.hpRole === 'doctors' && <Box data-test-id="5" style={this.checkSelectedField("5")} onClick={() => this.hpFilterType("5")}>
              Department
          </Box>}
          <Box data-test-id="6" style={this.checkSelectedField("6")} onClick={() => this.hpFilterType("6")}>
              Price
          </Box>
          <Box data-test-id="7" style={this.checkSelectedField("7")} onClick={() => this.hpFilterType("7")}>
              Time Slots
          </Box>
      </>
      );
    }else {
      return (
        <>
          <Box data-test-id="0" style={this.checkSelectedField("0")} onClick={()=> this.hpFilterClick("0", "schedule_type")}>
              Schedules
          </Box>
          <Box data-test-id="1" style={this.checkSelectedField("1")} onClick={() => this.hpFilterClick("1", "shift")}>
              Shifts
          </Box>
        </>
      );
    }
  };

  renderScheduleFilter = () => {
    if (this.state.hpFilterType === "0") {
     return filters[0].map((selectedScheduleFilter: { name: string, value: string }, index: number) => {
        const filterTypeObject = this.state.selectedFilters && this.state.selectedFilters.length > 0 && this.state.selectedFilters.find(
          (filter: any) => filter.schedule_type !== undefined
        );
        const isFilterSelected = filterTypeObject
          ? filterTypeObject.schedule_type.some((i: any) => i.name === selectedScheduleFilter.name)
          : false;

        return (
          <TableRow key={index} id="schedulesTableRow">
            <TableCell id="schedulesTableCell" style={this.getRowStyles(isFilterSelected)}>
              <Checkbox
                data-test-id={`schedule${index}`}
                className={this.props.classes.checkbox}
                checked={isFilterSelected}
                onChange={() => this.handleFilterCheckboxChange(index, selectedScheduleFilter.value, "schedule_type", selectedScheduleFilter.name)}
                icon={<UnChecked />}
                checkedIcon={<Checked />}
              />
            </TableCell>
            <TableCell id="scheduleType" style={this.getSelectedField(isFilterSelected)}>
              {selectedScheduleFilter.name}
            </TableCell>
          </TableRow>
        );
      })}
  }

  renderShiftFilter = () => {
    if (this.state.hpFilterType === "1") {
     return (this.state.hpRole === "nurses" ? filters[1] : filters[3]).map((selectedShiftFilter: { name: string, value: string }, index: number) => {
        const filterTypeObjectShift = this.state.selectedFilters && this.state.selectedFilters.length > 0 && this.state.selectedFilters.find(
          (filter: any) => filter.shift !== undefined
        );
        const checkForShifts = filterTypeObjectShift
          ? filterTypeObjectShift.shift.some((i: any) => i.name === selectedShiftFilter.name)
          : false;

        return (
          <TableRow key={index} id="slotTableRow">
            <TableCell id="shiftTableCell" style={this.getRowStyles(checkForShifts)}>
              <Checkbox data-test-id={`shift${index}`} className={this.props.classes.checkbox} checked={checkForShifts} onChange={() => this.handleFilterCheckboxChange(index, selectedShiftFilter.value, "shift", selectedShiftFilter.name)} icon={<UnChecked />} checkedIcon={<Checked />}
              />
            </TableCell>
            <TableCell id="shiftType" style={this.getSelectedField(checkForShifts)}>
              {selectedShiftFilter.name}
            </TableCell>
          </TableRow>
        )
      })}
  }

  renderExpFilter = () => {
   if (this.state.hpFilterType === "2") {
     return filters[2].map((selectedExperienceFilter: { name: string, from: string, to: string | null }, index: number) => {

        const filterTypeObjectExp = this.state.selectedFilters && this.state.selectedFilters.length > 0 && this.state.selectedFilters.find(
          (filter: any) => filter.experience !== undefined
        );
        const checkForExp = filterTypeObjectExp
          ? filterTypeObjectExp.experience[0] === selectedExperienceFilter.name
          : false;
        return (
          <TableRow key={index} id="experienceTableRow">
            <TableCell id="experienceTableCell" style={this.getRowStyles(checkForExp)}>
              <Checkbox data-test-id={`experience${index}`} className={this.props.classes.checkbox} checked={checkForExp} onChange={() => this.handleFilterCheckboxChange(index, selectedExperienceFilter, "experience", selectedExperienceFilter.name)} icon={<UnChecked />} checkedIcon={<Checked />} />
            </TableCell>
            <TableCell id="experienceType" style={this.getSelectedField(checkForExp)}>
              {selectedExperienceFilter.name}
            </TableCell>
          </TableRow>
        )
      })}
  }

  renderPriceFilter = () => {
    if (this.state.hpFilterType === "6") {
      return filters[4].map((selectedPriceFilter: { name: string, from: string, to: string | null }, index: number) => {
 
         const filterTypeObjectPrice = this.state.selectedFilters && this.state.selectedFilters.length > 0 && this.state.selectedFilters.find(
           (filter: any) => filter.price !== undefined
         );

         const checkForPrice = filterTypeObjectPrice
           ? filterTypeObjectPrice.price[0] === selectedPriceFilter.name
           : false;
         return (
           <TableRow key={index} id="priceTableRow">
             <TableCell id="priceTableCell" style={this.getRowStyles(checkForPrice)}>
               <Checkbox data-test-id={`price${index}`} className={this.props.classes.checkbox} checked={checkForPrice} onChange={() => this.handleFilterCheckboxChange(index, selectedPriceFilter, "price", selectedPriceFilter.name)} icon={<UnChecked />} checkedIcon={<Checked />} />
             </TableCell>
             <TableCell id="priceType" style={this.getSelectedField(checkForPrice)}>
               {selectedPriceFilter.name}
             </TableCell>
           </TableRow>
         )
       })}
   }

  renderLangFilter = () => {
    if (this.state.hpFilterType === "3") {
      return (this.state.languagesData?.length > 0 ? this.state.languagesData.map((selectedLanguageFilter: { name: string, id: number | string }, index: number) => {
        const filterTypeObjectLang = this.state.selectedFilters && this.state.selectedFilters.length > 0 && this.state.selectedFilters.find(
          (filter: any) => filter.language !== undefined
        );
        const checkForLanguages = filterTypeObjectLang
          ? filterTypeObjectLang.language.some((i: any) => i.name === selectedLanguageFilter.name)
          : false;
        return (
          <TableRow key={index} id="languageTableRow">
            <TableCell id="languageTableCell" style={this.getRowStyles(checkForLanguages)}>
              <Checkbox data-test-id={`language${index}`} className={this.props.classes.checkbox} checked={checkForLanguages} onChange={() => this.handleFilterCheckboxChange(index, selectedLanguageFilter.id, "language", selectedLanguageFilter.name)} icon={<UnChecked />} checkedIcon={<Checked />} />
            </TableCell>
            <TableCell id="languageType" style={this.getSelectedField(checkForLanguages)}>
              {selectedLanguageFilter.name}
            </TableCell>
          </TableRow>
        )
      }) : (<span>No languages data available</span>))}
  }

  renderDepartFilter = () => {
    if (this.state.hpFilterType === "5") {
      return (this.state.departmentsData.length > 0 ? this.state.departmentsData.map((selectedDepartmentFilter: { attributes:{name: string}, id: string}, index: number) => {
        const filterTypeObjectDepart = this.state.selectedFilters && this.state.selectedFilters.length > 0 && this.state.selectedFilters.find(
          (filter: any) => filter.departments !== undefined
        ) 
        
        const checkForDepartments = filterTypeObjectDepart
          ? filterTypeObjectDepart.departments.some((i: any) => i.name === selectedDepartmentFilter.attributes.name)
          : false;

        return (
          <TableRow key={index} id="departmentTableRow">
          <TableCell id="departmentTableCell" style={this.getRowStyles(checkForDepartments)}>
            <Checkbox data-test-id={`department${index}`} className={this.props.classes.checkbox} checked={checkForDepartments} onChange={() => this.handleFilterCheckboxChange(index, selectedDepartmentFilter.id, "departments", selectedDepartmentFilter.attributes.name)} icon={<UnChecked />} checkedIcon={<Checked />}/>
          </TableCell>
          <TableCell id="departmentType" style={this.getSelectedField(checkForDepartments)}>
            {selectedDepartmentFilter.attributes.name}
          </TableCell>
        </TableRow>
        )
      }) : (<span>No department data available</span>))}
  }


  renderDoctorShiftFilter = () => {
    if (this.state.hpFilterType === "7") {
     return TimeSlots.map((selectedSlotFilter: { name: string, value: string }, index: number) => {
        const filterTypeObjectShift = this.state.selectedFilters && this.state.selectedFilters.length > 0 && this.state.selectedFilters.find(
          (filter: any) => filter.slot !== undefined
        );
        const checkForShifts = filterTypeObjectShift
          ? filterTypeObjectShift.slot.some((i: any) => i.name === selectedSlotFilter.name)
          : false;

       return (
         <TableRow key={index} id="slotTableRow">
           <TableCell id="slotTableCell" style={this.getRowSlotStyles(checkForShifts)}>
             <span>
               <Checkbox data-test-id={`slot${index}`} className={this.props.classes.checkbox} checked={checkForShifts} onChange={() => this.handleFilterCheckboxChange(index, selectedSlotFilter.value, "slot", selectedSlotFilter.name)} icon={<UnChecked />} checkedIcon={<Checked />} />
             </span>
             <span>
               <Box style={this.getSelectedSlotField(checkForShifts)}>{selectedSlotFilter.name}</Box>
               <Box style={{
                 fontSize: "16px",
                 fontFamily: "SF Pro Text Regular",
                 color: "#292929",
                 caretColor: "transparent"
               }}>
                 {selectedSlotFilter.value}
               </Box>
             </span>
           </TableCell>
         </TableRow>
       )
      })}
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box className={this.props.classes.dialogHpOverlay}/>
        <Box className={this.props.classes.dialogHpContainer}>
          <Box style={this.getPopoverClass()}>
            <Box style={{ display: "flex", width: "100%" }}>
                <Box className={this.props.classes.filterHpNameAlignment}>
                  {this.renderDoctorField()}
                  <Box data-test-id="2" style={this.checkSelectedField("2")} onClick={() => this.hpFilterClick("2", "experience", "")}>
                      Experience
                  </Box>
                  <Box data-test-id="3" style={this.checkSelectedField("3")} onClick={() => this.hpFilterClick("3", "language", [])}>
                      Language
                  </Box>
                  <Box data-test-id="4" style={this.checkSelectedField("4")} onClick={() => this.hpFilterClick("4", "ratings", "")}>
                      Ratings
                  </Box>
                </Box>
              <Box className={this.props.classes.filterHpNameRightAlignment}>
                <Box className={this.props.classes.clearBtn} onClick={this.handleClearAll} data-test-id="hpClearFilter"> Clear All</Box>
                <Box className={this.props.classes.hpTablePopoverContainer}>
                  <TableContainer id="filterTableContainer" className={this.props.classes.hpTablecontainer}>
                    <Table id="filterTable">
                      <TableBody id="filterBody">
                        {this.renderDepartFilter()}
                        {this.renderPriceFilter()}
                        {this.renderDoctorShiftFilter()}  
                        {this.renderScheduleFilter()}
                        {this.renderShiftFilter()}
                        {this.renderExpFilter()}
                        {this.renderLangFilter()}

                        {this.state.hpFilterType === "4" &&
                          [
                            { name: "5 Star", yellowStars: 5, grayStars: 0 },
                            { name: "4 Star & Above", yellowStars: 4, grayStars: 1 },
                            { name: "3 Star & Above", yellowStars: 3, grayStars: 2 },
                            { name: "2 Star & Above", yellowStars: 2, grayStars: 3 },
                            { name: "1 Star & Below", yellowStars: 1, grayStars: 4 }
                          ].map((selectedRatingFilter: { name: string, yellowStars: number, grayStars: number }, index: number) => (
                            <TableRow key={index} id="ratingTableRow">
                              <TableCell id="ratingTableCell" style={this.getRowStyles(index === this.state.selectedIndex)}>
                                <Checkbox data-test-id={`rating${index}`} className={this.props.classes.checkbox} checked={index === this.state.selectedIndex} onChange={() => this.handleCheckboxChange(index)} icon={<UnChecked />} checkedIcon={<Checked />} />
                              </TableCell>
                              <TableCell id="ratingType" style={this.getSelectedField(index === this.state.selectedIndex)}>
                                <span style={{ marginRight: "8px" }}>
                                  {[...Array(selectedRatingFilter.yellowStars)].map((_, i) => (
                                    <img
                                      key={`yellow-${i}`}
                                      src={YellowStar}
                                      height={22}
                                      width={22}
                                      style={{ marginRight: "8px" }}
                                    />
                                  ))}
                                  {[...Array(selectedRatingFilter.grayStars)].map((_, i) => (
                                    <img
                                      key={`gray-${i}`}
                                      src={GrayStar}
                                      height={22}
                                      width={22}
                                      style={{ marginRight: "8px" }}
                                    />
                                  ))}
                                </span>
                                {selectedRatingFilter.name}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Button
                  variant="contained"
                  data-test-id="hpApplyFilter"
                  color="primary"
                  onClick={() => this.handleApply()}
                  className={this.props.classes.applyButton}>
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
       </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  hpSelectedFilterType: {
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Semibold",
    color: "rgb(55, 128, 224)",
    background: "#C3D9F6",
    marginRight: "20px",
    padding: "16px 25px",
    borderRadius: "8px",
    marginBottom: "20px",
    caretColor: "transparent",
    width: "85px",
    display: "flex"
  },
  hpfilterList: {
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Semibold",
    color: "#808080",
    background: "transparent",
    marginRight: "20px",
    padding: "16px 25px",
    borderRadius: "8px",
    marginBottom: "20px",
    caretColor: "transparent",
    width: "85px",
    display: "flex"
  },
 
  dialogHpOverlay: {
    position: "fixed" as any,
    top: 0,
    right: 0,
    width: "91.7%",
    height: "100%",
    backgroundColor: "#000000b0",
    zIndex: 999
  },
  dialogHpContainer: {
    position: "fixed" as any,
    top: "10% !important",
    width: "60%",
    zIndex: 1000,
    padding: "0px 20px",
    borderRadius: "8px"
  },
  hpTablecontainer: {
    width: "100%",
    margin: "5px auto"
  },
  filterList: {
    cursor: "pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Semibold",
    color: "#808080",
    background: "transparent",
    marginRight: "20px",
    padding: "16px 25px",
    borderRadius: "8px",
    marginBottom: "45px",
    caretColor: "transparent",
    width: "85px",
    display: "flex"
  },
  filterHpNameAlignment: {
    width: "155px",
    borderRight: "1px solid #eee9e9",
    marginRight: "20px",
    paddingTop: "32px",
    caretColor: "transparent"
  },
  filterHpNameRightAlignment: {
    width: "100%"
  },
  clearBtn: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    color: "rgb(55, 128, 224)",
    textAlign: "right" as "right",
    marginBottom: "15px",
    caretColor: "transparent",
    cursor: "pointer"
  },
  applyButton: {
    backgroundColor: "#014866",
    color: "#FFFFFF",
    border: "medium none",
    borderRadius: "10px",
    padding: "8px 23px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Bold",
    fontSize: "18px",
    margin: "25px 15px 10px 0px",
    textTransform: "capitalize" as "capitalize",
    float: "left" as "left",
    width: "155px",
    height: "45px",
    caretColor: "transparent",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#FFFFFF"
    }
  },
  hpTablePopoverContainer: {
    width:"100%",
    maxHeight: "440px",
    overflow: "scroll" as "scroll",
    scrollbarColor: " #fff #fff",
    overflowY: "scroll" as "scroll",
    scrollbarWidth: "thin" as "thin",
    marginRight: "15px",
    "&::-webkit-scrollbar": {
      backgroundColor: "white",
      color: "white"
    },
    "&::-webkit-scrollbar-button": {
      backgroundColor: "white",
      display: "none",
      color: "white"
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "white",
      color: "white"
    },
    "::-webkit-scrollbar-track": {
      backgroundColor: "white",
      color: "white"
    },
    "@media (max-height: 700px)": {
      maxHeight: "450px"
    },
    "@media (max-height: 660px)": {
      maxHeight: "395px"
    },
    "@media (max-height: 659px)": {
      maxHeight: "376px"
    }
  },
  checkbox: {
    padding: "5px 25px 5px 0px",
    color: "white !important",
    "& .MuiCheckbox-colorSecondary .Mui-checked":{
      color: "white !important",
    }
  },
  searceFilter: {
    "& .MuiInputBase-root": {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: "#292929 !important",
      width:"225px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px !important",
    },
    '&::placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
  },
  searchInput: {
    '&::placeholder': {
      color:"#808080",
      fontFamily:"SF Pro Text Regular",
      fontSize:"16px",
    },
    '&::-webkit-input-placeholder': {
      color:"#808080 !important",
      fontFamily:"SF Pro Text Regular",
      fontSize:"16px !important",
      opacity: 1,
    },
  },
};
export default withStyles(webStyles)(Filter);
// Customizable Area End
